const config = {
Version: '1.6.1',
    Debug: false, // true prefills the login form with test bam, enables redux tools, and sets the URL for epubs to http rather than https

    // **************** PROD ENVIRONMENT ****

    Environment: 'PROD',
    API: {
      Viewer: 'https://viewer.digitalbookroom.com',
      Main: 'https://api.digitalbookroom.com',
      Storage: 'https://dibsbookstest.blob.core.windows.net',
      Admin: `https://admin.digitalbookroom.com`
    },

    // **************** TEST ENVIRONMENT ****

    // Environment: 'TEST',
    // API: {
    //     Main: 'https://dibs-api-test.azurewebsites.net',
    //     Viewer: 'https://dibs-viewer-test-node.azurewebsites.net',
    //     Storage: 'https://dibsbookstest.blob.core.windows.net',
    //     Admin: `https://brainhive-admin-test.azurewebsites.net`
    // },
    // Environment: 'TEST',
    // API: {
    //     Main: 'https://dibs-api-test.azurewebsites.net',
    //     Storage: '',
    //     Admin: `https://brainhive-admin-test.azurewebsites.net`
    // },

    /* **************   DEV ENVIRONMENT *******/
    // Environment: 'DEV',
    // API: {
    //   Main: 'http://api-dev.digitalbookroom.com',
    //   Storage: 'https://dibsbookstest.blob.core.windows.net',
    //   Admin: `http://dibs-admin-dev.azurewebsites.net`
    // },

    Coralogix: {
        key: '88e035bf-5ccb-3a98-29a0-562fcf7162c2',
        subsystemName: 'DiBS.Web.Student'
    },
    google: {
        clientID:
            '170648211199-qldupgprptpkc1mfsg0h6b6p3va5a6te.apps.googleusercontent.com'
    },

    UserRoleIDs: {
        Teacher: 'C89F9DD9-ED08-43F5-8FC8-DBFCB1A3D008', // notes on each role are in the README
        SuperAdmin: 'BA2ED223-7F90-4700-B04E-3DA0025D2F5A',
        SchoolAdmin: 'B751ECE9-97E1-439D-BC27-9665E47D847E',
        SalesRep: 'D335673F-182A-4E72-B4B6-F20856698843',
        Student: '357001F1-7DC2-496F-82F3-8FC21813BF6C',
        Generic: '2E4030D1-BD1A-451E-B1C8-010CAA433BBC',
        Demo: 'E3F41C5E-DC13-4859-9A51-3124B6724297'
    },

    typeGUID: {
        math: 'FDDCA1E8-4BE2-45E2-9DE7-CB9D43D60A44',
        languageArts: 'D675D783-CA70-47F7-A600-FD7CE2669594',
        science: 'C17493D4-1A3A-4AA3-A707-606C1C839758',
        socialStudies: 'f7a70888-fd0c-43c2-b619-223eff1c1a4f'
    }
};

export default config;
