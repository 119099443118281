import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { hashHistory } from "react-router";
import UserProfileForm from "../common/UserProfileForm";
import CommonModal from "../common/CommonModal";

import {
  userUpdateProfile,
  userUpdatePW,
  userLogout,
  toggleClassCodeModal,
} from "../../actions/userActions";
import {
  toggleUserProfileModal,
  toggleLoggoutConfirmModal,
} from "../../actions/dashboard/dashboardActions";

import constants from "../../constants/constants";
import ClassCodeModal from "./ClassCodeModal";
import { Navbar } from "./Navbar";

function muteAudio() {
  const audioCtx = new AudioContext();
  const gainNode = audioCtx.createGain();

  gainNode.gain.setValueAtTime(0, audioCtx.currentTime);
}

class BookBagHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.user.First,
      lastName: this.props.user.Last,
      password: "",
      confirmPassword: "",
      loginID: this.props.user.LoginID,
      logoutWarning:
        "All of your downloaded books will be removed and you will have to redownload them. Are you sure you want to continue?",
      mediaOverlayMuted: false,
    };
    this.readium = {};
    this.theme = constants.themeProvider.activeTheme;
    this.logo = this.theme.logo;
  }

  componentDidMount() {
    this.setState({ logoutWarning: "" });
    const { readiumClass: RediumClass } = this.props;

    if (RediumClass) {
      const readerOptions = {
        needsFixedLayoutScalerWorkAround: false,
        el: "#readium-id",
      };
      const readiumOptions = {
        cacheSizeEvictThreshold: undefined,
        useSimpleLoader: true,
        openBookOptions: {},
      };
      const readium = new RediumClass(readiumOptions, readerOptions);

      this.readium = readium;
    }
  }

  closeLoggoutConfirmModal = () => {
    this.setState({ showLoggoutConfirmModal: false });
  };

  handleLoggout = () => {
    const keys = Object.keys(localStorage);
    const filteredBookIds = keys.filter((key) => key.startsWith("last-page-"));
    filteredBookIds.forEach((key) => {
      localStorage.removeItem(key);
    });
    this.props.userLogout();
    hashHistory.push("/login");
  };

  handleSelect = (e) => {
    switch (e) {
      case "1":
        this.props.toggleUserProfileModal();
        break;
      case "2":
        this.signOut();
        break;
      case "3":
        this.props.toggleClassCodeModal();
        break;
      default:
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  signOut = () => {
    // prevent user from logging out if there is no internet connection
    if (!this.props.isOnline) {
      toastr.error(
        `You must be connected to the internet before logging out.`,
        `Warning`,
        constants.toastrWarningOptions,
      );
      return;
    }
    this.props.toggleLoggoutConfirmModal();
  };

  handleSubmit = () => {
    if (!this.props.isOnline) {
      toastr.error(
        `You must be connected to the internet before updating your profile or changing your password.`,
        `No Internet Connection`,
        {
          closeButton: true,
          showAnimation: "animated fadeInDown",
        },
      );
      return;
    }
    if (
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.loginID !== ""
    ) {
      const firstName = this.state.firstName;
      const lastName = this.state.lastName;
      const loginID = this.state.loginID;
      this.props.toggleUserProfileModal();

      this.props
        .userUpdateProfile(this.props.user, firstName, lastName, loginID)
        .then(() => {
          toastr.success(
            `Updated profile info.`,
            `Success`,
            constants.toastrSuccessOptions,
          );
        })
        .catch((error) => {
          console.error("Error updating profile", error);
          toastr.error(
            `Unable to update profile.  ${error.statusText}`,
            `Error`,
            constants.toastrErrorOptions,
          );
        });
    }

    if (
      this.state.password === this.state.confirmPassword &&
      this.state.password !== ""
    ) {
      const password = this.state.password;
      this.props
        .userUpdatePW(this.props.user, password)
        .then(() => {
          toastr.success(
            `Updated password.`,
            `Success`,
            constants.toastrSuccessOptions,
          );
        })
        .catch((error) => {
          console.error("Error updating password", error);
          toastr.error(
            `Unable to update password.  ${error.statusText}`,
            `Error`,
            constants.toastrErrorOptions,
          );
        });
      this.setState({ password: "", confirmPassword: "" });
      this.props.toggleUserProfileModal();
    }
  };

  buildUserProfileForm = () => {
    return (
      <UserProfileForm
        handleChange={this.handleChange}
        forms={[
          {
            id: "firstName",
            name: "firstName",
            value: this.state.firstName,
            placeholder: "First Name",
            type: "text",
          },
          {
            id: "lastName",
            name: "lastName",
            value: this.state.lastName,
            placeholder: "Last Name",
            type: "text",
          },
          {
            id: "loginID",
            name: "loginID",
            value: this.state.loginID,
            placeholder: "Login ID (i.e username or email)",
            type: "text",
          },
          {
            id: "password1",
            name: "password",
            placeholder: "Password",
            type: "text",
          },
          {
            id: "confirmPassword",
            name: "confirmPassword",
            placeholder: "Confirm Password",
            type: "text",
          },
        ]}
      />
    );
  };

  toggleMuteMediaOverlay = () => {
    const toggleMute = !this.state.mediaOverlayMuted;
    this.setState({
      mediaOverlayMuted: toggleMute,
    });

    // mute media overlay
    muteAudio();
  }

  render() {
    return (
      <div className="book-bag-header">
        <Navbar
          user={this.props.user}
          img={this.logo}
          signOut={() => this.signOut()}
          userProfileModal={() => this.props.toggleUserProfileModal()}
          query={this.props.query}
          theme={this.props.theme}
          isFiltersActive={this.props.isFiltersActive}
          handleInvalidSession={this.props.handleInvalidSession}
          handleCategoryClick={this.props.handleCategoryClick}
          selectedCategory={this.props.selectedCategory}
          handleBookSearchValue={this.props.handleBookSearchValue}
          handleReadingSearchValue={this.props.handleReadingSearchValue}
          categories={this.props.categories}
          handleHomeClick={this.props.handleHomeClick}
          bookBagCount={this.props.bookBagCount}
          hideBookBag={this.props.hideBookBag}
          handleSearchMode={this.props.handleSearchMode}
          searchMode={this.props.searchMode}
          isMuted={this.state.mediaOverlayMuted}
          hideNav={this.props.hideNav}
          toggleMute={this.toggleMuteMediaOverlay}
        />

        <ClassCodeModal addCode={this.props.addCode}></ClassCodeModal>

        <CommonModal
          name="userProfile"
          className="common-modal static-modal-update-user-profile"
          bsSize="small"
          title={`Hi ${this.state.firstName + " " + this.state.lastName
            }, need to update your profile?`}
          children={this.buildUserProfileForm()}
          modalVisable={this.props.showUserProfileModal}
          cancelText="Cancel"
          submitText="Save"
          cancel={this.props.toggleUserProfileModal}
          submit={this.handleSubmit}
        />
        <CommonModal
          name="loggoutConfirm"
          className="common-modal"
          bsSize="small"
          title={`Are you sure you want to logout?`}
          children={this.state.logoutWarning}
          modalVisable={this.props.showLoggoutConfirmModal}
          cancelText="Cancel"
          submitText="Logout"
          cancel={this.props.toggleLoggoutConfirmModal}
          submit={this.handleLoggout}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    showUserProfileModal: state.dashboard.showUserProfileModal,
    showLoggoutConfirmModal: state.dashboard.showLoggoutConfirmModal,
    readiumClass: state.readium.readiumClass,
  };
}

export default connect(mapStateToProps, {
  userUpdateProfile,
  userUpdatePW,
  userLogout,
  toggleUserProfileModal,
  toggleLoggoutConfirmModal,
  toggleClassCodeModal,
})(BookBagHeader);
