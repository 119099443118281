/*
 * BookPage component is responsible for a single book page
 * it knows how to size itself, render highlights and notes.
 * The reason the Pages component does not size the BookPage is because the Pages component would need
 * to send sizing data for all the pages. However we really only need the currently visible pages.
 * Then the only thing the BookPage component would need to do is figure out if it is the left or right page.
 */

import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { viewerModes } from "../../constants/viewerModes";
import { filter } from "lodash";
import { toastr } from "react-redux-toastr";
import NotePopover from "./NotePopover";
import { saveBookItem, deleteBookItem } from "../../actions/bookActions";
import { setActiveNote, setBookZoom } from "../../actions/bookViewActions";
import { startPointing } from "../../actions/bookToolbarActions";
import UserAPI from "../../api/userAPI";
import constants from "../../constants/constants";
// import { roundTo } from "../../utilities/roundTo";

class BookPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scaleX: "",
      scaleY: "",
      pageContainerWidth: 0,
      pageContainerHeight: 0,
      pageClassName: "",
      marginLeft: 0,
      pageScalePercent: 0,
    };
    this._lastResized = 0;
    this.resizePage = this.resizePage.bind(this);
    this.assignBookPageClassName = this.assignBookPageClassName.bind(this);
    this.saveNote = this.saveNote.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.deserialize = this.deserialize.bind(this);
    this.getNotesHTML = this.getNotesHTML.bind(this);

    this.retryCount = 0;
  }

  componentDidMount() {
    this.assignBookPageClassName();
    window.addEventListener("resize", this.assignBookPageClassName);

    /*
     * Setup Mobile Safari listeners
     */

    // TODO refactor/blms
    // We are setting up event listeners
    // $(document.body).on('touchend', '.pages .left, .pages .right', (e) => {
    //   this.props.pagesTapped(e);
    // });
    // $(document.body).on('touchend', '.pages .left .tappable, .pages .right .tappable', (e) => {
    //   this.props.wordTapped(e);
    // });
    // not sure how to properly trigger these events on Mobile Safari
    // $(document.body).on('touchstart', '.pages .left, .pages .right', (e) => {
    //   this.props.onMouseDown(e);
    // });
    // $(document.body).on('touchmove', '.pages .left, .pages .right', (e) => {
    //   this.props.onMouseMove(e);
    // });
    // $(document.body).on('touchend', '.pages .left, .pages .right', (e) => {
    //   this.props.onMouseUp(e);
    // });

    /*
     * Setup Desktop Browser listeners
     */
    // $(document.body).on('click', '.pages .left, .pages .right', (e) => {
    //   this.props.pagesTapped(e);
    // });
    // $(document.body).on('click', '.pages .left .tappable, .pages .right .tappable', (e) => {
    //   this.props.wordTapped(e);
    // });
    // $(document.body).on('mousedown', '.pages .left, .pages .right', (e) => {
    //   this.props.onMouseDown(e);
    // });
    // $(document.body).on('mousemove', '.pages .left, .pages .right', (e) => {
    //   this.props.onMouseMove(e);
    // });
    // $(document.body).on('mouseup', '.pages .left, .pages .right', (e) => {
    //   this.props.onMouseUp(e);
    // });

    // initialize sizes, and deserialize after 2 second (should be enough time to load)
    // TODO rather than a 2 second timeout, can we wait until the HTML renders?
  }

  componentWillUnmount() {
    // $(document.body).off();
    window.removeEventListener("resize", this.assignBookPageClassName);
  }

  /*
   * Step 5 in loading a book page
   *
   */
  componentDidUpdate(prevProps) {
    let shouldAssign = false;

    if (prevProps.dimensions !== this.props.dimensions) {
      this.assignBookPageClassName();
    }

    if (
      prevProps.blm.Title !== this.props.blm.Title ||
      prevProps.blmMode !== this.props.blmMode
    ) {
      // console.log('blm mode changed in bookpage');
      shouldAssign = true;
    }

    if (prevProps.book.currentPage !== this.props.book.currentPage) {
      shouldAssign = true;
      // console.log('current page changed in BookPage');
    }

    if (prevProps.pagesVisible !== this.props.pagesVisible) {
      shouldAssign = true;
      // console.log('pagesVisible changed in BookPage', prevProps.pagesVisible, this.props.pagesVisible)
    }
    // change in manual zoom
    if (
      prevProps.bookView.bookManualZoomLevel !==
      this.props.bookView.bookManualZoomLevel
    ) {
      shouldAssign = true;
      // console.log('change in manual zoom level');
    }
    if (
      prevProps.leftPageContainerWidth !== this.props.leftPageContainerWidth ||
      prevProps.leftPageContainerHeight !== this.props.leftPageContainerHeight
    ) {
      shouldAssign = true;
    }
    if (prevProps.page.pageReady !== this.props.page.pageReady) {
      shouldAssign = true;
    }
    if (shouldAssign) {
      this.assignBookPageClassName();
    }

    // received new notes
    if (
      prevProps.book.notes &&
      this.props.book.notes &&
      prevProps.book.notes.length !== this.props.book.notes.length &&
      (this.props.book.currentPage === this.props.page.pageNumber ||
        this.props.book.currentPage === this.props.page.pageNumber + 1)
    ) {
      this.forceUpdate();
    }

    if (prevProps.scaleType !== this.props.scaleType) {
      this.props.setBookZoom(this.props.scaleIndex);
    }
  }

  /*
   * Step 6 in loading a book page
   * if we have markups for a page, then deserialize them to render them in the viewer
   */
  // This is where we deserialize the pages of a book
  deserialize() {
    // wait until the rangy highlighters are ready
    if (!this.props.highlighterLeft || !this.props.highlighterRight) {
      if (this.retryCount < 5) {
        setTimeout(this.deserialize, 300);
        this.retryCount++;
      } else {
        console.error(
          "Unable to deserailize markups, highlighters never became ready",
        );
      }

      console.log(
        "rangy highlighters, not ready.  retrying..." + this.retryCount,
      );
      return;
    }
    const pageNumber = this.props.page.pageNumber; // this page
    const whichSide =
      this.props.book.currentPage === pageNumber ? "Left" : "Right";

    // we need to check viewer mode and display student highlights here
    if (
      !this.props.location.query.viewerMode ||
      this.props.location.query.viewerMode === "normal"
    ) {
      if (
        !!this.props.book.highlights &&
        !!this.props.book.highlights.Content &&
        !!this.props.book.highlights.Content[`page${pageNumber}`]
      ) {
        this.props[`highlighter${whichSide}`].deserialize(
          this.props.book.highlights.Content[`page${pageNumber}`],
        );
      }
      // mode: teacher is viewing a student book to see students content and leave notes for a student
      // action: we deseralize the content made by the student
    } else if (
      this.props.location.query.viewerMode ===
      viewerModes.MODE_TEACHER_VIEW_STUDENT_BOOK ||
      this.props.location.query.viewerMode ===
      viewerModes.MODE_TEACHER_STUDENT_BLM
    ) {
      if (
        !!this.props.book.studentHighlights &&
        !!this.props.book.studentHighlights.Content &&
        !!this.props.book.studentHighlights.Content[`page${pageNumber}`]
      ) {
        this.props[`highlighter${whichSide}`].deserialize(
          this.props.book.studentHighlights.Content[`page${pageNumber}`],
        );
      }
    }
  }

  resizePage() {
    // INFO: The viewport height divided by 100 gives us 1% and then it's multiplied by 0.1125 to give us the correct scale.
    // INFO: The multiplier is completely arbitrary and was determined by trial and error.
    // let pageScalePercent = roundTo((this.props.dimensions.viewportHeight / 100) * this.props.bookScaling, 3);
    let pageScalePercent = this.props.bookView.bookManualZoomLevel;
    let pageContainerWidth = 0;
    let pageContainerHeight = 0;
    let marginLeft = 0;

    const pageWidth = this.props.page.width;
    const pageHeight = this.props.page.height;
    const pageNumber = this.props.page.pageNumber; // this page
    const currentPage = this.props.book.currentPage; // the page the viewer is viewing

    pageContainerWidth = pageWidth * pageScalePercent;
    pageContainerHeight = pageHeight * pageScalePercent;

    /*
     * Handle assigning the right css classes to the book page
     * if the pageNumber is the same as the pageIndex - this is the left page
     * if this is one page after the pageIndex and pages visible is 2 then this is the right page
     * everything else is invisble.
     * if viewing a single page, then add single page
     *
     * If in BLM mode and single pagesvisible - then left book page should be invisible
     */

    if (pageNumber === currentPage) {
      this.props.updateLeftPageContainer(
        pageContainerWidth,
        pageContainerHeight,
      );
      setTimeout(this.deserialize, 300);
    } else if (pageNumber === currentPage + 1) {
      if (this.props.blmMode || this.props.pagesVisible === 1) {
        // pageClassName += 'invisible-right'
        marginLeft = 0;
      } else {
        // pageClassName += 'right page-right'
        setTimeout(this.deserialize, 300);
        marginLeft = this.props.leftPageContainerWidth + 5; // add 5 for the space inbetween the pages
      }
    } else {
      // pageClassName += 'invisible-left';
    }
    let pageClassName = this.getBookPageClassName(pageNumber, currentPage);
    this.setState({
      pageContainerWidth,
      pageContainerHeight,
      pageClassName,
      marginLeft,
      pageScalePercent,
    });
  }

  getBookPageClassName(pageNumber, currentPage) {
    let pageClassName = "page ";
    if (pageNumber === currentPage) {
      pageClassName += "left page-left";
      if (this.props.pagesVisible === 1) {
        pageClassName += " single-page";
      }
    } else if (pageNumber === currentPage + 1) {
      if (this.props.blmMode || this.props.pagesVisible === 1) {
        pageClassName += "invisible-right";
        // marginLeft = 0;
      } else {
        pageClassName += "right page-right";
        // marginLeft = this.props.leftPageContainerWidth + 5; // add 5 for the space inbetween the pages
      }
    } else {
      pageClassName += "invisible-left";
    }
    return pageClassName;
  }

  /*
   * should this page be visible or not
   */
  assignBookPageClassName() {
    const pageNumber = this.props.page.pageNumber; // this page
    const currentPage = this.props.book.currentPage; // the page the viewer is viewing

    if (this.shouldBookPageResize(currentPage, pageNumber)) {
      this.resizePage();
    } else {
      let pageClassName = this.getBookPageClassName(pageNumber, currentPage);
      let marginLeft = 0;
      if (pageClassName === "page right page-right") {
        marginLeft = this.props.leftPageContainerWidth + 5; // add 5 for the space inbetween the pages
      }
      this.setState({ pageClassName, marginLeft });
    }
  }

  /*
   * Does this page need to resize??
   * it needs to resize if it is the left or right page
   * it needs to resize the left page first - currently we are doing this on bookView load, but not when the book changes or pages change
   */
  shouldBookPageResize(currentPage, pageNumber) {
    // we cannot assign the correct amount of leftMargin to the right page until the left page is sized, so wait.
    // the pages skipped by this return will be caught in componentDidUpdate check for a change in leftPageContainerWidth
    if (pageNumber !== currentPage && this.props.leftPageContainerWidth === 0) {
      return false;
    }
    if (pageNumber === currentPage || pageNumber === currentPage + 1) {
      return true;
    } else {
      return false;
    }
  }

  /*
   * save a note
   */
  saveNote(note) {
    this.props
      .saveBookItem(
        note,
        this.props.user,
        this.props.location.query.viewerMode,
        this.props.book.ID,
      )
      .then()
      .catch((error) => {
        console.error("Error saving note", error);
        toastr.error(
          "Unable to save note.  Please try again or contact support.",
          `Error Saving Note`,
          constants.toastrErrorOptions,
        );
      });
    const noteID = note.ID || note.TempID;
    this.props.setActiveNote(noteID);
  }

  /*
   * create a note that temporarily only lives in redux
   */
  createTempNote(e, pageNumber) {
    const pageScalePercent = this.state.pageScalePercent;
    const viewerMode = this.props.location.query.viewerMode;
    const noteScalePercent = 1 / pageScalePercent;

    /*
     * If we need to add back the onTouchEnd event use this because the layerX is not defined on touch events:
     * onTouchEnd={(e) => this.bookPageTapped(e, pageNumber)}
     * const rect = e.nativeEvent.target.getBoundingClientRect();
     * const layerX = e.changedTouches[0].clientX - rect.left;
     * const layerY = e.nativeEvent.layerY || e.changedTouches[0].clientY - rect.top;
     */

    /* get the offset for mobile Safari touch events with Jonathan's solution
     * he even posted it to stackoverflow:
     * https://stackoverflow.com/questions/17130940/retrieve-the-same-offsetx-on-touch-like-mouse-event/46105718#46105718
     */

    const layerX = e.nativeEvent.layerX;
    const layerY = e.nativeEvent.layerY;
    const noteX = e.nativeEvent.offsetX || layerX * noteScalePercent;
    const noteY = e.nativeEvent.offsetY || layerY * noteScalePercent;
    let newNote = {
      Type: 3,
      TempID: (Math.random() * 10000 + "").replace(".", ""),
      Page: pageNumber,
      bookID: this.props.book.ID,
      Content: {
        page: `page${pageNumber}`,
        value: "",
        offsetX: noteX, // x coordinate for note icon
        offsetY: noteY, // y coordinate for note icon
      },
    };
    // Step 3: determining the current mode we are in
    // This determines what we add to the Note object
    // Mode: teacher viewing a students book
    // Teacher is leaving a note specific to a student (type 4)
    if (
      viewerMode === viewerModes.MODE_TEACHER_VIEW_STUDENT_BOOK ||
      viewerMode === viewerModes.MODE_TEACHER_STUDENT_BLM
    ) {
      newNote = {
        ...newNote,
        Type: 4,
        StudentID: this.props.location.query.studentID,
        TeacherID: this.props.user.ID,
      };
    }
    // Mode: teacher leaving notes for a class
    // Teacher is leaving a note specific to a class (type 5)
    if (viewerMode === viewerModes.MODE_TEACHER_CLASS_NOTES) {
      newNote = {
        ...newNote,
        Type: 5,
        ClassID: this.props.location.query.classID,
        TeacherID: this.props.user.ID,
      };
    }
    // Mode: teacher leaving a group notes
    // Teacher is leaving a note specific to a group (type 6)
    if (viewerMode === viewerModes.MODE_TEACHER_GROUP_NOTES) {
      newNote = {
        ...newNote,
        Type: 6,
        GroupID: this.props.location.query.groupID,
        TeacherID: this.props.user.ID,
      };
    }
    this.saveNote(newNote);
    this.props.startPointing();
  }

  deleteNote(note) {
    // prevent teacher's from deleting student notes
    if (!UserAPI.isStudent(this.props.user.RoleID) && note.Type === 3) {
      toastr.error(
        `Only student's can delete their notes.`,
        `Warning`,
        constants.toastrWarningOptions,
      );
      return;
    }
    this.props.deleteBookItem(note, this.props.user, this.props.book.ID);
    this.props.setActiveNote("");
  }

  bookPageTapped(e, pageNumber) {
    e.persist();
    if (this.props.bookToolbar.allowNotes) {
      this.createTempNote(e, pageNumber);
    } else {
      this.props.pagesTapped(e, pageNumber);
    }
    // close any open notes
    if (this.props.bookView.activeNoteID) {
      // uncomment this to be able to click anywhere and close a note.  Issue is that if the user did not type anything, there is not a good way to
      // automatically delete the note.
      // this.props.setActiveNote('');
    }
  }

  /*
   * Render the Note HTML
   */
  getNotesHTML() {
    const pageNumber = this.props.page.pageNumber;
    // which notes
    const notes = this.whichNotes();
    return notes.map((note) => {
      if (note.Page === 0) {
        note = Object.assign({}, note, { Page: 1 });
      }
      if (
        note.Page !== this.props.book.currentPage &&
        note.Page !== this.props.book.currentPage + 1
      ) {
        // only render notes within current visible page bounds
        return "";
      } else if (
        this.props.pagesVisible === 1 &&
        note.Page === this.props.book.currentPage + 1
      ) {
        // dont render notes for second page if we are only viewing one
        return "";
      } else if (note.Page !== pageNumber) {
        return "";
      } else {
        const key = note.ID || note.TempID;
        return (
          <NotePopover
            key={key}
            note={note}
            saveNote={this.saveNote}
            deleteNote={this.deleteNote}
            activeNoteID={this.props.bookView.activeNoteID}
            setActiveNote={this.props.setActiveNote}
            pageScalePercent={this.state.pageScalePercent}
            isStudent={UserAPI.isStudent(this.props.user.RoleID)}
            loading={this.props.loading}
          />
        );
      }
    });
  }

  /*
   * which notes should we load?
   */
  whichNotes() {
    let combinedNotes = [];
    if (
      !!this.props.book &&
      !!this.props.book.notes &&
      !!this.props.book.notes.length
    ) {
      combinedNotes = [...combinedNotes, ...this.props.book.notes];
    }
    if (
      !!this.props.book &&
      (this.props.location.query.viewerMode ===
        viewerModes.MODE_TEACHER_VIEW_STUDENT_BOOK ||
        this.props.location.query.viewerMode ===
        viewerModes.MODE_TEACHER_STUDENT_BLM)
    ) {
      const filteredStudentNotes = filter(this.props.book.studentNotes, {
        Type: 3,
      });
      combinedNotes = [...combinedNotes, ...filteredStudentNotes];
    }
    if (this.props.notes && !!this.props.notes.length) {
      combinedNotes = [...combinedNotes, ...this.props.notes];
    }
    return combinedNotes;
  }

  escapeHTML(data) {
    return { __html: data };
  }

  render() {
    if (!this.props.page.pageReady) {
      return null;
    }
    let pageHTML = this.props.page.pageHTML;
    let key = this.props.page.pageKey;
    const pageNumber = this.props.page.pageNumber; // this page

    const pageStyle = {
      width: `${this.state.pageContainerWidth}px`,
      height: `${this.state.pageContainerHeight}px`,
      marginLeft: `${this.state.marginLeft}px`,
      position: "absolute",
      marginBottom: `${constants.bottomToolbarHeight + 10}`,
    }; // the width and height will change as we zoom
    const jpedalStyle = {
      width: `${this.props.page.width}px`,
      height: `${this.props.page.hight}px`,
      position: "relative",
      display: "block",
      transform: `translateY(0px) translateX(0px) scale(${this.state.pageScalePercent})`,
      transformOrigin: "top left",
    }; // the scale will change as we zoom.

    const notesLayerStyle = Object.assign({}, jpedalStyle, {});

    return (
      <div
        id={key}
        key={key}
        className={this.state.pageClassName}
        style={pageStyle}
        data-type={this.props.scaleType}
      >
        <div className={"notesLayer"} style={notesLayerStyle}>
          {this.getNotesHTML()}
          <div
            dangerouslySetInnerHTML={this.escapeHTML(pageHTML)}
            onClick={(e) => this.bookPageTapped(e, pageNumber)}
            onMouseDown={(e) => this.props.pagesOnMouseDown(e, pageNumber)}
            onMouseMove={this.props.pagesOnMouseMove}
            onMouseUp={(e) => this.props.pagesOnMouseUp(e, pageNumber)}
            onTouchStart={(e) => this.props.pagesOnMouseDown(e, pageNumber)}
            onDragStart={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      </div>
    );
  }
}

BookPage.propTypes = {
  pagesOnMouseDown: PropTypes.func.isRequired,
  pagesOnMouseUp: PropTypes.func.isRequired,
  pagesOnMouseMove: PropTypes.func.isRequired,
  pagesVisible: PropTypes.number.isRequired,
  blmMode: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  highlighterRight: PropTypes.object,
  highlighterLeft: PropTypes.object,
  wordTapped: PropTypes.func.isRequired,
  location: PropTypes.object,
  page: PropTypes.object.isRequired,
  pagesContainerHeight: PropTypes.number.isRequired,
  pagesContainerWidth: PropTypes.number.isRequired,
  updateLeftPageContainer: PropTypes.func.isRequired,
  leftPageContainerWidth: PropTypes.number.isRequired,
  leftPageContainerHeight: PropTypes.number.isRequired,
  updateBookScalePercent: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    bookToolbar: state.bookToolbar,
    bookView: state.bookView,
    loading: state.ajaxCallsInProgress > 0,
    book: state.book,
  };
};
export default connect(mapStateToProps, {
  saveBookItem,
  setActiveNote,
  deleteBookItem,
  startPointing,
  setBookZoom,
})(withRouter(BookPage));
