import * as React from "react";
import { Button, Col, FormControl, InputGroup } from "react-bootstrap";
import UserAPI from "../../api/userAPI";
import book from "../../images/book-2.svg";
import book_black from "../../images/book-2-black.svg";
import constants from "../../constants/constants";
import graphic_organizers from "../../images/tools.svg";
import graphic_organizers_black from "../../images/tools_black.svg";
import icon_notebook from "../../images/notebook-2.svg";
import icon_zoom_in from "../../images/zoom_in.svg";
import icon_zoom_out from "../../images/zoom_out.svg";
import icon_player_pause from "../../images/player_pause.svg";
import icon_player_play from "../../images/player_play.svg";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      arrowUp: false,
      filteredBooks: this.props.books,
    };
    this.searchBooks = this.searchBooks.bind(this);
    this.toggleClass = "fa fa-angle-double-up";
    this.theme = constants.themeProvider.activeTheme;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.books !== this.props.books) {
      this.setState({ filteredBooks: this.props.books });
    }
  }

  openPanel = () => {
    this.setState((prevState) => ({
      arrowUp: !prevState.arrowUp,
    }));
    this.props.openSidePanel(this.state.arrowUp);
  };

  searchBooks(event) {
    let filteredBooks = this.props.books.filter((book) =>
      book.Title.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    if (!filteredBooks) {
      filteredBooks = [];
    }
    this.setState({
      search: event.target.value,
      filteredBooks,
    });
  }

  render() {
    return (
      <div
        className={`viewer-header page-header ${this.props.book.IsEPub ? "justify-content-center" : ""
          }`}
        style={this.props.book.IsEPub ? { width: "100%" } : { width: "53%" }}
      >
        {!this.props.book.IsEPub && (
          <div>
            {UserAPI.isStudent(this.props.user.RoleID) && (
              <div>
                <Button
                  className="round-button"
                  style={{}}
                  onClick={this.openPanel}
                  role="button"
                  type="button"
                >
                  <img alt="" src={icon_notebook} />
                </Button>
                <div className="book-blm-menu btn-group">
                  <Button
                    role="button"
                    type="button"
                    className="item dropdown-toggle"
                    variant="default"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      alt=""
                      src={book}
                      data-pin-nopin="true"
                      className="dropdown-img"
                    ></img>
                    <img
                      alt=""
                      src={book_black}
                      data-pin-nopin="true"
                      className="dropdown-img-black"
                    ></img>
                    <span className="dropdown-text">Book</span>{" "}
                    <span className="caret"></span>
                  </Button>
                  <ul className="dropdown-menu book-2">
                    <Col md={12} lg={12}>
                      <InputGroup>
                        <InputGroup.Text
                          style={{
                            background: "none",
                            borderRight: "none",
                            borderColor: "#808080",
                            borderBottomRightRadius: 0,
                            borderTopRightRadius: 0,
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                          }}
                        >
                          <i className="fa-solid fa-search"></i>
                        </InputGroup.Text>
                        <FormControl
                          type="text"
                          placeholder="Search"
                          value={this.state.search}
                          onChange={this.searchBooks}
                          style={{
                            background: "none",
                            borderLeft: "none",
                            borderColor: "#808080",
                            fontFamily: "Poppins",
                            borderBottomRightRadius: "8px",
                            borderTopRightRadius: "8px",
                          }}
                        />
                      </InputGroup>
                    </Col>
                    {this.state.filteredBooks.length > 0 &&
                      this.state.filteredBooks.map((book) => {
                        return (
                          <div key={book.ID}>
                            <li>
                              <Button
                                variant="link"
                                className="button-text-decoration"
                                onClick={() => {
                                  this.props.changeBook(book);
                                }}
                              >
                                {book.Title}
                              </Button>
                            </li>
                          </div>
                        );
                      })}
                  </ul>
                </div>
                <span>
                  <Button
                    role="button"
                    type="button"
                    className="item dropdown-toggle"
                    variant="default"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      alt=""
                      src={graphic_organizers}
                      data-pin-nopin="true"
                      className="dropdown-img"
                    ></img>
                    <img
                      alt=""
                      src={graphic_organizers_black}
                      data-pin-nopin="true"
                      className="dropdown-img-black"
                    ></img>
                    <span className="dropdown-text">Activities</span>{" "}
                    <span className="caret"></span>
                  </Button>
                  <ul className="dropdown-menu blm">
                    {this.props.blms.length > 0 &&
                      this.props.blms.map((blm) => {
                        return (
                          <li key={blm.ID}>
                            <Button
                              variant="link"
                              className="button-text-decoration"
                              onClick={() => {
                                this.props.changeBlm(
                                  blm.projectAssignmentID,
                                  blm.ID,
                                );
                              }}
                            >
                              {blm.FriendlyName || blm.Title}
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </span>
              </div>
            )}
          </div>
        )}

        <div className="drawer-wrapper">
          <div className="zoom-btn-container">
            {this.props.book.IsEPub && (
              <>
                <Button
                  className="zoom-btn"
                  onClick={() => this.props.setStatus(true)}
                  role="button"
                  type="button"
                  disabled={this.props.status === true}
                >
                  <img alt="" src={icon_player_play} />
                </Button>
                <Button
                  className="zoom-btn"
                  onClick={() => this.props.setStatus(false)}
                  role="button"
                  type="button"
                  disabled={this.props.status === false}
                >
                  <img alt="" src={icon_player_pause} />
                </Button>
                <span className="zoom-btn" />
              </>
            )}
            <Button
              id="zoom-btn-decrease"
              className="zoom-btn"
              style={{}}
              onClick={() => {
                this.props.decreaseBookZoom();
              }}
              role="button"
              type="button"
            >
              <img alt="" src={icon_zoom_out} />
            </Button>
            <Button
              id="zoom-btn-increase"
              className="zoom-btn"
              style={{}}
              onClick={() => {
                this.props.increaseBookZoom();
              }}
              role="button"
              type="button"
            >
              <img alt="" src={icon_zoom_in} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
