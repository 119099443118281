import { Col, Row } from "react-bootstrap";
import GridBook from "./GridBook";
import SelectedGridIcon from "../../images/selected-layout-grid.svg";
import SelectedListIcon from "../../images/selected-list.svg";
import GridIcon from "../../images/layout-grid.svg";
import ListIcon from "../../images/list.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BooksGrid.scss";
import { useState, useEffect, useRef } from "react";
import ListBook from "./ListBook";
import FilterDropdown from "./FilterDropdown";
import SectionImage from "../../images/section_image.svg";

const BooksGrid = ({
  listView,
  emptySearchMessage,
  filteredBooks,
  count,
  isStudent,
  shouldHide,
  addBook,
  removeBook,
  openBook,
  bookInfo,
  displayBookReadingLevel,
  showReadingLevel,
  changeView,
  section,
  defaultPhoto,
  constants,
  toastr,
  downloadBook,
  fetchMoreData,
  isSearchingBooks,
  sectionImage,
  bookBagSelected = false,
  hideNav,
}) => {
  const [books, setBooks] = useState(filteredBooks);
  const [gridSelected, setGridSelected] = useState(listView ? false : true);
  const lastElementRef = useRef(null);

  const handleIconSelect = (option) => {
    !option ? setGridSelected(true) : setGridSelected(false);
    changeView(option);
  };

  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      console.info('handleIntersection', section);
      fetchMoreData(section);
    }
  };

  const handleBooksFilter = (books) => {
    setBooks(books);
  };

  useEffect(() => {
    setBooks(filteredBooks);
  }, [filteredBooks]);

  useEffect(() => {
    const refItem = lastElementRef.current;

    if (!isSearchingBooks && !bookBagSelected) {
      const observer = new IntersectionObserver(handleIntersection);

      if (lastElementRef.current) {
        observer.observe(lastElementRef.current);
      }

      return () => {
        if (refItem) {
          observer.unobserve(refItem);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [books, section]);

  return (
    <div className="books-grid-list" style={{ padding: "0 7rem" }} data-hubs={hideNav}>
      <div className="header d-flex align-items-center w-100 justify-content-between header-actions">
        <div className="left">
          <div
            className="slider-header align-items-center"
            style={{ marginRight: 0, marginLeft: "-5%" }}
          >
            {!!sectionImage ? (
              <img
                className="section-image"
                src={sectionImage}
                alt="Category"
              />
            ) : (
              <img src={SectionImage} alt="Default" />
            )}
            <p className="query slider-heading">{section}</p>
            <p className="count slider-heading">{count}</p>
          </div>
        </div>
        <div
          className="right  align-items-center right-inner-header-actions "
          style={{ marginRight: "0%" }}
        >
          {!isSearchingBooks && (
            <FilterDropdown
              books={books}
              handleBooksFilter={handleBooksFilter}
              filteredBooks={filteredBooks}
            />
          )}

          <button
            className="no-btn mr-3"
            onClick={() => handleIconSelect(false)}
          >
            {!gridSelected ? (
              <img src={GridIcon} alt="grid_icon" />
            ) : (
              <img src={SelectedGridIcon} alt="grid_icon" />
            )}
          </button>
          <button className="no-btn" onClick={() => handleIconSelect(true)}>
            {gridSelected ? (
              <img src={ListIcon} alt="list_icon" />
            ) : (
              <img src={SelectedListIcon} alt="list_icon" />
            )}
          </button>
        </div>
      </div>
      <>
        {listView ? (
          <div className="book-list-view animated bounceInUp">
            {filteredBooks?.length <= 0 && (
              <Col>
                <div className="empty-search-message">No results found</div>
              </Col>
            )}
            {filteredBooks?.length > 0 &&
              books.map((book) => (
                <ListBook
                  key={book.ID}
                  book={book}
                  isStudent={isStudent}
                  shouldHide={shouldHide}
                  defaultPhoto={defaultPhoto}
                  downloadBook={downloadBook}
                  openBook={openBook}
                  addBook={addBook}
                  removeBook={removeBook}
                  displayBookReadingLevel={displayBookReadingLevel}
                  showReadingLevel={showReadingLevel}
                  constants={constants}
                  toastr={toastr}
                />
              ))}
          </div>
        ) : (
          <Row className="books-row text-center animated bounceInUp">
            {filteredBooks?.length <= 0 && (
              <Col>
                <div className="empty-search-message">{emptySearchMessage}</div>
              </Col>
            )}
            {filteredBooks?.length > 0 && (
              <>
                {books.map((book, index) => {
                  if (index === filteredBooks?.length - 1) {
                    return (
                      <div key={book.ID}>
                        <Col
                          key={book.ID}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={2}
                          className="mr-3 px-0 pb-4"
                        >
                          <GridBook
                            book={book}
                            isStudent={isStudent}
                            shouldHide={shouldHide}
                            openBook={openBook}
                            bookInfo={bookInfo}
                            removeBook={removeBook}
                            displayBookReadingLevel={displayBookReadingLevel}
                            showReadingLevel={showReadingLevel}
                            addBook={addBook}
                            bookBagSelected={bookBagSelected}
                          />
                        </Col>
                      </div>
                    );
                  } else {
                    return (
                      <Col
                        key={book.ID}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2}
                        className="mr-3 px-0 pb-4"
                      >
                        <GridBook
                          book={book}
                          isStudent={isStudent}
                          shouldHide={shouldHide}
                          openBook={openBook}
                          bookInfo={bookInfo}
                          removeBook={removeBook}
                          displayBookReadingLevel={displayBookReadingLevel}
                          showReadingLevel={showReadingLevel}
                          addBook={addBook}
                          bookBagSelected={bookBagSelected}
                        />
                      </Col>
                    );
                  }
                })}
                {books?.length > 0 && <div className="last-element" ref={lastElementRef}></div>}
              </>
            )}
          </Row>
        )}
      </>
    </div>
  );
};

export default BooksGrid;
