import React from "react";
import icon_archive from "../../images/Backback.svg";
import remove_book from "../../images/Backback-Remove.svg";
import icon_read_book from "../../images/Info.png";
import icon_audio from "../../images/Listen_icon.png";
import "./GridBook.scss";

const GridBook = ({
  key,
  openBook,
  bookInfo,
  book,
  isStudent,
  shouldHide,
  displayBookReadingLevel,
  showReadingLevel,
  addBook,
  removeBook,
  bookBagSelected,
}) => {
  const [showBookBagBtns, setShowBookBagBtns] = React.useState(!shouldHide && isStudent);


  React.useEffect(() => {
    setShowBookBagBtns(!shouldHide && isStudent);
  }, [shouldHide, isStudent]);


  return (
    <>
      <div
        key={key}
        onClick={(e) => {
          e.stopPropagation();
          openBook(book);
        }}
        className="gridBook"
        data-author={book.IsAudioAvailable}
      >
        {book.OfficialImage ?
          (<img className="book-img" src={book.OfficialImage} alt="" />) :
          (<div className="book-placeholder"><p>{book.Title}</p></div>)
        }
        {showReadingLevel && (
          <div className="reading-level">
            {displayBookReadingLevel(book, true)}
          </div>
        )}
        <button
          type="button"
          className="bottom-right-button"
          onClick={(e) => {
            e.stopPropagation();
            bookInfo(book);
          }}
        >
          <div className="icon-wrap">
            <img className="icon-img" alt="" src={icon_read_book} />
          </div>
        </button>

        {book.IsEPub && (
          <button
            type="button"
            className="bottom-left-button"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="icon-wrap">
              <img className="icon-img" alt="" src={icon_audio} />
            </div>
          </button>
        )}

        {showBookBagBtns && !book.IsStudentAdded && !book.isInBookBag && (
          <button
            type="button"
            className="top-right-button"
            onClick={(e) => {
              e.stopPropagation();
              addBook(book);
            }}
          >
            <img className="icon-img" alt="" src={icon_archive} />
          </button>
        )}

        {showBookBagBtns && book.IsStudentAdded && book.isInBookBag && (
          <button
            type="button"
            className="top-right-button"
            onClick={(e) => {
              e.stopPropagation();
              removeBook(book.ID, book.ISBN);
            }}
          >
            <img className="icon-img" alt="" src={remove_book} />
          </button>
        )}
      </div>
    </>
  );
};

export default GridBook;
