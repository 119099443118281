import { reducer as offlineQueue } from 'redux-queue-offline';
import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as toastr, ToastrState } from 'react-redux-toastr';
import user from './userReducer';
import { bookbagFiltersReducer as bookbagFilters } from './bookbagFiltersReducer';
import { dashboard } from './dashboardReducer';

import { reducer as jPlayers } from 'react-jplayer';
import {
    blmReducer as blm,
    blmsReducer as blms,
    completedBLMsReducer as completedBLMs
} from './blmReducer';
import {
    bookReducer as book,
    booksReducer as books,
    hubAndBooksReducer as hubAndBooks,
    booksCountReducer as booksCount,
    downloadedBooksReducer as downloadedBooks,
    dashboardPageResultsReducer as dashboardPageResults
} from './bookReducer';
import bookView from './bookViewReducer';
import { projectToolbar } from './projectToolbarReducer';
import bookToolbar from './bookToolbarReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';
import viewerMode from './modeReducer';
import readium from './readiumReducer';

const rootReducer = combineReducers({
    appVersion: (state = {}) => state,
    offlineQueue,
    toastr,
    user,
    blms,
    completedBLMs,
    blm,
    book,
    books,
    hubAndBooks,
    booksCount,
    downloadedBooks,
    routing,
    viewerMode,
    bookbagFilters,
    dashboard,
    ajaxCallsInProgress,
    jPlayers,
    projectToolbar,
    bookView,
    bookToolbar,
    dashboardPageResults,
    readium,
});

export type IinitialState = ReturnType<typeof rootReducer> &
    { toastr: ToastrState };

export default rootReducer;
