import * as React from "react";
import Slider from "rc-slider";
import {
  Button,
  Col,
  Dropdown,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import { StyledDiv } from "../../constants/styledComponents";

import CommonModal from "../common/CommonModal";
import constants from "../../constants/constants";
import icon_hl_green from "../../images/icon_hl_green.png";
import icon_hl_orange from "../../images/icon_hl_orange.png";
import icon_hl_teal from "../../images/icon_hl_teal.png";
import icon_hl_yellow from "../../images/icon_hl_yellow.png";
import icon_notes from "../../images/notes.svg";
import icon_underline from "../../images/underline.svg";
import icon_strikethrough from "../../images/strikethrough.svg";
import icon_highlight from "../../images/highlight.svg";
import icon_arrow from "../../images/arrow-big-top.svg";
import icon_eraser from "../../images/eraser.svg";
import icon_pointer from "../../images/icon_pointer.png";
import icon_pages from "../../images/pages.svg";
import icon_file from "../../images/file.svg";
import move_icon from "../../images/move_icon.png";
import { Ibook, IbookToolbar, Iuser, IviewerSettings } from "../../models";
import { viewerModes } from "../../constants/viewerModes";

interface Iprops {
  lastPage: () => void;
  firstPage: () => void;
  nextPage: () => void;
  prevPage: () => void;
  user: Iuser;
  book: Ibook;
  startHighlighter: (e: any, color: string) => void;
  startEraser: () => void;
  startPointer: () => void;
  startNotes: () => void;
  openDrawer: () => void;
  viewerSettings: IviewerSettings;
  currentPage: number;
  blmMode: boolean;
  pagesVisible: number;
  bookToolbar: IbookToolbar;
  decreaseBookZoom: () => void;
  increaseBookZoom: () => void;
  viewerMode?: string;
  goToPage: (
    pageNumber: number,
    callback?: () => void,
    shouldCheckLeftPageEven?: boolean,
  ) => void;
  bookIsZooming: boolean;
  openSidePanel: (openPanel: boolean) => void;
  close: boolean;
  handleScroll(): void;
  updatePage: (newPage: number) => void;
}
interface Istate {
  showGoToPageModal: boolean;
  goToPage: string;
  showBookMarkupTools: boolean;
  arrowUp: boolean;
  arrowClass: string;
}
const theme = constants.themeProvider.activeTheme;

class Toolbar extends React.Component<Iprops, Istate> {
  private startedHighlighting = false;

  constructor(props: Iprops) {
    super(props);
    this.state = {
      showGoToPageModal: false,
      goToPage: "",
      showBookMarkupTools: this.shouldShowMarkupTools(),
      arrowClass: "fa fa-angle-double-down",
      currentPage: this.props.currentPage,
    };
    this.startedHighlighting = false;
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.close !== prevProps.close) {
      this.props.close
        ? this.setState({ arrowClass: "fa fa-angle-double-up" })
        : this.setState({ arrowClass: "fa fa-angle-double-down" });
    }

    if (this.props.currentPage !== prevProps.currentPage) {
      this.setState({ currentPage: this.props.currentPage });
    }
  }
  /**
   * @return {boolean}
   */
  shouldShowMarkupTools = (): boolean => {
    if (!this.props.viewerMode) {
      return true;
    }
    const hasCorrectViewerMode =
      this.props.viewerMode !== viewerModes.MODE_TEACHER_VIEW_STUDENT_BOOK &&
      this.props.viewerMode !== viewerModes.MODE_TEACHER_CLASS_NOTES &&
      this.props.viewerMode !== viewerModes.MODE_TEACHER_GROUP_NOTES &&
      this.props.viewerMode !== viewerModes.MODE_TEACHER_STUDENT_BLM &&
      this.props.viewerMode !== viewerModes.MODE_RESOURCE;

    const { IsEPub } = this.props.book;
    return hasCorrectViewerMode && IsEPub;
  };

  startHL = (e: any, color: string) => {
    // console.log('starting hl', e.type)
    /*
     * stopPropegation rather than preventDefault because preventDefault on iOS leaves the selected text highlighted
     * and we do not want the text to remain highlighted.  Then you can't see the color of the highlight.
     * Unfortunately only dong stopPropagation does not prevent this from being clicked twice... so we have a timeout.
     * after further testing apparently we do not need to preventDefault here...  Will keep this around for a bit.
     */
    // if (this.startedHighlighting) return;
    // this.startedHighlighting = true;
    // setTimeout(() => {
    //   this.startedHighlighting = false;
    // }, 300)

    // if (e.type === 'touchend'){
    // e.stopPropagation();
    // }
    // e.preventDefault();

    this.props.startHighlighter(e, color);
  };
  closeGoToPageModal = () => {
    this.setState({ showGoToPageModal: false });
  };
  openGoToPageModal = (e: any) => {
    e.preventDefault();
    this.setState({ showGoToPageModal: true });
  };

  submitGoToPage = () => {
    this.setState(
      {
        showGoToPageModal: false,
      },
      () => {
        this.props.goToPage(parseInt(this.state.goToPage, 10));
        this.setState({ goToPage: "" });
      },
    );
  };
  toggleColors = (isOpen: boolean, e: any) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  handleChange = (e: any) => {
    this.setState({
      goToPage: e.target.value,
    });
  };

  inputForm = () => {
    return (
      <Row>
        <Col md={12} className="dibs-form">
          <FormGroup>
            <FormControl
              type="tel"
              placeholder="Go to page"
              value={this.state.goToPage}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  };

  render() {
    let icon_hl = icon_hl_yellow;
    let toolbarStyle, pointerIcon;
    if (this.props.bookToolbar.highlightColor === "hl-green") {
      icon_hl = icon_hl_green;
    } else if (this.props.bookToolbar.highlightColor === "hl-teal") {
      icon_hl = icon_hl_teal;
    }
    if (this.props.bookToolbar.highlightColor === "hl-orange") {
      icon_hl = icon_hl_orange;
    }
    if (this.props.viewerSettings.showToolbar) {
      toolbarStyle = "toolbar row";
    } else {
      toolbarStyle = "toolbar row d-none";
    }
    if (this.props.bookIsZooming) {
      pointerIcon = move_icon;
    } else {
      pointerIcon = icon_pointer;
    }
    const bookToolbar = this.props.bookToolbar;
    const pointerClassName = bookToolbar.pointing
      ? "item  hl-pointer active"
      : "item  hl-pointer";
    const highlighterClassName =
      bookToolbar.highlighting &&
      bookToolbar.highlightColor !== "hl-strikethrough" &&
      bookToolbar.highlightColor !== "hl-underline"
        ? `item-child btn btn-light dropdown-toggle hl-highlight active`
        : "item-child btn btn-light dropdown-toggle hl-highlight";
    const strikeClassName =
      bookToolbar.highlighting &&
      bookToolbar.highlightColor === "hl-strikethrough"
        ? "item icon-btn hl-strike active"
        : "item icon-btn hl-strike";
    const underlineClassName =
      bookToolbar.highlighting && bookToolbar.highlightColor === "hl-underline"
        ? "item icon-btn hl-under active"
        : "item icon-btn hl-under";
    const eraseClassName = bookToolbar.erasing
      ? "item hl-erase active"
      : "item hl-erase";
    let notesClassName = bookToolbar.allowNotes
      ? "item hl-notes active"
      : "item hl-notes";
    if (this.props.book.IsEPub) {
      notesClassName = "hide";
    }
    // hide the bottom toolbar if in single page Project mode
    if (this.props.blmMode && this.props.pagesVisible === 1) {
      return null;
    }

    const step =
      Math.ceil(this.props.book.pagecount / 8) >=
      this.props.book.pagecount - this.props.book.currentPage
        ? this.props.book.pagecount % 2 === 0
          ? this.props.book.pagecount - this.props.book.currentPage - 1
          : this.props.book.pagecount - this.props.book.currentPage
        : Math.ceil(this.props.book.pagecount / 8);

        return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <StyledDiv className={toolbarStyle}>
          {!this.props.book.IsEPub && (
            <>
            <div className="tools-btn-container">
              <div className="iconTools">
                {this.state.showBookMarkupTools && (
                  <>
                    <Button
                      className="page-btn"
                      onClick={this.props.startPointer}
                      role="button"
                      type="button"
                    >
                      <img alt="" className="centered-img" src={icon_arrow} />
                    </Button>
                    <Dropdown
                      id="dropdown-custom-menu"
                      className="dropup item"
                      onToggle={(isOpen, e) => this.toggleColors(isOpen, e)}
                      drop="up"
                    >
                      <Dropdown.Toggle className="page-btn">
                        <img
                          alt=""
                          className="centered-img"
                          src={icon_highlight}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu toolbar-menu">
                        <Dropdown.Item
                          className="hl-yellow btn"
                          onClick={(e) => {
                            this.startHL(e, "hl-yellow");
                          }}
                          onTouchEnd={(e: any) => {
                            this.startHL(e, "hl-yellow");
                          }}
                        />
                        <Dropdown.Item
                          className="hl-orange btn"
                          onClick={(e) => {
                            this.startHL(e, "hl-orange");
                          }}
                          onTouchEnd={(e: any) => {
                            this.startHL(e, "hl-orange");
                          }}
                        />
                        <Dropdown.Item
                          className="hl-green btn"
                          onClick={(e) => {
                            this.startHL(e, "hl-green");
                          }}
                          onTouchEnd={(e: Event) => {
                            this.startHL(e, "hl-green");
                          }}
                        />
                        <Dropdown.Item
                          className="hl-teal btn"
                          onClick={(e) => {
                            this.startHL(e, "hl-teal");
                          }}
                          onTouchEnd={(e: any) => {
                            this.startHL(e, "hl-teal");
                          }}
                        />
                      </Dropdown.Menu>
                    </Dropdown>

                    <Button
                      className="toolbar-page-btn"
                      onClick={(e) =>
                        this.props.startHighlighter(e, "hl-strikethrough")
                      }
                      onTouchEnd={(e) =>
                        this.props.startHighlighter(e, "hl-strikethrough")
                      }
                      role="button"
                      type="button"
                    >
                      <img
                        alt=""
                        className="centered-img"
                        src={icon_strikethrough}
                      />
                    </Button>
                    <Button
                      className="toolbar-page-btn"
                      onClick={(e) =>
                        this.props.startHighlighter(e, "hl-underline")
                      }
                      onTouchEnd={(e) =>
                        this.props.startHighlighter(e, "hl-underline")
                      }
                      role="button"
                      type="button"
                    >
                      <img
                        alt=""
                        className="centered-img"
                        src={icon_underline}
                      />
                    </Button>

                    <Button
                      className="toolbar-page-btn"
                      onClick={this.props.startEraser}
                      role="button"
                      type="button"
                    >
                      <img alt="" className="centered-img" src={icon_eraser} />
                    </Button>
                  </>
                )}

                <Button
                  className="toolbar-page-btn"
                  onClick={this.props.startNotes}
                  role="button"
                  type="button"
                >
                  <img alt="" className="centered-img" src={icon_notes} />
                </Button>
              </div>
            </div>
            <div
              className="item page-slider"
              onScroll={this.props.handleScroll}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Slider
                  onChange={(nextValues) => {
                    console.log("Change:", nextValues);
                    const newPage = Math.min(
                      Math.max(Math.round(nextValues), 1),
                      this.props.book.pagecount,
                    );

                    this.props.updatePage(newPage);
                  }}
                  min={1}
                  max={this.props.book.pagecount}
                  step={step || 1}
                  value={this.state.currentPage}
                  defaultValue={this.props.currentPage}
                />
                <p className="current-page-info">
                  <span className="bubble-text">{this.props.currentPage}</span>
                  of {this.props.book.pagecount}
                </p>
              </div>
            </div>
            </>
          )}
          <div className="pages-btn-container">
            <Button
              className="toolbar-page-btn"
              onClick={() => this.props.updatePagesVisible(1)}
              role="button"
              type="button"
            >
              <img alt="" className="centered-img" src={icon_file} />
            </Button>
            <Button
              className="toolbar-page-btn"
              onClick={() => this.props.updatePagesVisible(2)}
              role="button"
              type="button"
            >
              <img alt="" className="centered-img" src={icon_pages} />
            </Button>
          </div>
        </StyledDiv>
        <CommonModal
          name="go-to-page"
          className="common-modal"
          bsSize="small"
          title={`What page would you like to go to?`}
          children={this.inputForm()}
          modalVisable={this.state.showGoToPageModal}
          cancelText="Cancel"
          submitText="GO"
          cancel={() => this.closeGoToPageModal()}
          submit={this.submitGoToPage}
        />
      </div>
    );
  }
}

export default Toolbar;
