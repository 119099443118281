import { throttle } from "lodash";

import constants from "../constants/constants";
import { withinRange } from "./withinRange";

export interface BookDimensions {
  width: number;
  height: number;
}

export interface BookScaling {
  type: string;
  scale: number;
  zoom: number;
  index: number;
}

export const getBookScaling = throttle(
  (dimensions: BookDimensions): BookScaling => {
    const {
      bookSizes: {
        short,
        small,
        squared,
        squaredSmall,
        squaredLarge,
        standard,
        tall,
        wide,
        extrawide,
      },
    } = constants;

    switch (true) {
      // squared
      case dimensions.width === squared.width &&
        dimensions.height === squared.height:
        return { type: "squared", scale: 0.12, zoom: 0.9, index: 4 };
      // squaredLarge
      case dimensions.width === squaredLarge.width &&
        dimensions.height === squaredLarge.height:
        return { type: "squaredLarge", scale: 0.08, zoom: 0.65, index: 1 };
      // squaredSmall
      case withinRange(
        dimensions.width,
        squaredSmall.width[0],
        squaredSmall.width[1],
      ) &&
        withinRange(
          dimensions.height,
          squaredSmall.height[0],
          squaredSmall.height[1],
        ):
        return { type: "squaredSmall", scale: 0.145, zoom: 1.4, index: 7 };
      // small
      case dimensions.width === small.width &&
        dimensions.height === small.height:
        return { type: "small", scale: 0.14, zoom: 1.4, index: 7 };
      // short
      case withinRange(dimensions.width, short.width[0], short.width[1]) &&
        withinRange(dimensions.height, short.height[0], short.height[1]):
        return { type: "short", scale: 0.11, zoom: 0.8, index: 3 };
      // standard
      case dimensions.width === standard.width &&
        dimensions.height === standard.height:
        return { type: "standard", scale: 0.0975, zoom: 0.8, index: 3 };
      // wide
      case dimensions.width === wide.width && dimensions.height === wide.height:
        return { type: "wide", scale: 0.12, zoom: 0.95, index: 5 };
      // extrawide
      case dimensions.width === extrawide.width &&
        dimensions.height === extrawide.height:
        return { type: "extrawide", scale: 0.0975, zoom: 0.8, index: 3 };
      // tall
      case withinRange(dimensions.width, tall.width[0], tall.width[1]) &&
        withinRange(dimensions.height, tall.height[0], tall.height[1]):
        return { type: "tall", scale: 0.08, zoom: 0.7, index: 2 };
      default:
        return { type: "standard", scale: 0.1, zoom: 0.9, index: 4 };
    }
  },
  1000,
  { leading: true, trailing: true },
);
