import { themeProvider } from '../utilities/themeProvider';

const constants = {
    // UserRoleIDs: {
    //   Teacher: "C89F9DD9-ED08-43F5-8FC8-DBFCB1A3D008", // access book by clicking e-book in teacher portal
    //   SuperAdmin: "BA2ED223-7F90-4700-B04E-3DA0025D2F5A", // access book by clicking e-book in teacher portal
    //   SchoolAdmin: "B751ECE9-97E1-439D-BC27-9665E47D847E", // access book by clicking e-book in teacher portal
    //   SalesRep: "D335673F-182A-4E72-B4B6-F20856698843", // cannot get into login - only admin panel
    //   Student: "357001F1-7DC2-496F-82F3-8FC21813BF6C", // can login, has bookbag, remote search, can highlight make notes and those save to db
    //   Generic: "2E4030D1-BD1A-451E-B1C8-010CAA433BBC", // can login, see remote page, access bookview and highlight but cannot save to db
    //   Demo: "E3F41C5E-DC13-4859-9A51-3124B6724297" // can access 25% of the book
    // },

    // typeGUID: {
    //   math: 'FDDCA1E8-4BE2-45E2-9DE7-CB9D43D60A44',
    //   languageArts: 'D675D783-CA70-47F7-A600-FD7CE2669594',
    //   science: 'C17493D4-1A3A-4AA3-A707-606C1C839758',
    //   socialStudies: 'f7a70888-fd0c-43c2-b619-223eff1c1a4f'
    // }
    themeProvider,
    toastrSuccessOptions: {
        position: 'top-right',
        transitionIn: 'fadeIn',
        transitionOut: 'fadeOut',
        timeOut: 5000
    },
    toastrErrorOptions: {
        timeOut: 8000
    },
    toastrWarningOptions: {
        position: 'top-right',
        transitionIn: 'bounceInDown',
        transitionOut: 'bounceOutUp',
        timeOut: 5000
    },
    testSpeechMarkRaw: `{"time":6,"type":"word","start":35,"end":36,"value":"6"}
    {"time":596,"type":"word","start":37,"end":45,"value":"HISTORIC"}
    {"time":1253,"type":"word","start":46,"end":53,"value":"FIGURES"}
    {"time":1862,"type":"word","start":54,"end":56,"value":"of"}
    {"time":2093,"type":"word","start":57,"end":65,"value":"theWORLD"}
    {"time":3531,"type":"word","start":111,"end":114,"value":"The"}
    {"time":3733,"type":"word","start":115,"end":126,"value":"Exploration"}
    {"time":4770,"type":"word","start":127,"end":134,"value":"Station"}
    {"time":6145,"type":"word","start":181,"end":191,"value":"Unscramble"}
    {"time":6969,"type":"word","start":192,"end":195,"value":"the"}
    {"time":7064,"type":"word","start":196,"end":204,"value":"captions"}
    {"time":7855,"type":"word","start":205,"end":207,"value":"to"}
    {"time":8036,"type":"word","start":208,"end":216,"value":"discover"}
    {"time":8666,"type":"word","start":217,"end":220,"value":"the"}
    {"time":8784,"type":"word","start":221,"end":226,"value":"names"}
    {"time":9273,"type":"word","start":227,"end":229,"value":"of"}
    {"time":9425,"type":"word","start":230,"end":239,"value":"different"}
    {"time":10012,"type":"word","start":240,"end":249,"value":"explorers"}
    {"time":11456,"type":"word","start":251,"end":255,"value":"Each"}
    {"time":11788,"type":"word","start":256,"end":263,"value":"cartoon"}
    {"time":12341,"type":"word","start":264,"end":269,"value":"gives"}
    {"time":12645,"type":"word","start":270,"end":271,"value":"a"}
    {"time":12722,"type":"word","start":272,"end":276,"value":"clue"}
    {"time":13056,"type":"word","start":277,"end":279,"value":"to"}
    {"time":13234,"type":"word","start":280,"end":283,"value":"the"}
    {"time":13429,"type":"word","start":284,"end":296,"value":"explorer’s"}
    {"time":14349,"type":"word","start":297,"end":305,"value":"identity"}
    {"time":15505,"type":"word","start":307,"end":311,"value":"Find"}
    {"time":15922,"type":"word","start":312,"end":315,"value":"the"}
    {"time":16113,"type":"word","start":316,"end":323,"value":"answers"}
    {"time":16781,"type":"word","start":324,"end":326,"value":"on"}
    {"time":16997,"type":"word","start":327,"end":331,"value":"page"}
    {"time":17433,"type":"word","start":332,"end":334,"value":"44"}
    {"time":18986,"type":"word","start":383,"end":384,"value":"I"}
    {"time":19145,"type":"word","start":385,"end":391,"value":"landed"}
    {"time":19658,"type":"word","start":392,"end":394,"value":"in"}
    {"time":19827,"type":"word","start":395,"end":399,"value":"what"}
    {"time":20092,"type":"word","start":400,"end":402,"value":"is"}
    {"time":20294,"type":"word","start":403,"end":406,"value":"now"}
    {"time":20596,"type":"word","start":407,"end":410,"value":"the"}
    {"time":20712,"type":"word","start":411,"end":418,"value":"Bahamas"}
    {"time":21352,"type":"word","start":419,"end":421,"value":"in"}
    {"time":21567,"type":"word","start":422,"end":424,"value":"14"}
    {"time":22139,"type":"word","start":424,"end":426,"value":"92"}
    {"time":22950,"type":"word","start":427,"end":430,"value":"and"}
    {"time":23126,"type":"word","start":431,"end":435,"value":"then"}
    {"time":23369,"type":"word","start":436,"end":442,"value":"sailed"}
    {"time":23897,"type":"word","start":443,"end":445,"value":"to"}
    {"time":24011,"type":"word","start":446,"end":450,"value":"Cuba"}
    {"time":24453,"type":"word","start":451,"end":454,"value":"and"}
    {"time":24715,"type":"word","start":455,"end":465,"value":"Hispaniola"}
    {"time":26262,"type":"word","start":467,"end":469,"value":"an"}
    {"time":26413,"type":"word","start":470,"end":476,"value":"island"}
    {"time":26834,"type":"word","start":477,"end":481,"value":"that"}
    {"time":27028,"type":"word","start":482,"end":487,"value":"Haiti"}
    {"time":27492,"type":"word","start":488,"end":491,"value":"and"}
    {"time":27729,"type":"word","start":492,"end":495,"value":"the"}
    {"time":27816,"type":"word","start":496,"end":505,"value":"Dominican"}
    {"time":28508,"type":"word","start":506,"end":514,"value":"Republic"}
    {"time":29122,"type":"word","start":515,"end":520,"value":"share"}
    {"time":29481,"type":"word","start":521,"end":526,"value":"today"}
    {"time":30777,"type":"word","start":573,"end":577,"value":"Help"}
    {"time":31089,"type":"word","start":578,"end":581,"value":"Tom"}
    {"time":31427,"type":"word","start":582,"end":587,"value":"Scour"}
    {"time":31957,"type":"word","start":588,"end":591,"value":"His"}
    {"time":32224,"type":"word","start":592,"end":596,"value":"Curb"}`,

    bookZoomLevels: [60, 65, 70, 80, 90, 95, 100, 120, 140, 160, 180, 200],
    bookSizes: {
        squared: {
            width: 825,
            height: 825,
        },
        squaredSmall: {
            width: [655, 660],
            height: [655, 660],
        },
        squaredLarge: {
            width: 880,
            height: 880,
        },
        small: {
            width: 665,
            height: 653,
        },
        short: {
            width: [656, 660],
            height: [858, 880],
        },
        standard: {
            width: 660,
            height: 990,
        },
        tall: {
            width: [825, 935],
            height: [1127, 1210],
        },
        wide: {
            width: 962,
            height: 811,
        },
        extrawide: {
            width: 962,
            height: 990,
        },
    },
    audioHighlightLeadTime: 50,
    audioDelayBetweenPages: 200,
    audioResyncTime: 160,
    topToolbarToggleHeight: 40,
    bottomToolbarHeight: 80,
    forceLogoutTimeWarning: 1000 * 60 * 60 * 24 * 27, // 30 days,
};

export default constants;
