import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import constants from '../../constants/constants';
import { StyledButton } from '../../constants/styledComponents';

class CommonModal extends React.Component {
    theme = constants.themeProvider.activeTheme;

    render() {
        return (
            <div>
                <Modal
                    show={this.props.modalVisable}
                    onHide={this.props.cancel}
                    bsSize={this.props.bsSize}
                    className={this.props.className}
                    backdrop={this.props.backdrop}
                >
                    <Modal.Header>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <form
                        id="commonForm"
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.props.submit(e);
                        }}
                        className="dibs-form"
                    >
                        <Modal.Body>{this.props.children}</Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="pull-left cancel"
                                type="button"
                                variant="light"
                                onClick={this.props.cancel}
                            >
                                {this.props.cancelText}
                            </Button>
                            <StyledButton
                                style={{
                                borderRadius: '30px',
                                backgroundColor: '#3DB8F5',
                                borderBottom: '1px',
                                width: '100px'
                                }}
                                type="submit"
                            >
                                {this.props.submitText}
                            </StyledButton>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default CommonModal;
