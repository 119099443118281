import React from "react";
import FabricUI from "./FabricUI";
import { round } from "lodash";

class BLM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageContainerWidth: 0,
      pageContainerHeight: 0,
      marginLeft: 0,
      pageClassName: "",
      pageWidth: 0,
      pageHeight: 0,
    };
    this.resizeProjectPage = this.resizeProjectPage.bind(this);
  }
  componentDidMount() {
    console.log("Project (BLM) component mounted");
    this.resizeProjectPage();
    window.addEventListener("resize", this.resizeProjectPage);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeProjectPage);
    console.log("unmounting BLM");
  }
  componentDidUpdate(prevProps) {
    let shouldResize = false;
    if (
      prevProps.leftPageContainerWidth !== this.props.leftPageContainerWidth ||
      prevProps.leftPageContainerHeight !== this.props.leftPageContainerHeight
    ) {
      shouldResize = true;
      // console.log('leftPageContainer changed in Project');
    }
    if (
      prevProps.blm.Title !== this.props.blm.Title ||
      prevProps.blmMode !== this.props.blmMode
    ) {
      shouldResize = true;
      // console.log('blm changed in project');
    }
    if (prevProps.pagesVisible !== this.props.pagesVisible) {
      shouldResize = true;
      // console.log('pagesVisible changed in project')
    }
    if (shouldResize) {
      this.resizeProjectPage();
    }
  }
  resizeProjectPage() {
    const topToolbarToggle = 40; // and close button
    const bottomToolbarHeight = 120;
    const widthOfArrow = 60;
    const widthOfProjectToolbar = 50;
    let maxContentHeight = 0;
    let maxContentWidth = 0;
    let scalePercent = 1;
    let pageContainerWidth = 0;
    let pageContainerHeight = 0;
    let marginLeft = 0;

    const pageWidth = this.props.blm.bounds[0][0];
    const pageHeight = this.props.blm.bounds[0][1];

    // if Project is displaying next to a book page, then subtract the page width and the two arrow buttson from the available space
    if (this.props.pagesVisible === 1) {
      maxContentHeight = window.innerHeight - topToolbarToggle;
      maxContentWidth = window.innerWidth - widthOfProjectToolbar - 80; // plus some extra whitespace
    } else {
      maxContentHeight =
        window.innerHeight - topToolbarToggle - bottomToolbarHeight;
      maxContentWidth =
        window.innerWidth -
        this.props.leftPageContainerWidth -
        widthOfArrow * 2 -
        widthOfProjectToolbar;
      marginLeft = this.props.leftPageContainerWidth + 50;
    }
    const heightDiff = pageHeight - maxContentHeight; // difference between the height of the project and the available hight we can display it in
    let widthDiff = pageWidth - maxContentWidth; // difference between the width of the project and the available width we have to display it in

    let pageClassName = this.getProjectClassName();
    // if we are lacking mostly height, then scale down by height
    if (heightDiff < widthDiff) {
      // TODO we might be able to increase the available width to display the wide BLM by adding margin top for the close button
      // limit by width
      scalePercent = round(maxContentWidth / pageWidth, 3);
      pageContainerWidth = pageWidth * scalePercent;
      pageContainerHeight = pageHeight * scalePercent;
      // console.log('resizing blm by width', maxContentHeight, maxContentWidth, pageContainerWidth, pageContainerHeight)
    } else {
      // limit by height
      scalePercent = round(maxContentHeight / pageHeight, 3);
      pageContainerWidth = pageWidth * scalePercent;
      pageContainerHeight = pageHeight * scalePercent;
      // console.log('resizing blm by height', maxContentHeight, maxContentWidth, pageContainerWidth, pageContainerHeight)
    }
    this.setState({
      pageContainerWidth,
      pageContainerHeight,
      pageClassName,
      marginLeft,
      pageWidth,
      pageHeight,
    });
    this.props.updateProjectScalePercent(scalePercent);
  }

  getProjectClassName() {
    let pageClassName = "blm ";
    if (this.props.pagesVisible === 1) {
      pageClassName += "single-page";
    }
    return pageClassName;
  }

  render() {
    /*
     * size and position self
     * if single page: 'left page-left single-page'
     * if 2 page: 'right page-right blm-page'
     */
    const pagesVisible = this.props.pagesVisible;
    let projectClassName = "";
    if (pagesVisible === 1) {
      projectClassName = "page left page-left single-page";
    } else {
      projectClassName = "page right page-right blm-page";
    }
    if (!this.props.blmHtml) {
      return null;
    }
    const pageStyle = {
      width: `${this.state.pageContainerWidth}px`,
      height: `${this.state.pageContainerHeight}px`,
      marginLeft: `${this.state.marginLeft}px`,
      position: "absolute",
    }; // the width and height will change as we zoom
    const jpedalStyle = {
      width: `${this.state.pageWidth}px`,
      height: `${this.state.pageHeight}px`,
      position: "relative",
      display: "block",
      transform: `translateY(0px) translateX(0px) scale(${this.props.projectScalePercent})`,
      transformOrigin: "top left",
    }; // the scale will change as we zoom.
    return (
      <div id="blm" className={projectClassName} style={pageStyle}>
        <div style={jpedalStyle}>
          <div dangerouslySetInnerHTML={{ __html: this.props.blmHtml }} />
          <FabricUI
            projectScalePercent={this.props.projectScalePercent}
            blm={this.props.blm}
            user={this.props.user}
            toastCont={this.props.toastCont}
            exitBlmMode={this.props.exitBlmMode}
            canvasClassForBlm={this.props.canvasClassForBlm}
            closeBookView={this.props.closeBookView}
            location={this.props.location}
            viewerSettings={this.props.viewerSettings}
            isOnline={this.props.isOnline}
          />
        </div>
      </div>
    );
  }
}

export default BLM;
