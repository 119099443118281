import React from "react";

import { hubLevels } from "../../constants/enums";

import IconReadBook from "../../images/Info.png";
import MissingImg from '../../images/missing_image.svg';

const showName = (hubItem) => {
  const isHub = hubItem?.hasOwnProperty('ParentHubID');

  switch (true) {
    case !isHub && !hubItem.OfficialImage:
      return true;
    case isHub && !hubItem.ImageUrl:
      return true;
    case isHub && hubItem.ShowName:
      return true;
    default:
      return false;
  }
};

const HubItem = ({ displayBookReadingLevel, hubItem, hubLevel, showReadingLevel, bookInfo, handleClick }) => {
  const isHub = hubItem.hasOwnProperty('ParentHubID');
  const hasIcon = hubLevel !== hubLevels.Children && Boolean(hubItem.IconUrl) && hubItem.ShowIcon;
  const title = isHub ? hubItem.Name : hubItem.Title;

  return (
    <button type="button" className="grid-item" onClick={() => handleClick(hubItem)}>
      {hasIcon && (
        <img src={hubItem.IconUrl} alt="" className="hub-icon" aria-hidden="true" />
      )}
      <div className="book-cover-wrap" data-icon={`${hasIcon}`}>
        {hubItem.ImageUrl ? (
          <img src={hubItem.ImageUrl} alt={title} className="book-cover" />
        ) : hubItem.OfficialImage ? (
          <img src={hubItem.OfficialImage} alt={title} className="book-cover" />
        ) : (
          <img src={MissingImg} alt={title} className="book-cover" data-missing="true" />
        )}
        {showName(hubItem) && (
          <div className="book-title">
            <p>{title}</p>
          </div>
        )}
        {!isHub && showReadingLevel && (
          <div className="reading-level">
            {displayBookReadingLevel(hubItem, true)}
          </div>
        )}
        {!isHub && (
          <button
            type="button"
            className="info-button"
            onClick={(e) => {
              e.stopPropagation();
              bookInfo(hubItem);
            }}
          >
            <div className="icon-wrap">
              <img className="icon-img" alt="" src={IconReadBook} />
            </div>
          </button>
        )}
      </div>
      {isHub && (
        <>
          <div className="book-cover-stack" data-stack="1"></div>
          <div className="book-cover-stack" data-stack="2"></div>
        </>
      )}
    </button>
  );
};

export default HubItem;