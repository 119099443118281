import {
  FormControl,
  FormGroup,
  Form
} from 'react-bootstrap';
import React, { Component } from 'react';
import {
  StyledButton
} from '../../constants/styledComponents.js';
import { ToastContainer, ToastMessage } from 'react-toastr';
import {
  googleTokenLogin,
  userLogin,
  userLogout
} from '../../actions/userActions';
import {
  manualAjaxEnd,
  manualAjaxStart
} from '../../actions/ajaxStatusActions';
import { validateField, validateForm } from '../common/UtilValidation';

import AsyncSelect from 'react-select/lib/Async';
import { GoogleLogin } from 'react-google-login';
import UserAPI from '../../api/userAPI.js';
import config from '../../api/config';
import { connect } from 'react-redux';
import constants from '../../constants/constants.js';
import debounce from 'throttle-debounce/debounce';
import { hashHistory } from 'react-router';
import { map } from 'lodash';

import './Login.scss';
/* global chrome */

const ToastMessageFactory = React.createFactory(ToastMessage.animation);

const convertToOptions = (items) => {
  return map(items, (item) => {
    if (item.District) {
      return {
        value: item.ID,
        label: `${item.Name} - ${item.District.Name}`
      };
    } else {
      return { value: '', label: '' };
    }
  });
};

const initialComponentState = {
  loginID: '',
  password: '',
  schoolID: '',
  schools: [{ Name: 'type at least 3 characters' }],
  shouldHideSchoolSearch: false,
  formValidations: {
    loginID: {
      validationState: null,
      validationMessage: '',
      valid: true,
      validators: [
        { required: true, message: 'email or username is required' }
      ]
    },
    password: {
      validationState: null,
      validationMessage: '',
      valid: true,
      validators: [
        { required: true, message: 'password is required' },
        {
          pattern: '^.{3,}',
          message: 'Password must be at least 3 characters'
        }
      ]
    },
    schoolID: {
      validationState: null,
      validationMessage: '',
      valid: true,
      validators: [
        {
          required: true,
          message:
            'You must select a School.  Type at least 3 characters then wait for the list to populate.'
        }
      ]
    }
  },
  formValid: false
  /* loading the autosuggestTheme so that we can add "form-control" class to the input.
   * This lets us use the built in form validation classes built into react-bootstrap
   */
};

class LoginForm extends Component {
  loginIDInput;
  passwordInput;
  toastCont;

  constructor(props) {
    super(props);

    this.state = initialComponentState;
    this.debouncedSchoolSearch = debounce(800, this.doSchoolSearch);

    this.theme = constants.themeProvider.activeTheme;
    this.logo = this.theme.logo;
    this.hero = this.theme.hero;
  }
  componentDidMount() {
    // schoolID is retrieved first from the Theme, then from the Query params.  if none then leave it blank and show the select
    const schoolIDFromTheme = this.theme.schoolID;
    const schoolID = schoolIDFromTheme || this.props.schoolID || '';
    this.setState(
      {
        schoolID,
        shouldHideSchoolSearch: !!schoolID
      },
      () => {
        // validate all the form fields but do not show errors
        this.validateAllFields(false);
      }
    );

    if (this.props.changeSchoolID) {
      this.handleChangeSchool();
    }
    this.shouldRedirectToBookBag();

    // if we are debugging, then pre-fill the login form with test bam
    if (config.Debug) {
      this.setState(
        {
          loginID: '87654',
          password: 'test',
          schoolID: '16fca1c8-d8fb-4452-aa77-99e1232b3e8d',
          schools: [{ Name: 'type at least 3 characters' }],
          shouldHideSchoolSearch: false,
          formValid: true
        },
        () => {
          this.validateAllFields(false);
        }
      );
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.schoolID !== prevProps.schoolID) {
      this.setState(
        {
          schoolID: this.props.schoolID || '',
          shouldHideSchoolSearch: !!this.props.schoolID
        },
        () => {
          // validate all the form fields but do not show errors
          this.validateAllFields(false);
        }
      );
    }
    if (
      this.props.changeSchoolID !== prevProps.changeSchoolID &&
      this.props.changeSchoolID &&
      this.props.changeSchoolID.length
    ) {
      this.handleChangeSchool();
    }
  }

  /*
* shouldRedirectToBookBag
* if we have a valid user and are not actively changing the school id then redirect to the bookbag
*/
  shouldRedirectToBookBag = () => {
    if (
      this.props.user.ID.length > 0 &&
      !this.props.changeSchoolID &&
      (this.props.location.pathname.search('login') !== -1 ||
        this.props.location.pathname === '/')
    ) {
      hashHistory.push('bag');
    }
  };
  handleChangeSchool = () => {
    this.props
      .userLogout()
      .then(() => {
        sessionStorage.removeItem("navCategory");
        this.props.manualAjaxEnd();
        setTimeout(() => {
          hashHistory.push(
            `/login?schoolID=${this.props.changeSchoolID}`
          );
        }, 500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleChange = (e) => {
    // this.setState({ [e.target.name]: e.target.value });
    const name = e.target.name;
    const value = e.target.value;
    const fv = validateField(name, value, true, this.state.formValidations);
    const vf = validateForm(this.state.formValidations);

    this.setState({ [name]: value, formValidations: fv, formValid: vf });
  };

  validateAllFields = (showErrors) => {
    validateField(
      'loginID',
      this.state.loginID,
      showErrors,
      this.state.formValidations
    );
    validateField(
      'password',
      this.state.password,
      showErrors,
      this.state.formValidations
    );
    validateField(
      'schoolID',
      this.state.schoolID,
      showErrors,
      this.state.formValidations
    );
  };

  handleLoginSuccess = () => {
    if (this.props.redirect && this.props.redirect.length) {
      hashHistory.push(this.props.redirect);
    } else if (this.props.bookID && this.props.bookID.length) {
      hashHistory.push(`viewer?bookID=${this.props.bookID}`);
    } else {
      hashHistory.push('/bag');
    }
  };

  handleLoginFailed = (error) => {
    let message = `Unable to login`;

    if (navigator.onLine === false) {
      message = 'Please connect to the internet.'
    }
    if (error === undefined || (error && error.status === 404)) {
      message = `Sorry, we could not find a student account with that login information.`;
    }
    if (error && error.status === 417) {
      message = 'Ooops, we found your account but the password is incorrect.'
    }
    if (error && error.status === 411) {
      message = 'Ooops, we found your account, but you have selected the wrong school.  Please contact your teacher or school administrator.'
    }
    if (error && error.status === 406) {
      message = `Ooops, you are trying to login to the student portal with a teacher account.  Please navigate to the admin portal if you were trying to login there.`
    }
    if (error && error.status === 412) {
      message = `Ooops, we are having an issue logging in with Google.  Please try again or contact support.`
    }

    this.toastCont.error(message, `Error`, {
      closeButton: true,
      showAnimation: 'animated fadeInUp'
    });
  }

  handleSubmit = (e) => {
    if (e !== '') {
      e.preventDefault();
    }
    if (this.state.formValid === false) {
      this.toastCont.error(
        `Please check form errors above and try again.`,
        `Login Error`,
        {
          closeButton: true,
          showAnimation: 'animated fadeInUp'
        }
      );
      // validate and show errors
      this.validateAllFields(true);
      this.forceUpdate(); // needed because if the user clicks submit whithout touching some fields, then the inlide validation does not show as expected

      return;
    }

    this.props
      .userLogin(
        this.state.loginID,
        this.state.password,
        this.state.schoolID
      )
      .then((user) => {
        this.handleLoginSuccess();
      })
      .catch((error) => {
        console.error(`error logging in`, error);
        this.handleLoginFailed(error)
      });
  };

  goToRegister = () => {
    hashHistory.push('/register');
  };

  handleLoadOptions = (value, callback) => {
    this.debouncedSchoolSearch(value, callback);
  };

  doSchoolSearch = (value, callback) => {
    if (value.length >= 3) {
      this.props.manualAjaxStart();
      UserAPI.getSchools(value)
        .then((schools) => {
          // this.setState({ schools });
          const schoolOptions = convertToOptions(schools);
          this.props.manualAjaxEnd();
          callback(schoolOptions);
        })
        .catch((error) => {
          console.error(error);

          this.props.manualAjaxEnd();
          callback(null);
        });
    }
  };
  handleSchoolSelect = (selectedSchool) => {
    validateField(
      'schoolID',
      selectedSchool.value,
      true,
      this.state.formValidations
    );
    // set the menu to only the selected item
    const fv = validateField(
      'schoolID',
      selectedSchool.value,
      true,
      this.state.formValidations
    );
    const vf = validateForm(this.state.formValidations);

    this.setState({
      schoolID: selectedSchool.value,
      formValidations: fv,
      formValid: vf
    });
  };

  reqGoogleLogin = () => {
    this.props.manualAjaxStart();
  };

  responseGoogle = (resp) => {
    this.props.manualAjaxEnd();
    if (!!resp.error) {
      console.error('Error from Google Auth', resp);
    } else {
      this.handleTokenLogin(resp.tokenId);
    }
  };

  handleTokenLogin = (authorizationCode) => {
    this.props
      .googleTokenLogin(authorizationCode, this.state.schoolID)
      .then((user) => {
        if (user.Error) {
          // eslint-disable-next-line no-throw-literal
          throw { message: user.Error };
        }
        if (UserAPI.isStudent(user.roleID)) {
          hashHistory.push('/bag');
        } else {
          hashHistory.push('/bag');
        }
      })
      .catch((err) => {
        console.error('Google auth error', err);
        this.handleLoginFailed(err)
      });
  };

  render() {
    const userSchoolClassName = this.state.shouldHideSchoolSearch
      ? 'autocomplete-container d-none'
      : 'autocomplete-container';

    return (

      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
        }}
      >
        <ToastContainer
          toastMessageFactory={ToastMessageFactory}
          ref={(toast) => {
            this.toastCont = toast;
          }}
          className="toast-top-right"
        />
        {/* Image */}
        <div className="main-page"
        >
          <img
            src={this.hero}
            alt="Not Found"
            style={{
              width: "100%",
              height: " 100vh",
              objectFit: "cover",
            }}
          />
        </div>

        <div style={{ flex: 1 }}>
          <div
            className="mainContainer"
          >
            <img src={this.logo} alt="" style={{ minWidth: '122px', height: '50px' }} />
            {/* Title */}
            <div className="title"

            >
              <div
                style={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "40px",
                  lineHeight: "60px",
                  letterSpacing: "-0.03em",
                  color: "#333333",
                }}
              >
                Welcome!
              </div>
            </div>

            {/* Second Title */}
            <div className="title"
            >
              {/* Text */}
              <div
                style={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "25px",
                  lineHeight: "21px",
                  letterSpacing: "-0.03em",
                  color: "#808080",

                }}
              >
                Please sign in.
              </div>
            </div>

            {/* Inputs */}
            <form
              id="login-form"
              onSubmit={(e) => this.handleSubmit(e)}
              className="dibs-form"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "15px",
                  width: "480px",
                  flex: "none",
                  alignSelf: "stretch",
                  flexGrow: 0,
                }}
              >
                <div className=".school-container">
                  <div
                    className={`school-text ${this.state.shouldHideSchoolSearch ? 'd-none' : ''}`}
                    style={{ color: "#808080", fontWeight: 500 }}
                  >
                    Select your school
                  </div>
                  <FormGroup
                    controlId="userSchool"
                    bsSize="lg sm"
                    className={userSchoolClassName}
                    validationState={
                      this.state.formValidations.schoolID.validationState
                    }
                  >
                    {this.props.loading && (
                      <label
                        htmlFor="schools-autocomplete"
                        className="schools-autocomplete-text"
                      >
                        Loading... <i className="fa fa-refresh fa-spin fa-fw" />
                      </label>
                    )}
                    {
                      <AsyncSelect
                        loadOptions={this.handleLoadOptions}
                        placeholder="School"
                        defaultOptions={[
                          {
                            value: "option1",
                            label: "type at least three characters",
                          },
                        ]}
                        onChange={this.handleSchoolSelect}
                        classNamePrefix="school-select"
                        cacheOptions
                        // onInputChange={this.handleChange}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            // width: "60vw",
                            borderRadius: "8px",
                          }),
                        }}
                        className="custom-school-select"
                      />
                    }
                    <FormControl.Feedback />
                    <Form.Text>
                      {this.state.formValidations.schoolID.validationMessage}
                    </Form.Text>
                  </FormGroup>
                </div>

                <div className="field-container"

                >
                  <div className="school-text" style={{ fontWeight: 500, fontSize: '15px', lineHeight: ' 21px', color: '#808080' }}>
                    Email or Name
                  </div>
                  <FormGroup
                    controlId="userEmail"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.loginID.validationState
                    }
                    style={{ width: "480px" }}
                  >
                    <FormControl
                      type="text"
                      name="loginID"
                      placeholder="Username or email address"
                      value={this.state.loginID}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <Form.Text>
                      {this.state.formValidations.loginID.validationMessage}
                    </Form.Text>
                  </FormGroup>
                </div>

                <div className=".field-container">
                  {/* Username or email address */}
                  <div className="school-text" style={{ color: '#808080', fontSize: '15px', fontWeight: '500px' }}>
                    Password
                  </div>
                  <FormGroup
                    controlId="userPassword"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.password
                        .validationState
                    }
                    style={{ width: '480px' }}

                  >
                    <FormControl
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <Form.Text>
                      {
                        this.state.formValidations.password
                          .validationMessage
                      }
                    </Form.Text>
                  </FormGroup>
                </div>

                <div
                  style={{
                    color: "#808080",
                    textDecorationLine: "underline",
                    lineHeight: " 21px",
                    fontSize: "15px",
                  }}
                >
                  Forget Password?
                </div>
              </div>

              <div
                className="signin-button"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "480px",
                  marginTop: "34px",

                }}

              >
                <div style={{ flex: 1 }}>
                  <GoogleLogin
                    clientId={config.google.clientID}
                    buttonText=""
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    onRequest={this.reqGoogleLogin}
                    className="google-login"
                    disabled={this.props.loading}
                  />
                </div>
                <div className='login-btn' style={{ diplay: 1, width: '120px' }}>
                  <StyledButton
                    id="btn-login"
                    disabled={this.props.loading}
                    style={{
                      borderRadius: "30px",
                      backgroundColor: "#3DB8F5",
                      borderBottom: "1px",
                      width: "100%",
                      height: "40px",
                      padding: "0px",
                    }}
                    type="submit"
                  >
                    Login
                  </StyledButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    loading: state.ajaxCallsInProgress > 0,
    bookID:
      ownProps.location.query.bookID ||
      ownProps.location.query.bookid ||
      ownProps.location.query.BookID,
    schoolID:
      ownProps.location.query.SchoolID ||
      ownProps.location.query.schoolid ||
      ownProps.location.query.schoolID,
    changeSchoolID: ownProps.location.query.changeSchoolID,
    redirect: ownProps.location.query.redirect,
  };
};

export default connect(mapStateToProps, {
  userLogin,
  manualAjaxStart,
  manualAjaxEnd,
  googleTokenLogin,
  userLogout,
})(LoginForm);
