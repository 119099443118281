import React from "react";
import icon_archive from "../../images/Backback.svg";
import remove_book from "../../images/Backback-Remove.svg";
import icon_read_book from "../../images/Info.png";
import icon_audio from "../../images/Listen_icon.png";
import icon_download from "../../images/download_button.svg";

const ListBook = ({
  key,
  book,
  isStudent,
  shouldHide,
  downloadBook,
  addBook,
  removeBook,
  constants,
  toastr,
  displayBookReadingLevel,
  showReadingLevel,
  openBook,
}) => {
  return (
    <>
      <div
        key={key}
        onClick={(e) => {
          e.stopPropagation();
          openBook(book);
        }}
        className="book-list-item"
      >
        <div className="book-details">
          <div className="book-list-image">
            {book.OfficialImage ?
              (<img className="book-img" src={book.OfficialImage} alt="" />) :
              (<div className="book-placeholder"><p>{book.Title}</p></div>)
            }
          </div>
          <div
            className="book-list-details"
            style={{ marginBottom: !book.author && "50px" }}
          >
            <span className="book-list-title">{book.Title}</span>
            <p className="book-list-author">By: {book.author}</p>
            {showReadingLevel && (
              displayBookReadingLevel(book) 
            )}
          </div>
        </div>
        <div className="book-list-actions">
          {book.IsEPub && (
            <button type="button" className="gray-button" onClick={() => { }}>
              <span className="text">Listen</span>
              <img alt="" className="image" src={icon_audio} />
            </button>
          )}
          {book.IsStudentAdded && !this?.isDownloaded(book.ID) && (
            <button type="button"
              className="sky-blue-button"
              onClick={(e) => {
                e.stopPropagation();
                downloadBook(book).then(() => {
                  toastr.success(
                    "Succcess",
                    "Book downloaded for viewing offline.",
                    constants.toastrSuccessOptions,
                  );
                });
              }}
            >
              <img alt="" className="image" src={icon_download} />{" "}
              <span className="text">Download</span>
            </button>
          )}
          <button type="button"
            className="green-button"
            onClick={(e) => {
              e.stopPropagation();
              openBook(book);
            }}
          >
            <img alt="" className="image" src={icon_read_book} />{" "}
            <span className="text">Read</span>
          </button>

          {!shouldHide && isStudent && !book.IsStudentAdded && !book.isInBookBag && (
            <button type="button"
              className="purple-button"
              onClick={(e) => {
                e.stopPropagation();
                addBook(book);
              }}
            >
              <img alt="" className="image" src={icon_archive} />
            </button>
          )}

          {!shouldHide && isStudent && book.IsStudentAdded && book.isInBookBag && (
            <button
              type="button"
              className="top-right-button"
              onClick={(e) => {
                e.stopPropagation();
                removeBook(book.ID, book.ISBN);
              }}
            >
              <img className="icon-img" alt="" src={remove_book} />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ListBook;
