import * as React from "react";

import { forEach, groupBy, remove } from "lodash";

import PropTypes from "prop-types";
import constants from "../../constants/constants";
import { ListGroup, ListGroupItem } from "react-bootstrap";

import SlidingPanel from "react-sliding-side-panel";
import "./drawerpanel.css";
import "react-sliding-side-panel/lib/index.css";

class DrawerPanel extends React.Component {
  static defaultProps = {
    showNotesButton: true,
    showHighlightsButton: true,
  };
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: true,
      activeTab: 1,
    };

    this.shorten = this.shorten.bind(this);
    this.getNotesHtml = this.getNotesHtml.bind(this);
    this.getHLHtml = this.getHLHtml.bind(this);
    this.parseSerializedHighlight = this.parseSerializedHighlight.bind(this);
    this.theme = constants.themeProvider.activeTheme;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.book.notes !== prevProps.book.notes ||
      this.props.book.highlights !== prevProps.book.highlights
    ) {
      this.forceUpdate();
    }
  }

  shorten(text) {
    if (!text) {
      return "";
    }
    let ret = text;
    let maxLength = 95;
    if (ret.length > maxLength) {
      ret = ret.substr(0, maxLength - 3) + "...";
    }
    return ret;
  }

  getNotesHtml(bookItems) {
    bookItems = bookItems.filter((bookItem) => bookItem.Content.value !== "");
    let borderForNote = "drawer-item";
    // group the book items by their Page attribute
    const bis = groupBy(bookItems, "Page");
    // build jsx array to return for print out
    let buffer = [];
    forEach(bis, (biArr, key) => {
      buffer.push(
        <p className="localParagraph" key={key}>
          Page {key}
        </p>,
      );
      buffer = buffer.concat(
        biArr.map((bi) => {
          if (bi.Type === 4) {
            borderForNote = "drawer-item teacher-student-note";
            // popoverNote = 'teacher-student-popover-bookItems';
          } else if (bi.Type === 5) {
            borderForNote = "drawer-item class-note";
            // popoverNote = 'teacher-class-popover-bis';
          } else if (bi.Type === 6) {
            borderForNote = "drawer-item group-note";
            // popoverNote = 'teacher-group-popover-bis';
          } else if (bi.Type === 3) {
            borderForNote = "drawer-item student-note";
            // popoverNote = 'student-popover-note';
          }
          return (
            <ListGroupItem
              className={borderForNote}
              key={bi.ID}
              onClick={() => {
                this.props.goTo(bi);
              }}
            >
              <p className="drawer-item-text">
                {this.shorten(bi.Content.value)}
              </p>
            </ListGroupItem>
          );
        }),
      );
    });
    return buffer;
  }

  getHLHtml(bookItem) {
    var bookItems = this.parseSerializedHighlight(bookItem.Content);
    // group the hl items by their page attribute
    const bis = groupBy(bookItems, "page");
    // build jsx array to return for print out
    let buffer = [];
    forEach(bis, (biArr, key) => {
      buffer.push(
        <p className="localParagraph" key={key}>
          Page {key}
        </p>,
      );
      buffer = buffer.concat(
        biArr.map((bi) => {
          return (
            <ListGroupItem
              className={`drawer-item border p-2 d-flex  ${bi.hlClass}`}
              key={bi.ID}
              onClick={() => {
                this.props.goToPage(bi.Page);
              }}
            >
              <p className="drawer-item-text">{this.shorten(bi.hlText)}</p>
            </ListGroupItem>
          );
        }),
      );
    });
    return buffer;
  }

  parseSerializedHighlight(serializedPages) {
    /*
      type:TextRange|165$192$3$hl-strikethrough$page6$t cover by First Avenue Edi
    */
    let hls = [];
    forEach(serializedPages, (serialized) => {
      var serializedHighlights = serialized.split("|");
      // remove the first node: type:TextRange
      serializedHighlights.shift();
      // loop over the rest and extraxt the page, style and text
      hls = hls.concat(
        serializedHighlights.map((sh) => {
          const parts = sh.split("$");
          if (parts.length < 6) {
            return null;
          }
          if (parts[5] === "") return null;
          var id = (Math.random() * 10000 + "").replace(".", "");
          var page = parseInt(parts[4].substr(4), 10);
          var hlClass = parts[3];
          var hlText = parts[5];
          return { id, page, hlClass, hlText };
        }),
      );
    });
    return remove(hls, null);
  }

  render() {
    const { activeTab } = this.state;
    return (
      <SlidingPanel
        type="left"
        isOpen={this.props.openPanel}
        backdropClicked={this.props.close}
        size={43}
        panelClassName="additional-class"
        panelContainerClassName="sliding-container"
      >
        <div className="panel-container">
          <button className="close-button" onClick={this.props.close}>
            &times;
          </button>
        </div>

        <div className="model-container">
          <div
            className={`modelHeading ${activeTab === 1 ? "active" : ""}`}
            onClick={() => this.setState({ activeTab: 1 })}
          >
            Notes
          </div>

          <div
            className={`modelHeading ${activeTab === 2 ? "active" : ""}`}
            onClick={() => this.setState({ activeTab: 2 })}
          >
            Markups
          </div>
        </div>

        <div
          className="content-container"
          style={{ height: "78%", overflowY: "scroll" }}
        >
          {activeTab === 1 && (
            <ListGroup className="list" style={{ flex: "1 1 auto" }}>
              {this.getNotesHtml(this.props.book.notes)}
            </ListGroup>
          )}
          {activeTab === 2 && this.props.book && (
            <ListGroup className="list" style={{ flex: "1 1 auto" }}>
              {this.getHLHtml(this.props.book.highlights)}
            </ListGroup>
          )}
        </div>
      </SlidingPanel>
    );
  }
}

DrawerPanel.propTypes = {
  openPanel: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default DrawerPanel;
