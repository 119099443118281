/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import "./BookSlider.scss";
import icon_right_arrow from "../../images/icon_arrow.svg";
import icon_left_arrow from "../../images/arrow-left.svg";
import icon_archive from "../../images/Backback.svg";
import remove_book from "../../images/Backback-Remove.svg";
import icon_read_book from "../../images/Info.png";
import icon_audio from "../../images/Listen_icon.png";
import SectionImage from "../../images/section_image.svg";
import ContinueReading from "../../images/continue-reading.svg";

const BookSlider = ({
  heading,
  books,
  isStudent,
  shouldHide,
  count,
  openBook,
  bookInfo,
  addBook,
  removeBook,
  handleCategoryClick,
  displayBookReadingLevel,
  isSectionBooks,
  sectionImage,
  categories,
  showReadingLevel,
  setIsContinueReadingBooks,
  handleSearchMode,
}) => {
  const [carouselOptions, setCarouselOptions] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [sectionBooks, setSectionBooks] = useState(books);
  const [leftButton, setLeftButton] = useState(false);
  const carousel = useRef(null);
  const getItemsCount = (width) => {
    if (width <= 1115) {
      return 5;
    } else if (width > 1115 && width <= 1260) {
      return 6;
    } else if (width > 1260 && width <= 1440) {
      return 7;
    } else if (width > 1440 && width <= 1700) {
      return 8;
    } else {
      return 9;
    }
  };
  const ITEMS = getItemsCount(window.innerWidth);

  useEffect(() => {
    setCarouselOptions({
      items: ITEMS,
      nav: true,
      navText: [null, null],
      margin: 16,
      autoHeight: true,
      navContainerClass: "custom-nav",
    });
  }, [sectionBooks, ITEMS]);

  useEffect(() => {
    setSectionBooks(books);
  }, [books]);

  const goToNextPage = () => {
    if (carousel.current.currentPosition >= 0) {
      setLeftButton(true);
    }
    if (
      isSectionBooks &&
      carousel.current.currentPosition === sectionBooks.length - ITEMS + 2
    ) {
      const page = pageNumber + 1;

      setPageNumber(page);
    } else {
      carousel.current.next(1);
    }
  };

  const goToPreviousPage = () => {
    if (carousel.current.currentPosition === 0) {
      setLeftButton(false);
    }
    carousel.current.prev(1);
  };

  const fetchCategory = (id) => {
    return categories.find((category) => category.ID === id);
  };

  const handleMoreTiles = () => {
    if (heading === "Book Bag") {
      handleSearchMode("local");

      return;
    }

    if (heading === "Continue Reading") {
      setIsContinueReadingBooks(true);
    } else {
      handleCategoryClick(fetchCategory(sectionBooks[0].section.ID));
    }
  };

  return (
    <div className="slider">
      <div className="slider-header">
        <div className="d-flex align-items-center justify-content-around">
          {sectionImage ? (
            <img className="section-image" src={sectionImage} alt="Category" />
          ) : heading === "Continue Reading" ? (
          <img src={ContinueReading} alt="Default" />
          ) : (
          <img src={SectionImage} alt="Default" />
              )}
          <button
            type="button"
            className="category-btn"
            onClick={() => handleCategoryClick(fetchCategory(sectionBooks[0].section.ID))}
          >
            <p className="query slider-heading">{heading}</p>
          </button>
          <p className="count slider-heading">{count}</p>
        </div>

        {sectionBooks.length >= ITEMS - 1 && (
          <div className="slider-custom-nav">
            {leftButton && (
              <img
                alt=""
                className="owl-prev-left"
                src={icon_left_arrow}
                onClick={goToPreviousPage}
              />
            )}
            <img
              alt=""
              className="owl-prev-right"
              src={icon_right_arrow}
              onClick={goToNextPage}
            />
          </div>
        )}
      </div>
      <div className="book-slider">
        <OwlCarousel options={carouselOptions} ref={carousel}>
          {sectionBooks.map((book, index) => {
            return (
              <div
                key={`${book.ID}-${index}`}
                onClick={(e) => {
                  e.stopPropagation();
                  openBook(book);
                }}
                className="sliderBook"
                data-audio={book.IsAudioAvailable}
              >
                {book.OfficialImage ?
                  (<img className="book-img" src={book.OfficialImage} alt="" />) :
                  (<div className="book-placeholder"><p>{book.Title}</p></div>)
                }
                {showReadingLevel && (
                  <div className="reading-level">
                    {displayBookReadingLevel(book, true)}
                  </div>
                )}
                <button
                  type="button"
                  className="bottom-right-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    bookInfo(book);
                  }}
                >
                  <div className="icon-wrap">
                    <img className="icon-img" alt="" src={icon_read_book} />
                  </div>
                </button>

                {book.IsEPub && (
                  <button
                    type="button"
                    className="bottom-left-button"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div className="icon-wrap">
                      <img className="icon-img" alt="" src={icon_audio} />
                    </div>
                  </button>
                )}

                {!shouldHide && isStudent && !book.IsStudentAdded && (
                  <button
                    type="button"
                    className="top-right-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      addBook(book);
                    }}
                  >
                    <img className="icon-img" alt="" src={icon_archive} />
                  </button>
                )}

                {!shouldHide && isStudent && book.IsStudentAdded && (
                  <button
                    type="button"
                    className="top-right-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeBook(book.ID, book.ISBN);
                    }}
                  >
                    <img className="icon-img" alt="" src={remove_book} />
                  </button>
                )}
              </div>
            );
          })}
          {count - ITEMS + 1 > 0 &&
            <div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  width: "176px",
                  backgroundColor: "white",
                  height: "224px",
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                  textAlign: "center",
                }}
                className="view-more"
              >
                <h1
                  style={{
                    fontWeight: "300",
                    fontSize: "40px",
                    color: "#808080",
                  }}
                >
                  {count - ITEMS + 1}
                </h1>
                <h1
                  style={{
                    fontWeight: "300",
                    fontSize: "18px",
                    color: "#808080",
                  }}
                >
                  More Titles
                </h1>
                <button
                  type="button"
                  style={{
                    width: "122px",
                    backgroundColor: "#3DB8F5",
                    borderRadius: "500px",
                    color: "white",
                    border: "none",
                    background: "#3DB8F5",
                    backgroundBlendMode: "multiply",
                    backgroundImage:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(204, 204, 204, 0.5) 100%)",
                    fontFamily: "Poppins",
                    fontWeight: "600px",
                    fontSize: "14px",
                  }}
                  onClick={() => handleMoreTiles()}
                >
                  VIEW ALL
                </button>
              </div>
            </div>
          }
          <div className="left-empty-book"></div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default BookSlider;
