/*
 * Pages component is responsible for containing the book and/or project pages.
 * it is also responsible for gestures and changing pages
 * It centers the pages currently only horrizontally ( in the future we would like it to center vertically as well) It positions the left and right arrow buttons.
 * it does this by adding up the width and height of the elements it contains, then centers itself
 * try simply adding up the width of the elements inside and then setting the pages container width.
 * calculate the width of each page by multiplying their bounds by the scalePercent of that page.
 */

import * as React from "react";

import BLM from "../blm/BLM";
import BookPage from "./BookPage";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import UserAPI from "../../api/userAPI";
import constants from "../../constants/constants";
import icon_arrow from "../../images/icon_arrow.svg";
import icon_cross from "../../images/cross.svg";
import { viewerModes } from "../../constants/viewerModes";
import { isEmpty, map } from "lodash";
import "./Pages.scss";
import { getBookScaling } from "../../utilities/getBookScaling";
import { roundTo } from "../../utilities/roundTo";

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transX: "",
      transY: "",
      scaleX: "",
      scaleY: "",
      pagesContainerWidth: 0,
      pagesContainerHeight: 0,
      leftMarginForArrow: 0,
      dragging: false,
      viewportWidth: 0,
      viewportHeight: 0,
      loaded: false,
      zoomed: false,
      bookType: "standard",
      page: {
        width: this.props.bookDimensions.width || this.props.book.cachedPages.page1?.width,
        height: this.props.bookDimensions.height || this.props.book.cachedPages.page1?.height,
      }
    };
    this.handleSwipe = this.handleSwipe.bind(this);
    this.resizePagesContainer = this.resizePagesContainer.bind(this);
    this.pagesOnMouseDown = this.pagesOnMouseDown.bind(this);
    this.pagesOnMouseMove = this.pagesOnMouseMove.bind(this);
    this.pagesOnMouseUp = this.pagesOnMouseUp.bind(this);
    this.shouldShowProject = this.shouldShowProject.bind(this);
    this.viewportRef = React.createRef();
    this.pagesContainerRef = React.createRef();
    this.observer = null;
    this.lastClientX = 0;
    this.lastClientY = 0;
    this.theme = constants.themeProvider.activeTheme;
  }

  componentDidMount() {
    this.observer = new ResizeObserver(this.handleResize);
    this.observer.observe(this.viewportRef.current);
    this.setState({
      page: {
        width: this.props.bookDimensions.width || this.props.book.cachedPages.page1?.width,
        height: this.props.bookDimensions.height || this.props.book.cachedPages.page1?.height,
      }
    });

    // window.addEventListener('resize', this.resizePagesContainer);
    // WARNING: disabling this causing pages to loose dimensions on reloads
    this.resizePagesContainer();
  }

  componentWillUnmount() {
    this.setState({ loaded: false });
    this.observer.disconnect();
    // window.removeEventListener('resize', this.resizePagesContainer);
  }

  /*
   * Step 5 in loading pages container
   *
   */
  componentDidUpdate(prevProps) {
    let shouldResize = false;
    if (
      prevProps.leftPageContainerWidth !== this.props.leftPageContainerWidth ||
      prevProps.leftPageContainerHeight !== this.props.leftPageContainerHeight
    ) {
      shouldResize = true;
    }
    if (prevProps.pagesVisible !== this.props.pagesVisible) {
      shouldResize = true;
    }
    // we must check for BLM mode changes here because the size and scale do not change when closing a BLM on a wide screen
    if (
      prevProps.blm.Title !== this.props.blm.Title ||
      prevProps.blmMode !== this.props.blmMode
    ) {
      shouldResize = true;
    }

    // when the project is the right page, we need to resize after it has
    if (prevProps.projectScalePercent !== this.props.projectScalePercent) {
      shouldResize = true;
    }
    if (prevProps.book.bookIsReady !== this.props.book.bookIsReady) {
      shouldResize = true;
    }
    if (prevProps.blm.projectIsReady !== this.props.blm.projectIsReady) {
      shouldResize = true;
    }
    if (shouldResize) {
      this.resizePagesContainer();
    }

    if (!this.state.loaded && this.props.bookLoaded) {
      this.setState({ loaded: this.props.bookLoaded });
    }

    if (prevProps.bookDimensions !== this.props.bookDimensions) {
      this.setState({
        page: {
          width: this.props.bookDimensions.width || this.props.book.cachedPages.page1?.width,
          height: this.props.bookDimensions.height || this.props.book.cachedPages.page1?.height,
        }
      });
    }

    if (prevProps.bookInitialPage !== this.props.bookInitialPage) {
      console.info('BOOK CHANGED', this.props.bookInitialPage);
    }
  }

  handleResize = (entries) => {
    const { width, height } = entries[0].contentRect;

    this.setState({
      viewportWidth: width,
      viewportHeight: height,
    });
  };

  /*
   * resize the pages container
   *
   */
  resizePagesContainer() {
    // console.log('resizing pages container')
    // const maxContentHeight = window.innerHeight - 120; // height of window - toolbar and top toggle
    let leftW = 0;
    let leftH = 0;
    // let rightW = this.props.book.bounds[rIndex][0];
    // let rightH = this.props.book.bounds[rIndex][1];
    let rightW = 0;
    let rightH = 0;
    const pagesVisible = this.props.pagesVisible;
    // TODO: might rename bookScalePercent to leftPageScalePercent. We are Assuming that the pages of the book are all the same size.
    let leftScalePercent = this.props.bookScalePercent;
    let rightScalePercent = 0;
    let totalWidthOfPages = 0;
    let tallestPageHeight = 0;
    let widthOfArrow = 50;
    let widthOfProjectToolbar = 50;
    let leftMarginForArrow = 0;
    let gapBetweenPages = 5;

    // if blmMode then use the bounds of the BLM instead.
    if (this.props.blmMode && this.props.blm.projectIsReady) {
      if (pagesVisible === 1) {
        leftScalePercent = this.props.projectScalePercent;
        leftW = this.props.blm.bounds[0][0] * leftScalePercent;
        leftH = this.props.blm.bounds[0][1] * leftScalePercent;
        totalWidthOfPages = leftW + widthOfProjectToolbar;
      } else {
        leftW = this.props.leftPageContainerWidth;
        leftH = this.props.leftPageContainerHeight;
        // TODO before determining the rightScalePercent we need to determin if we are limiting by height or width.
        // maybe we calculate both the scalePercent for height and width - then use the greater scaling (resulting in smaller page size)
        rightScalePercent = this.props.projectScalePercent;
        // rightScalePercentWidth = maxContentWidth / rightW;
        rightW = this.props.blm.bounds[0][0] * rightScalePercent;
        rightH = this.props.blm.bounds[0][1] * rightScalePercent;
        totalWidthOfPages = leftW + rightW + widthOfArrow + gapBetweenPages;
        leftMarginForArrow = leftW;
        // if the Project is wider than it is tall, switch to single page view automatically
        if (leftW > leftH) {
          this.props.automaticUpdatePagesVisible(1);
        }
      }
    } else if (this.props.book.bookIsReady) {
      if (pagesVisible === 1) {
        leftW = this.props.leftPageContainerWidth;
        leftH = this.props.leftPageContainerHeight;
        totalWidthOfPages = leftW;
        leftMarginForArrow = leftW;
      } else {
        // set both left and right to the leftPageContainer because it is easier and we can assume that both pages of the book are the same size.
        leftW = this.props.leftPageContainerWidth;
        leftH = this.props.leftPageContainerHeight;
        rightW = this.props.leftPageContainerWidth;
        rightH = this.props.leftPageContainerHeight;
        totalWidthOfPages = leftW + rightW + gapBetweenPages;
        leftMarginForArrow = leftW + rightW + gapBetweenPages;
        // if the book page is wider than it is tall, switch to single page view automatically
        // if (leftW > leftH){
        //   this.props.automaticUpdatePagesVisible(1);
        // }
      }
    }

    tallestPageHeight = leftH > rightH ? leftH : rightH;

    this.setState({
      pagesContainerHeight: tallestPageHeight,
      pagesContainerWidth: totalWidthOfPages,
      leftMarginForArrow,
    });
  }

  handleSwipe(direction) {
    switch (direction) {
      case "top":
        break;
      case "bottom":
        break;
      case "left":
        this.props.nextPage();
        break;
      case "right":
        this.props.prevPage();
        break;
      default:
        break;
    }
  }

  getBookPage(page, pageID, dimensions, pending) {
    const { index, scale, type } = getBookScaling({ width: this.state.page.width, height: this.state.page.height });
    const pageScalePercent = roundTo((this.state.viewportHeight / 100) * scale, 3);
    console.info({ pageScalePercent, scale, type, index, width: this.state.page.width, height: this.state.page.height });
    if (pending && !this.state.zoomed) {
      this.props.setBookZoom(index, pageScalePercent);
      this.setState({ bookType: type, zoomed: true });

      if (type === 'wide') {
        this.props.updatePagesVisible(1);
      }
    }

    return (
      <BookPage
        {...this.props}
        pageID={pageID}
        page={page}
        pagesContainerHeight={this.state.pagesContainerHeight}
        pagesContainerWidth={this.state.pagesContainerWidth}
        key={pageID}
        pagesOnMouseDown={this.pagesOnMouseDown}
        pagesOnMouseMove={this.pagesOnMouseMove}
        pagesOnMouseUp={this.pagesOnMouseUp}
        dimensions={dimensions}
        scaleType={type}
        scaleIndex={index}
      />
    );
  }

  /*
   * do Not show the book if we are in BLMMode and only 1 page visible
   * wait to show the book until prop.pages is defined
   */
  shouldShowBook() {
    let showBook = false;

    switch (true) {
      case !isEmpty(this.props.book.cachedPages) && this.props.book.bookIsReady:
        showBook = true;
        break;
      case !this.observer:
        showBook = false;
        break;
      case this.state.viewportWidth === 0:
        showBook = false;
        break;
      case this.state.pagesContainerWidth === 0:
        showBook = false;
        break;
      case !this.props.book.bookIsReady:
        showBook = false;
        break;
      case this.props.blmMode && this.props.pagesVisible === 1:
        showBook = false;
        break;
      default:
        showBook = false;
        break;
    }

    return showBook;
  }

  shouldShowProject() {
    let showProject = false;
    if (
      this.props.blmMode &&
      this.props.blm.bounds &&
      this.state.pagesContainerWidth &&
      this.props.blm.projectIsReady
    ) {
      showProject = true;
    }
    return showProject;
  }

  /*
   * shouldShowCLoseButton
   */
  shouldShowCloseButton = () => {
    const { RoleID } = this.props.user;
    if (
      // is a student
      UserAPI.isStudent(RoleID) === true ||
      // is generic and not resource mode
      (UserAPI.isGeneric(RoleID) === true &&
        this.props.viewerMode !== viewerModes.MODE_RESOURCE) ||
      // is demo
      UserAPI.isDemo(RoleID) === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  /*
   * respond to mouse down
   * if we are not already dragging and the pointer tool is active, then drag scroll
   */
  pagesOnMouseDown(e, pageNumber) {
    e.persist();
    if (!this.state.dragging && this.props.bookToolbar.pointing) {
      this.setState({ dragging: true });
      this.lastClientX = e.clientX || e.touches[0].clientX;
      this.lastClientY = e.clientY || e.touches[0].clientY;
      const tempX = this.lastClientX;
      const tempY = this.lastClientY;

      // if they don't move much, then they are highlighting rather than dragging
      setTimeout(() => {
        const moveX = parseInt(tempX - this.lastClientX, 10);
        const moveY = parseInt(tempY - this.lastClientY, 10);
        if (moveX < 30 || moveY < 30) {
          this.setState({ dragging: false });
        }
      }, 200);
    }
    this.props.onMouseDown(e, pageNumber);
  }

  pagesOnMouseMove(e) {
    e.persist();

    if (this.state.dragging && this.pagesContainerRef.current) {
      this.pagesContainerRef.current.scrollLeft -=
        -this.lastClientX + (this.lastClientX = e.clientX);
      this.pagesContainerRef.current.scrollTop -=
        -this.lastClientY + (this.lastClientY = e.clientY);
      this.mouseMove++;
    }

    this.props.onMouseMove(e);
  }

  pagesOnMouseUp(e, pageNumber) {
    if (this.state.dragging) {
      this.setState({ dragging: false });
    }

    e.persist();
    this.props.onMouseUp(e, pageNumber);
  }

  isBigEnough(type, level) {
    if (type === "tall") {
      return level >= 0.7;
    }

    return level >= 1;
  }

  render() {
    let bookReadyClass = this.shouldShowBook() ? "book-ready" : "";
    let classes = `pages ${this.props.bookToolbar.pagesClassName} ${bookReadyClass}`;
    let showBookArrows = "block";
    // TODO canvasClassForBLM is defined in Pages as well as BLM - pick one!
    let canvasClassForBlm, bookArrowLeftStyle, bookArrowRightStyle;
    if (this.props.pagesVisible === 1 && this.props.blmMode) {
      showBookArrows = "none";
      canvasClassForBlm = "page single-page blm-canvas-container";
    } else if (this.props.pagesVisible === 2 && this.props.blmMode) {
      canvasClassForBlm = "page blm-page blm-canvas-container";
    }
    if (this.props.blmMode) {
      classes += " blm-mode";
    }
    if (this.props.pagesVisible === 1) {
      classes += " one-visible";
    } else {
      classes += " two-visible";
    }
    bookArrowLeftStyle = { display: `${showBookArrows}` };
    bookArrowRightStyle = {
      display: `${showBookArrows}`,
      marginLeft: `calc(${this.state.leftMarginForArrow}px + var(--offset))`,
    };

    const pagesContainerStyle = {
      width: `${this.state.pagesContainerWidth}px`,
      height: `${this.state.pagesContainerHeight}px`,
      '--nav-size': `${this.state.pagesContainerHeight / 4}px`,
    };

    return (
      <div
        className="pages-wrap"
        style={{
          backgroundColor: "#f5f5f5",
        }}
        ref={this.viewportRef}
      >
        {this.shouldShowCloseButton() && (
          <Button
            className="close-book"
            style={{}}
            onClick={() => this.props.closeBookView()}
            role="button"
            type="button"
          >
            <img alt="" src={icon_cross} />
          </Button>
        )}
        <div
          className="pages-container"
          data-dragging={this.state.dragging}
          data-big={this.isBigEnough(this.state.bookType, this.props.zoomLevel)}
          data-type={this.state.bookType}
          ref={this.pagesContainerRef}
        >
          <div
            id="pages"
            className={classes}
            style={pagesContainerStyle}
            ref={this.props.forwardedRef}
            data-type={this.state.bookType}
          >
            <div className="pageIndex prev" style={bookArrowLeftStyle}>
              <Button
                variant="default"
                role="button"
                onClick={this.props.prevPage}
              >
                <img alt="" src={icon_arrow} />
              </Button>
            </div>
            <div className="pageIndex next" style={bookArrowRightStyle}>
              <Button
                variant="default"
                role="button"
                onClick={this.props.nextPage}
              >
                <img alt="" src={icon_arrow} />
              </Button>
            </div>

            {this.shouldShowBook() &&
              map(this.props.book.cachedPages, (page, pageID) => this.getBookPage(page, pageID, {
                viewportWidth: this.state.viewportWidth,
                viewportHeight: this.state.viewportHeight,
              }, this.state.loaded))}

            {this.shouldShowProject() && (
              <BLM
                blmHtml={this.props.blmHtml}
                blm={this.props.blm}
                user={this.props.user}
                toastCont={this.props.toastCont}
                blmMode={this.props.blmMode}
                transX={this.state.transX}
                transY={this.state.transY}
                scaleY={this.state.scaleY}
                scaleX={this.state.scaleX}
                exitBlmMode={this.props.exitBlmMode}
                canvasClassForBlm={canvasClassForBlm}
                closeBookView={this.props.closeBookView}
                location={this.props.location}
                viewerSettings={this.props.viewerSettings}
                isOnline={this.props.isOnline}
                leftPageContainerWidth={this.props.leftPageContainerWidth}
                leftPageContainerHeight={this.props.leftPageContainerHeight}
                pagesContainerHeight={this.state.pagesContainerHeight}
                pagesContainerWidth={this.state.pagesContainerWidth}
                pagesVisible={this.props.pagesVisible}
                updateProjectScalePercent={this.props.updateProjectScalePercent}
                projectScalePercent={this.props.projectScalePercent}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  onMouseDown: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func.isRequired,
  onMouseMove: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
  pagesVisible: PropTypes.number.isRequired,
  blmMode: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  book: PropTypes.object.isRequired,
  bookDimensions: PropTypes.object.isRequired,
  highlighterRight: PropTypes.object,
  highlighterLeft: PropTypes.object,
  wordTapped: PropTypes.func.isRequired,
  location: PropTypes.object,
  updateLeftPageContainer: PropTypes.func.isRequired,
  leftPageContainerWidth: PropTypes.number.isRequired,
  leftPageContainerHeight: PropTypes.number.isRequired,
  bookScalePercent: PropTypes.number.isRequired,
  updateBookScalePercent: PropTypes.func.isRequired,
  updateProjectScalePercent: PropTypes.func.isRequired,
  projectScalePercent: PropTypes.number,
  automaticUpdatePagesVisible: PropTypes.func.isRequired,
  bookToolbar: PropTypes.object.isRequired,
  viewerMode: PropTypes.string.isRequired,
};

export default React.forwardRef((props, ref) => {
  return <Pages {...props} forwardedRef={ref} />;
});
