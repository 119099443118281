import { middleware as offlineQueueMiddleware } from 'redux-queue-offline';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import * as createMigration from 'redux-persist-migrate';
import initialState from '../reducers/initialState';
import {persistStore, autoRehydrate} from 'redux-persist';

import {
    saveBookItem,
    updateBookStatus,
    deleteBookItem
} from '../actions/bookActions';
import { saveBLMItem } from '../actions/blmActions';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import BookFS from '../api/bookFS';
import { migrations } from './migrations';

export const persistConfig = {
    // keyPrefix: 'dibs',
    debounce: 300,
    storage: BookFS.fs,
    blacklist: ['showEditProfileModal', 'ajaxCallsInProgress', 'toastr']
    // transforms: [createTransform(encode, decode)]
};
// see https://github.com/diegoddox/react-redux-toastr/issues/249 about blacklisting toastr
const migration = createMigration.default(migrations, 'appVersion');

const offlineActionCreators = {
    saveBookItem: saveBookItem,
    updateBookStatus: updateBookStatus,
    deleteBookItem: deleteBookItem,
    saveBLMItem: saveBLMItem
};


export const configureStore = (persistCallback) => {
    if (process.env.NODE_ENV !== 'production') {
        // if (false){ // comment in for testing localling without redux dev tools

        const store = createStore(
            rootReducer,
            initialState,
            composeWithDevTools(
                applyMiddleware(
                    offlineQueueMiddleware(
                        'offlineQueue',
                        ['payload.promise'],
                        offlineActionCreators
                    ),
                    thunk
                ),
                compose(autoRehydrate(), migration) // , require('redux-immutable-state-invariant').default()
            )
        );
        const persistor = persistStore(store, persistConfig, persistCallback);
        return {store, persistor};
    } else {
        const store = createStore(
            rootReducer,
            initialState,
            compose(applyMiddleware(
                offlineQueueMiddleware(
                    'offlineQueue',
                    ['payload.promise'],
                    offlineActionCreators
                ),
                thunk,
            ),
            autoRehydrate(), migration)
        );
        const persistor = persistStore(store, persistConfig, persistCallback);
        return {store, persistor};
    }
}