/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import "./Navbar.scss";
import Bookbag from "../../images/book_bag.png";
import BookbagBW from "../../images/book_bag-bw.png";
import Bookroom from "../../images/book_room.png";
import BookroomBW from "../../images/book_room-bw.png";
import Logout from "../../images/logout.svg";
import Arrow from "../../images/arrow-down.svg";
// import AudioOn from "../../images/Audio_On.svg";
// import AudioOff from "../../images/Audio_Off.svg";
import ClassCode from "../../images/Class_Code.svg";
import { A11y, Navigation } from "swiper";
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import ArrowIcon from "../../images/icon_arrow.svg";
import { BookBagSearchContainer } from "./BookBagSearchContainer";

const hexToRgb = (hex, alpha) => {
  // remove the hash symbol if present
  hex = hex.replace("#", "");

  // convert the hex value to RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // retun RGB values as a string
  if (alpha) {
    return `rgb(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
}

export const SwiperNavButtons = ({ showPrevArrow, showNextArrow }) => {
  const swiper = useSwiper();

  return (
    <div className="nav-div">
      {swiper?.allowSlidePrev && !!showPrevArrow && (
        <button
          className="pre-button nav-button"
          onClick={() => swiper.slidePrev()}
        >
          <img
            src={ArrowIcon}
            alt="arrow_icon"
            style={{ transform: "matrix(-1, 0, 0, 1, 0, 0)" }}
          />
        </button>
      )}
      {swiper?.allowSlideNext && !!showNextArrow && (
        <button
          className="next-button nav-button"
          onClick={() => swiper.slideNext()}
        >
          <img alt="arrow_icon" src={ArrowIcon} />
        </button>
      )}
    </div>
  );
};

const BigNavbar = (props) => {
  const [bbActive, setBBActive] = useState(false);
  const [brActive, setBRActive] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const noRow = props.hideNav || (props.hideBookBag && props.categories.length === 0);

  return (
    <div className="navbar navbar-expand-lg navbar-light bg-light big-nav" data-collapse={noRow}>
      <Row className="align-items-center justify-content-between px-2 pt-1">
        <Col>
          <ul
            className="navbar-nav collapse navbar-collapse d-flex justify-content-between"
          >
            <li className="nav-item d-flex">
              <button className="navbar-brand nav-logo" onClick={() => props.handleHomeClick()}>
                <img src={props.img} alt="logo_img" />
              </button>
            </li>
            <li>
              <BookBagSearchContainer
                query={props.query}
                theme={props.theme}
                isFiltersActive={props.isFiltersActive}
                handleInvalidSession={props.handleInvalidSession}
                isBig={true}
                handleBookSearchValue={props.handleBookSearchValue}
                handleReadingSearchValue={props.handleReadingSearchValue}
                searchMode={props.searchMode}
              />
            </li>
            <li className="nav-item d-flex">
              {/* TODO: Cannot mute audio in any solid way. Disabling for now. */}
              {/* <button type="button" id="audio-control" className="nav-button p-2" onClick={props.toggleMute}>
                {props.isMuted ? (
                  <img src={AudioOn} alt="audio_on_icon" />
                ) : (
                  <img src={AudioOff} alt="audio_on_icon" />
                )}
              </button> */}
              <button type="button" className="nav-button">
                <img src={ClassCode} alt="class_code_icon" />
              </button>
              <p
                className="nav-link nav-button username nav-text"
              >
                {props.user.First} {props.user.Last}
              </p>
              <button type="button" className="nav-link nav-button" onClick={props.signOut}>
                <img src={Logout} alt="logout_icon" />
              </button>
            </li>
          </ul>
        </Col>
      </Row>

      {!props.hideNav && (
        <div id="big-navbar-wrap" className="d-flex align-items-center justify-content-center">
          <ul
            id="big-navbar-nav"
            className={`d-flex align-items-center justify-content-center collapse ${!props.hideBookBag ? "navbar-nav" : ""}`}
            data-open={`${navOpen}`}
          >
            {!props.hideBookBag && (
              <div className="d-flex">
                <li
                  className="me-2 d-flex align-items-center justify-content-between mr-3"
                  style={{ width: "8rem" }}
                >
                  <button
                    className="nav-button"
                    onClick={() => props.handleSearchMode("local")}
                    onMouseEnter={() => setBBActive(true)}
                    onMouseLeave={() => setBBActive(false)}
                  >
                    {bbActive ? <img src={Bookbag} alt="book_bag_icon" class="nav-button-img" /> :
                      <img src={BookbagBW} alt="book_bag_icon" class="nav-button-img" />}
                  </button>

                  <span className="display-text nav-text">My Book Bag</span>
                </li>
                <li
                  className="me-2 d-flex align-items-center justify-content-between"
                  style={{ width: "7.4rem" }}
                >
                  <button
                    className="nav-button"
                    onClick={() => props.handleSearchMode("remote")}
                    onMouseEnter={() => setBRActive(true)}
                    onMouseLeave={() => setBRActive(false)}
                  >
                    {brActive ? <img src={Bookroom} alt="book_room_icon" class="nav-button-img" /> :
                      <img src={BookroomBW} alt="book_room_icon" class="nav-button-img" />}
                  </button>
                  <span className="display-text nav-text">Book Room</span>
                </li>
              </div>
            )}
            {props.categories.length > 0 && (
              <>
                <li className="swipper-li d-flex">
                  <Chip
                    className="navigation-element"
                    label="Home"
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(204, 204, 204, 0.5)) gray",
                      width: "5rem",
                    }}
                    clickable
                    onClick={() => props.handleHomeClick()}
                  />
                </li>
                {props.categories.filter(({ IsVisible }) => IsVisible).map((category) => (
                  <li className="swipper-li d-flex">
                    <Chip
                      key={category.ID}
                      className="navigation-element"
                      label={category.Name}
                      style={
                        !props.isHover
                          ? props.chipStyles(category)
                          : props.hoveredChipStyles(category)
                      }
                      onClick={() => props.handleCategoryClick(category)}
                      clickable
                      onMouseEnter={props.handleMouseEnter}
                      onMouseLeave={props.handleMouseLeave}
                    />
                  </li>
                ))}
              </>
            )}
          </ul>
          <Chip
            className="navigation-element navigation-toggle"
            data-active={`${navOpen}`}
            label="More"
            icon={<img src={Arrow} alt="arrow_icon" />}
            style={{
              background:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(204, 204, 204, 0.5)) gray",
            }}
            clickable
            onClick={() => setNavOpen(!navOpen)}
          />
        </div>
      )}
    </div>
  );
};

const SmallNavbar = (props) => {
  const [counter, setCounter] = useState({});

  return (
    <>
      <div className="navbar navbar-expand-lg navbar-light bg-light sticky small-nav">
        <ul className="navbar-nav collapse navbar-collapse justify-content-between">
          <li className="nav-item d-flex">
            <button className="navbar-brand nav-logo" onClick={() => props.handleHomeClick()}>
              <img src={props.img} alt="" />
            </button>
          </li>
          <div className="search-wrap d-flex overflow-hidden align-items-end">
            <li>
              <BookBagSearchContainer
                query={props.query}
                theme={props.theme}
                isFiltersActive={props.isFiltersActive}
                handleInvalidSession={props.handleInvalidSession}
                isBig={false}
                handleBookSearchValue={props.handleBookSearchValue}
                searchMode={props.searchMode}
              />
            </li>
            {!props.hideNav && props.categories.length > 0 ? (
              <li className="nav-item swipper-li d-flex">
                <Chip
                  className="navigation-element mr-2"
                  label="Home"
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(204, 204, 204, 0.5)) gray",
                    width: "5rem",
                  }}
                  clickable
                  onClick={() => props.handleHomeClick()}
                />
                <Swiper
                  allowTouchMove
                  draggable
                  onResize={() => {
                    setCounter({});
                  }}
                  modules={[Navigation, A11y]}
                  spaceBetween={10}
                  slidesPerView="auto"
                  setWrapperSize="true"
                  onReachBeginning={props.handleReachBeginning}
                  onReachEnd={props.handleReachEnd}
                  onRealIndexChange={(swiper) => {
                    props.handleIndexChange(swiper.realIndex);
                  }}
                  initialSlide={props.currentSlide}
                >
                  {props.categories.filter(({ IsVisible }) => IsVisible).map((category) => {
                    return (
                      <SwiperSlide className="res-slider" key={category.ID}>
                        <Chip
                          className="navigation-element"
                          label={category.Name}
                          style={
                            !props.isHover
                              ? props.chipStyles(category)
                              : props.hoveredChipStyles(category)
                          }
                          onClick={() => props.handleCategoryClick(category)}
                          clickable
                          onMouseEnter={props.handleMouseEnter}
                          onMouseLeave={props.handleMouseLeave}
                        />
                      </SwiperSlide>
                    );
                  })}
                  <SwiperNavButtons
                    counter={counter}
                    showPrevArrow={props.showPrevArrow}
                    showNextArrow={props.showNextArrow}
                  />
                </Swiper>
              </li>
            ) : null}
          </div>
        </ul>
      </div>
    </>
  );
};

export const Navbar = (props) => {
  const bigNavbarRef = useRef(null);
  const smallNavbarRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [reachedBeginning, setReachedBeginning] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [showBigNavbar, setShowBigNavbar] = useState(true);
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(true);
  const [isHover, setIsHover] = useState(false);

  const handleReachBeginning = () => {
    setShowPrevArrow(false);
    setShowNextArrow(true);
    setReachedBeginning(true);
  };

  const handleReachEnd = () => {
    setShowNextArrow(false);
    setShowPrevArrow(true);
    setReachedEnd(true);
  };

  const handleIndexChange = (slideIndex) => {
    setCurrentSlide(slideIndex);
    !showNextArrow && setShowNextArrow(true);
    !showPrevArrow && setShowPrevArrow(true);
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const fadeColor = (color, opacity) => {
    if (color === null) {
      return "";
    }

    return hexToRgb(color, opacity);
  };

  const chipStyles = (category) => {
    return {
      background: `${props.selectedCategory
        ? props.selectedCategory === category
          ? "linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(204, 204, 204, 0.5))" +
          (`#${category.SectionColor}` || "gray")
          : !!category.SectionColor
            ? fadeColor(category.SectionColor, 0.5)
            : "lightgray"
        : !!category.SectionColor
          ? `#${category.SectionColor}`
          : "gray"
        }`,
      border: props.selectedCategory === category ? "2px solid white" : "",
      boxShadow:
        props.selectedCategory === category
          ? "0px 0px 10px rgba(0, 0, 0, 0.12)"
          : "",
    };
  };

  const hoveredChipStyles = (category) => {
    return {
      backgroundColor: `${props.selectedCategory
        ? props.selectedCategory === category
          ? `#${category.SectionColor}` || "gray"
          : !!category.SectionColor
            ? fadeColor(category.SectionColor, 0.5)
            : "lightgray"
        : category.SectionColor
          ? `#${category.SectionColor}`
          : "gray"
        }`,
      border: props.selectedCategory === category ? "2px solid white" : "",
      boxShadow:
        props.selectedCategory === category
          ? "0px 0px 10px rgba(0, 0, 0, 0.12)"
          : "",
    };
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (!entry.isIntersecting) {
        setShowBigNavbar(false);
      } else {
        setShowBigNavbar(true);
      }
    });
    observer.observe(bigNavbarRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      {showBigNavbar ? (
        <nav ref={bigNavbarRef}>
          <BigNavbar
            user={props.user}
            img={props.img}
            categories={props.categories}
            signOut={props.signOut}
            userProfileModal={props.userProfileModal}
            query={props.query}
            theme={props.theme}
            isFiltersActive={props.isFiltersActive}
            handleInvalidSession={props.handleInvalidSession}
            badgeContent={props.bookBagCount}
            handleCategoryClick={props.handleCategoryClick}
            selectedCategory={props.selectedCategory}
            handleBookSearchValue={props.handleBookSearchValue}
            handleReadingSearchValue={props.handleReadingSearchValue}
            handleReachBeginning={() => handleReachBeginning()}
            handleReachEnd={() => handleReachEnd()}
            handleIndexChange={handleIndexChange}
            showPrevArrow={showPrevArrow}
            showNextArrow={showNextArrow}
            handleMouseEnter={() => handleMouseEnter()}
            handleMouseLeave={() => handleMouseLeave()}
            reachedBeginning={reachedBeginning}
            reachedEnd={reachedEnd}
            isHover={isHover}
            chipStyles={chipStyles}
            hoveredChipStyles={hoveredChipStyles}
            handleHomeClick={props.handleHomeClick}
            hideBookBag={props.hideBookBag}
            handleSearchMode={props.handleSearchMode}
            isMuted={props.isMuted}
            toggleMute={props.toggleMute}
            searchMode={props.searchMode}
            currentSlide={currentSlide}
            hideNav={props.hideNav}
          />
        </nav>
      ) : (
        <nav ref={smallNavbarRef}>
          <SmallNavbar
            img={props.img}
            categories={props.categories}
            query={props.query}
            theme={props.theme}
            isFiltersActive={props.isFiltersActive}
            handleInvalidSession={props.handleInvalidSession}
            handleCategoryClick={props.handleCategoryClick}
            selectedCategory={props.selectedCategory}
            handleBookSearchValue={props.handleBookSearchValue}
            handleReachBeginning={() => handleReachBeginning()}
            handleReachEnd={() => handleReachEnd()}
            handleIndexChange={handleIndexChange}
            showPrevArrow={showPrevArrow}
            showNextArrow={showNextArrow}
            handleMouseEnter={() => handleMouseEnter()}
            handleMouseLeave={() => handleMouseLeave()}
            reachedBeginning={reachedBeginning}
            reachedEnd={reachedEnd}
            isHover={isHover}
            chipStyles={chipStyles}
            hoveredChipStyles={hoveredChipStyles}
            handleHomeClick={props.handleHomeClick}
            searchMode={props.searchMode}
            currentSlide={currentSlide}
            hideNav={props.hideNav}
          />
        </nav>
      )}
    </div>
  );
};
