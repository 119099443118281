import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import './FilterDropdown.scss'
import _ from "lodash";

const FilterDropdown = ({
  books,
  handleBooksFilter,
  filteredBooks
}) => {
  const sortOptions = ["Newest", "Oldest", "A-Z", "Z-A"]

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionSelect = (option, index) => {
    setSelectedOption(option);

    switch (index){
      case 0:
        handleBooksFilter(_.orderBy(books, ["CreateTime"], ["desc"]))
        break;
      case 1:
        handleBooksFilter(_.orderBy(books, ["CreateTime"], ["asc"]))
        break;
      case 2:
        handleBooksFilter(_.orderBy(books, ["Title"], ["asc"]))
        break;
      case 3:
        handleBooksFilter(_.orderBy(books, ["Title"], ["desc"]))
        break;
      default:
        break;
    }
  };

  const handleRemoveOption = () => {
    setSelectedOption('');
    handleBooksFilter(filteredBooks);
  };

  useEffect(()=>{
    setSelectedOption('');
  }, [filteredBooks])

  return (
    <div className="dropdown-wrapper mr-3">
            <DropdownButton
              id="dropdown-basic"
              className="dropdown-btn"
              title={
                <span>
                  Sort By:{" "}
                  {selectedOption ? (
                    <span>
                      <span style={{ color: "black" }}>{selectedOption}</span>
                      <span
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        onClick={handleRemoveOption}
                      >
                        &#10006;
                      </span>
                    </span>
                  ) : (
                    <span style={{ color: "black" }}>Select an option</span>
                  )}
                </span>
              }
            >
              {sortOptions.map((option, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleOptionSelect(option, index)}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </DropdownButton>
      </div>
  )
}

export default FilterDropdown