import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { debounce } from "lodash";
import {
  addBook,
  searchBookBagBooks,
  initialDashboardQuery,
  removeBook,
  downloadBook,
  deleteDownloadedBook,
  normalizeBookObjects,
} from "../../actions/bookActions";
import { addQuery, removeQuery } from "../../vendor/utils-router";
import { beginAjaxCall, endAjaxCall, manualAjaxStart } from "../../actions/ajaxStatusActions";
import BookBagHeader from "./BookBagHeader";
import Loading from "../common/Loading";
import config from "../../api/config";
import { connect } from "react-redux";
import constants from "../../constants/constants";
import defaultPhoto from "../../images/default.jpg";
import downloadedImage from "../../images/downloaded.png";
import { forEach } from "lodash";
import { hashHistory } from "react-router";
import { requireSignIn } from "../../routes";
import { toastr } from "react-redux-toastr";
import { toggleUserProfileModal } from "../../actions/dashboard/dashboardActions";
import { toggleClassCodeModal, userLogout } from "../../actions/userActions";
import UserAPI from "../../api/userAPI";
import BookSlider from "./BookSlider";
import BooksGrid from "./BooksGrid";
import BookAPI from "../../api/bookAPI";
import Bookbag from "../../images/book_bag.png";
import { hubLevels, hubTitles, keyFoundByValue, roleIDs, studentReadingEventTypes } from "../../constants/enums";
import BookInfo from "../common/BookInfo";
import SideNav from "./SideNav";
import HubItem from "./HubItem";

class BookBag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevSelected: [],
      selectedTypes: [],
      selectedBookID: false,
      currentPage: 1,
      loading: false,
      booksData: null,
      bookBagIDs: [],
      emptySearchMessage:
        "Please enter your search criteria above and press 'Go'",
      listView: false,
      continueReadingBooks: [],
      selectedCategory: null,
      isSectionLessBooks: false,
      bookSearchValue: "",
      readingLevelSearchValue: "",
      categories: [],
      showReadingLevel: null,
      isContinueReadingBooks: false,
      bookBagCount: 0,
      hideBookBag: false,
      bookBagBooks: [],
      bookBagSelected: this.props.isStudent,
      searchMode: "",
      isStudent: this.props.isStudent,
      shouldHide: false,
      isBookbag: false,
      bookInfo: null,
      showInfoModal: false,
      hideNav: true,
      hubLevel: hubLevels.Hubs,
      hubTitle: null,
      hubSchoolID: null,
      hub: {
        Search: null,
        Hubs: null,
        HubsAndChildren: null,
        Children: null,
      },
      subHubs: [],
    };

    this.openingBook = false;
    this.theme = constants.themeProvider.activeTheme;
  }

  componentDidMount() {
    const listViewValue = localStorage.getItem("listView");
    const navCategoryValue = sessionStorage.getItem("navCategory");
    const hideNav = this.checkStorageValue(sessionStorage.getItem("hideNav"));
    const hubTitle = this.checkStorageValue(sessionStorage.getItem("hubTitle"));
    const hubLevel = this.checkStorageValue(sessionStorage.getItem("hubLevel"));
    const hub = this.checkStorageValue(sessionStorage.getItem("hub"));
    const hubSchoolID = this.checkStorageValue(sessionStorage.getItem("hubSchoolID"));
    const subHubs = this.checkStorageValue(sessionStorage.getItem("subHubs"));

    // remove the BLM ID and projectAssignmentID from query so that we do not stay in BLM mode when switching books
    removeQuery("blmID");
    removeQuery("projectAssignmentID");
    this.checkAddCode();
    this.checkCanAccessBookBag();

    this.setState({
      listView: listViewValue === "true",
      selectedCategory: navCategoryValue ? JSON.parse(navCategoryValue) : this.state.selectedCategory,
      hideNav: hideNav === "true" || this.state.hideNav,
      hubTitle: hubTitle || this.state.hubTitle,
      hubLevel: hubLevel ? parseInt(hubLevel, 10) : this.state.hubLevel,
      hub: hub ? JSON.parse(hub) : this.state.hub,
      hubSchoolID: hubSchoolID || this.state.hubSchoolID,
      subHubs: Boolean(subHubs) ? JSON.parse(subHubs) : this.state.subHubs,
    });
    this.fetchCategories(1);
    this.getContinueReadingBooks();
    localStorage.removeItem("bookSearchValue");
    localStorage.removeItem("readingSearchValue");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.books !== this.props.books) {
      // set the appropriate empty search message.  If this is the local bag and they have no search criteria, then they have no books in their bag
      let emptySearchMessage = "";
      if (this.props.books.length <= 0 && !this.filtersActive()) {
        emptySearchMessage = `You currently do not have any books in your book bag.`;
      }
      this.setState({ emptySearchMessage });
    }
    if (prevProps.query !== this.props.query) {
      this.setState({ prevSelected: [] });
    }

    if (
      prevProps.user?.School?.ShowReadingLevel !==
      this.state.showReadingLevel ||
      prevProps.user?.School?.HideBookbag !== this.state.hideBookBag
    ) {
      this.setState({
        showReadingLevel: this.props.user.School.ShowReadingLevel,
        hideBookBag: this.props.user.School.HideBookbag,
      });
    }

    if (prevProps.hubAndBooks !== this.props.hubAndBooks) {
      this.handleHubUpdate({
        ...this.state.hub,
        Search: this.props.hubAndBooks,
      });
      this.handleLevelClick(hubLevels.Search);
    }
  }

  checkStorageValue = (value) => {
    switch (true) {
      case value === '':
        return null;
      case value === 'null':
        return null;
      case value === 'undefined':
        return undefined;
      default:
        return value;
    }
  }

  fetchCategories = (pageNo) => {
    const { user } = this.props;
    const { SchoolID, School } = user;

    this.setState({
      loading: true,
      showReadingLevel: School?.ShowReadingLevel,
      hideBookBag: this.props.isGeneric || School?.HideBookbag,
    });

    const getBooks = (pageNo) => {
      switch (true) {
        case this.state.categories.length > 0:
          this.fetchBooks(pageNo);
          break;
        case this.props.isGeneric:
          this.fetchRemoteBooksWithoutSection(pageNo);
          break;
        default:
          this.fetchBooksWithoutSection(pageNo);
          break;
      }
    }

    BookAPI.getSectionList(pageNo, SchoolID, user)
      .then((data) => {
        if (data.length > 0) {
          this.setState(
            {
              categories: data,
              hideNav: false,
            },
            () => {
              getBooks(1);
            },
          );
        } else {
          this.fetchHubs(user);
        }
      })
      .catch((error) => {
        console.error("Error getting sections List:", error);
      }).finally(() => {
        this.setState({ loading: false });
      });
  };

  getContinueReadingBooks() {
    const keys = Object.keys(localStorage);
    const filteredBookIds = keys.filter((key) => key.startsWith("last-page-"));
    const continueReadingBookIds = filteredBookIds.map((id) =>
      id.substring(10),
    );
    this.setState({ loading: true });
    BookAPI.getBooksByIds(continueReadingBookIds, this.props.user)
      .then((data) => {
        this.setState({ continueReadingBooks: data });
      })
      .catch((error) => {
        console.error("Error getting books List:", error);
        this.setState({ loading: false });
      });
  }

  removeBook = async (bookID, bookIsbn) => {
    const { user } = this.props;

    if (this.state.isBookbag) {
      await BookAPI.removeBook(bookID, user);
      await this.fetchBooksWithoutSection(1);
    } else {
      this.props.removeBook(bookID, bookIsbn);
    }
  }

  fetchBooksWithoutSection = (pageNo) => {
    const { user } = this.props;
    const { SchoolID } = user;

    this.setState({ isSectionLessBooks: true });
    BookAPI.getBooksV2(user, SchoolID, pageNo)
      .then((data) => {
        const books = data.books.map((book) => ({ ...book, isInBookBag: true, IsStudentAdded: true }));

        this.setState({
          bookBagCount: data.count,
          booksData: { ...data, books },
          loading: false,
          bookBagIDs: [...this.state.bookBagIDs, ...data.books.map((book) => book.ID)],
          isBookbag: true,
        });
      })
      .catch((error) => {
        console.error("Error getting books:", error);
        this.setState({ loading: false });
      });
  };

  fetchRemoteBooksWithoutSection = (pageNo) => {
    const { user } = this.props;

    this.setState({ isSectionLessBooks: true });
    this.props.beginAjaxCall();

    BookAPI.searchBooks(pageNo, "", "", "", "", user, this.props.bookbagFilters)
      .then((response) => {
        if (response[1].length > 0) {
          const books = normalizeBookObjects(response[1], false);
          const filteredBooks = books.map((book) => ({
            ...book,
            isInBookBag: this.state.bookBagIDs.includes(book.ID),
          }));

          this.setState(
            (prevState) => {
              const updatedBooks = [...prevState.booksData.books, ...filteredBooks];
              return {
                bookBagCount: updatedBooks.length,
                booksData: {
                  ...prevState.booksData,
                  books: updatedBooks,
                  currentPage: pageNo,
                  pages: 1,
                  count: updatedBooks.length,
                },
              }
            },
            () => {
              this.forceUpdate();
            },
          );
        }
      })
      .catch((error) => {
        console.error("Error getting books:", error);
        this.setState({ loading: false });
      })
      .finally(() => {
        this.props.endAjaxCall();
      });
  };

  fetchBooks = (pageNo) => {
    const { user } = this.props;
    // INFO: HideBookbag bypasses the user role and determines if bookbag should be used or not.
    const shouldHide = user?.School?.HideBookbag;
    const isStudent = keyFoundByValue(roleIDs, user.RoleID.toUpperCase());

    if (isStudent) {
      BookAPI.getBooksV2(user, user?.SchoolID, pageNo)
        .then((data) => {
          this.setState({
            bookBagCount: data.count,
            bookBagBooks: data.books,
            isStudent: true,
            shouldHide,
          });
        })
        .catch((error) => {
          console.error("Error getting books:", error);
          this.setState({ loading: false });
        });
    }

    BookAPI.getBooksBySections(user, user?.SchoolID, pageNo)
      .then((data) => {
        this.setState({
          booksData: data,
          loading: false,
          bookBagSelected: false,
        });
      })
      .catch((error) => {
        console.error("Error getting books by sections:", error);
        this.setState({ loading: false });
      });
  };

  /*
   * only students, generic, and demo can access the bookbag.  otherwise log them out
   */
  checkCanAccessBookBag = () => {
    if (
      this.props.user.SchoolID &&
      (this.props.isStudent || this.props.isDemo || this.props.isGeneric) ===
      false
    ) {
      toastr.warning(
        "Warning",
        "User does not have access to the bookbag.  Logging out...",
        constants.toastrWarningOptions,
      );
      setTimeout(() => {
        this.props.userLogout();
        hashHistory.replace("/");
      }, 5000);
    }
  };

  checkAddCode = () => {
    console.log("checking addcode");
    if (this.props.addCode) {
      setTimeout(() => {
        this.props.toggleClassCodeModal(true);
      }, 1000);
    }
  };

  handleAddBook = (book) => {
    const isBookAvailable = this.state.bookBagBooks.some(function (obj) {
      return obj.ID === book.ID
    });

    if (!isBookAvailable) {
      this.setState((prevState) => ({
        bookBagBooks: [...prevState.bookBagBooks, book],
        bookBagCount: prevState.bookBagCount + 1,
      }));
    }

    this.props.addBook(book);
  };

  handleBookBagClick = () => {
    this.setState({
      bookBagSelected: this.state.bookBagSelected ? false : true,
    });
  };

  handleSearchMode = (value) => {
    if (value !== this.state.searchMode) {
      if (value === "local") {
        this.handleBookBagClick();
        this.fetchBooksWithoutSection(1);
        this.setState({
          bookBagSelected: true,
        });
      } else if (value === "remote") {
        this.fetchRemoteBooksWithoutSection(1);
        this.setState({
          bookBagSelected: false,
        });
      }

      this.setState({
        searchMode: value,
      });
    }
  };

  handleBookClick = (e, book) => {
    if (e) {
      e.preventDefault();
    }
    if (this.state.selectedBookID === book.ID) {
      this.openBook(book);
    } else {
      this.setState({ selectedBookID: book.ID });
    }
  };

  handleHomeClick = () => {
    sessionStorage.removeItem("navCategory");
    this.setState({
      selectedCategory: null,
      isContinueReadingBooks: false,
      isSearchingBooks: false,
      bookBagSelected: false,
      searchMode: "",
      bookSearchValue: "",
      readingLevelSearchValue: "",
    });
  };

  handleCategoryClick = (category) => {
    sessionStorage.setItem("navCategory", JSON.stringify(category));

    if (this.state.selectedCategory === category) {
      this.setState({
        selectedCategory: null,
        isContinueReadingBooks: false,
        isSearchingBooks: false,
        bookBagSelected: false,
        searchMode: "",
        bookSearchValue: "",
        readingLevelSearchValue: "",
      });
    } else {
      this.setState({
        selectedCategory: category,
        isContinueReadingBooks: false,
        isSearchingBooks: false,
        bookBagSelected: false,
        searchMode: "",
        bookSearchValue: "",
        readingLevelSearchValue: "",
      });
    }
  };

  handleBookSearchValue = (obj) => {
    const isHub = Object.values(this.state.hub).some(Boolean);

    if (obj.value) {
      this.setState(
        {
          isSearchingBooks: obj.value === "" && obj.grl === "" ? false : true,
          bookSearchValue: obj.value,
          readingLevelSearchValue: obj.grl,
        },
        () => {
          const query = {
            activePage: 1,
            grl: obj.grl,
            schoolID: this.props.user.SchoolID,
            search: obj.value,
            searchMode: isHub ? 'hubs' : obj.searchMode,
            tag: "allTags",
            type: "All",
            isStudent: this.props.isStudent,
          };

          this.props.searchBookBagBooks(true, query);
        },
      );
    } else {
      if (isHub) {
        this.fetchHubs(this.props.user);
      }
    }
  };

  openBook = async (book) => {
    const { user } = this.props;
    // prevent double click on open book
    // If we are offline:
    if (this.openingBook) return;

    try {
      this.openingBook = true;

      setTimeout(() => {
        this.openingBook = false;
      }, 300);

      await BookAPI.logEvent(book.ID, studentReadingEventTypes.BookOpen, user);
    } catch (error) {
      console.error("[openBook] logging event:", error);
    } finally {
      if (book.IsExternal) {
        // open the book in the Lerner viewer, not in the DiBS viewer
        const externalURL = `${config.API.Main}/book/openexternalviewer?bookID=${book.ID}`;
        var win = window.open(externalURL, "_blank");
        win.focus();
      } else {
        // addQuery({bookID: book.ID});
        const location = Object.assign({}, hashHistory.getCurrentLocation());
        Object.assign(location.query, { bookID: book.ID });
        hashHistory.push({
          pathname: `/viewer`,
          query: location.query,
        });
      }
    }
  };

  bookInfo = (book) => {
    this.fetchResources(book.ID);
    this.fetchTags(book.ID);
    this.setState({ bookInfo: book, showInfoModal: true });
  }

  fetchResources = (bookID) => {
    const { user } = this.props;

    BookAPI.getResources(user, bookID)
      .then((data) => {
        this.setState({
          resources: data,
        });
      })
      .catch((error) => {
        console.error("Error getting resources:", error);
      });
  };

  fetchTags = (bookID) => {
    const { user } = this.props;

    BookAPI.getTags(user, bookID)
      .then((data) => {
        this.setState({
          tags: data.map((tag) => tag.Tag),
        });
      })
      .catch((error) => {
        console.error("Error getting tags:", error);
      });
  };

  handleLevelClick = (level, sub, index) => {
    if (sub) {
      this.fetchHubsBooks(sub);
    } else {
      const hubTitle = level === hubLevels.Hubs ? null : level === hubLevels.Search ? hubTitles[hubLevels.Search] : this.state.hubTitle;

      sessionStorage.setItem("hubLevel", level);

      this.setState((prevState) => ({
        hubLevel: level,
        hubTitle,
        subHubs: level === hubLevels.Hubs ? [] : this.state.subHubs,
        hub: {
          ...prevState.hub,
          Search: level === hubLevels.Hubs ? null : prevState.hub.Search,
        },
      }));
    }
  };

  handleHubUpdate = (hub, hubTitle, hubSchoolID, subHubs) => {
    const title = hubTitle || this.state.hubTitle;
    const schoolID = hubSchoolID || this.state.hubSchoolID;
    const subs = subHubs || this.state.subHubs;

    sessionStorage.setItem("hubTitle", title);
    sessionStorage.setItem("hubSchoolID", schoolID);
    sessionStorage.setItem("subHubs", JSON.stringify(subs));
    sessionStorage.setItem("hub", JSON.stringify(hub));

    this.setState({
      hub,
      hubTitle: title,
      hubSchoolID: schoolID,
      subHubs: subs,
    });
  };


  handleSubHubsUpdate = (hubItem) => {
    const subs = [
      ...this.state.subHubs,
      {
        ID: hubItem.ID,
        ParentHubID: hubItem.ParentHubID,
        Name: hubItem.Name,
        IconUrl: hubItem.IconUrl
      }
    ];
    // remove duplicates by id and items after the current index
    const cleanSubs = subs.filter((sub, index, self) => self.findIndex((s) => s.ID === sub.ID) === index);
    const indexOfCurrent = cleanSubs.findIndex((sub) => sub.ID === hubItem.ID);
    const newSubs = cleanSubs.slice(0, indexOfCurrent + 1);
    const updatedSubs = newSubs || this.state.subHubs;

    sessionStorage.setItem("subHubs", JSON.stringify(updatedSubs));
    this.setState({
      subHubs: updatedSubs,
    });
  }

  fetchHubs = (user) => {
    this.props.beginAjaxCall();

    BookAPI.getHubs(user)
      .then((data) => {
        this.handleHubUpdate({
          ...this.state.hub,
          Hubs: data
        });
        sessionStorage.setItem("hideNav", true);
        if (this.hubLevel === hubLevels.Search) {
          this.handleLevelClick(hubLevels.Hubs);
        }
      })
      .catch((error) => {
        console.error("Error getting hubs:", error);
      })
      .finally(() => {
        this.props.endAjaxCall();
      });
  };

  fetchHubsBooks = (hubItem) => {
    const { user } = this.props;
    const hubTitle = hubItem.Name;

    this.props.beginAjaxCall();

    BookAPI.getHubsBooks(user, hubItem.ID)
      .then((data) => {
        const firstItem = data[0];
        const isHub = firstItem.hasOwnProperty('ParentHubID');

        this.handleSubHubsUpdate(hubItem);

        if (isHub) {
          this.handleHubUpdate({
            ...this.state.hub,
            HubsAndChildren: data,
          }, hubTitle, hubItem.SchoolID);
          this.handleLevelClick(hubLevels.HubsAndChildren);
        } else {
          this.handleHubUpdate({
            ...this.state.hub,
            Children: data
          }, hubTitle, hubItem.SchoolID);
          this.handleLevelClick(hubLevels.Children);
        }
      })
      .catch((error) => {
        console.error("Error getting hub books:", error);
      })
      .finally(() => {
        this.props.endAjaxCall();
      });
  };

  handleHubsBooks = (hubItem) => {
    const isHub = hubItem.hasOwnProperty('ParentHubID');

    if (isHub) {
      this.fetchHubsBooks(hubItem);
    } else {
      this.openBook(hubItem);
    }
  };

  handleSubmit = (e, activePage) => {
    if (e) {
      e.preventDefault();
    }
    console.log(`initiating a bookbag search: ${this.props.query.search}`);
    addQuery({ activePage });
    this.props.searchBookBagBooks(this.filtersActive(), {
      ...this.props.query,
      activePage,
    });
  };

  // are any book bag filters active? returns a boolean
  filtersActive = () => {
    if (
      this.props.query.search.length === 0 &&
      this.props.query.type.length === 0 &&
      this.props.query.tag.length === 0 &&
      this.props.query.grl.length === 0 &&
      this.props.bookbagFilters.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  setIsContinueReadingBooks = async (value) => {
    this.setState({ isContinueReadingBooks: value });
  };

  fetchMoreData = async (section) => {
    if (this.state.isContinueReadingBooks) return null;
    const { currentPage: currPage } = this.state;
    const { pageResults, user } = this.props;
    const { SchoolID } = user;

    let currentPage, totalPages, pageNo, sectionId;

    if (this.state.bookSearchValue || this.state.readingLevelSearchValue) {
      currentPage = currPage;
      totalPages = pageResults;
      pageNo = currentPage + 1;

      if (currentPage < totalPages) {
        currentPage = pageNo;

        const query = {
          activePage: pageNo,
          grl: this.state.readingLevelSearchValue || "",
          schoolID: this.props.user.SchoolID,
          search: this.state.bookSearchValue || "",
          tag: "allTags",
          type: "All",
          update: true,
        };

        this.setState({ currentPage: pageNo });
        this.props.searchBookBagBooks(true, query);
      }
    } else if (!this.state.isSectionLessBooks) {
      currentPage = this.state.booksData[section].currentPage;
      totalPages = this.state.booksData[section].pages;
      pageNo = currentPage + 1;
      sectionId = this.state.booksData[section].books[0].sectionId;

      if (currentPage < totalPages) {
        const nextBooks = await BookAPI.getBookByCategory(
          pageNo,
          SchoolID,
          sectionId,
          user,
        );

        if (nextBooks.length > 0) {
          this.setState(
            (prevState) => ({
              booksData: {
                ...prevState.booksData,
                [section]: {
                  ...prevState.booksData[section],
                  currentPage: pageNo,
                  books: [...prevState.booksData[section].books, ...nextBooks],
                },
              },
            }),
            () => {
              this.forceUpdate();
            },
          );
        }
      }
    } else {
      currentPage = this.state.booksData.currentPage;
      totalPages = this.state.booksData.pages;
      pageNo = currentPage + 1;
      sectionId = this.state.booksData.books[0].SectionID;

      if (currentPage < totalPages) {
        const nextBooks = await BookAPI.getBooksV2(user, SchoolID, pageNo);

        if (nextBooks.length > 0) {
          this.setState(
            (prevState) => ({
              booksData: {
                ...prevState.booksData,
                [section]: {
                  ...prevState.booksData[section],
                  currentPage: pageNo,
                  books: [...prevState.booksData[section].books, ...nextBooks],
                },
              },
            }),
            () => {
              this.forceUpdate();
            },
          );
        }
      } else {
        this.fetchRemoteBooksWithoutSection(pageNo)
      }
    }
  };

  handleInvalidSession = () => {
    toastr.error(
      `Please login again.`,
      `Session Expired`,
      constants.toastrErrorOptions,
    );
    setTimeout(() => {
      this.props.userLogout();
      hashHistory.replace("/");
    }, 3000);
  };

  displayBookReadingLevel = (book, removeClass) => {
    if (!this.state.showReadingLevel) return null;

    let grl, atos, lex;
    let hideBookReadingLevel = removeClass
      ? "reading-text"
      : "book-reading-level";
    if (book.GuidedReadingLevel === "" || book.GuidedReadingLevel === null) {
      grl = "grl-hide";
    } else if (book.ATOS === "" || book.ATOS === null) {
      atos = "atos-hide";
    } else if (book.LEX === "" || book.LEX === null) {
      lex = "lex-hide";
    }

    if (!book.GuidedReadingLevel && !book.ATOS && !book.LEX) {
      hideBookReadingLevel = "";
      return;
    }

    return (
      <div className={hideBookReadingLevel}>
        <span className="reading-book-level-text">
          {book.ATOS !== null && book.ATOS !== "" && (
            <span className={atos}>A: {book.ATOS}, </span>
          )}
          {book.LEX !== null && book.LEX !== "" && (
            <span className={lex}>L: {book.LEX}, </span>
          )}
          {book.GuidedReadingLevel !== null &&
            book.GuidedReadingLevel !== "" && (
              <span className={grl}>GRL: {book.GuidedReadingLevel}</span>
            )}
        </span>
      </div>
    );
  };

  isDownloaded(book) {
    return this.props.downloadedBooks.some(
      (downloadedBook) => downloadedBook.ID === book.ID,
    );
  }

  changeView = (value) => {
    localStorage.setItem("listView", value);
    this.setState({ listView: value });
  };

  displayBookImage = (book) => {
    let img = !!book.OfficialImage ? book.OfficialImage : defaultPhoto;
    const bookTitle = book.Title;
    let downloadedIcon;
    forEach(this.props.downloadedBooks, (dlBook) => {
      if (book.ID === dlBook.ID) {
        downloadedIcon = downloadedImage;
      }
    });

    let photo = {
      backgroundImage: `url(${img})`,
      backgroundPosition: "center center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    };
    if (!!book.OfficialImage) {
      return (
        <div>
          <p className="book-title">{bookTitle}</p>
          <div
            className="book-details"
            style={photo}
            onClick={(e) => this.handleBookClick(e, book)}
          >
            <img className="downloaded-book" src={downloadedIcon} alt="" />
            {book.IsExternal && (
              <div className="btn-blue external-link">
                <i className="fa fa-external-link" aria-hidden="true"></i>
              </div>
            )}
            {this.displayBookReadingLevel(book)}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className=" book-details"
          style={photo}
          onClick={(e) => this.handleBookClick(e, book)}
        >
          <img className="downloaded-book" src={downloadedIcon} alt="" />
          {book.IsExternal && (
            <div className="btn-blue external-link">
              <i className="fa fa-external-link" aria-hidden="true"></i>
            </div>
          )}
          <p className="book-title">{bookTitle}</p>
          {this.displayBookReadingLevel(book)}
        </div>
      );
    }
  };

  render() {
    if (requireSignIn(this.props.user, this.props.location)) {
      return null;
    }

    return (
      <>
        <Loading show={this.state.booksData || this.state.hub.Hubs ? this.props.loading : true} />
        <Container className="content modal-container book-bag-content">
          <BookBagHeader
            isOnline={this.props.isOnline}
            toggleUserAccountModal={this.props.toggleUserProfileModal}
            addCode={this.props.addCode}
            query={this.props.query}
            theme={this.theme}
            isFiltersActive={this.filtersActive()}
            handleInvalidSession={this.handleInvalidSession}
            handleCategoryClick={this.handleCategoryClick}
            selectedCategory={this.state.selectedCategory}
            handleBookSearchValue={debounce(this.handleBookSearchValue, 200)}
            handleReadingSearchValue={this.handleReadingSearchValue}
            categories={this.state.categories}
            handleHomeClick={this.handleHomeClick}
            bookBagCount={this.state.bookBagCount}
            hideBookBag={this.state.hideBookBag}
            handleSearchMode={this.handleSearchMode}
            searchMode={this.state.searchMode}
            hideNav={this.state.hideNav}
          />
          {this.state.hub.Hubs ? (
            <SideNav
              hubLevel={this.state.hubLevel}
              subHubs={this.state.subHubs}
              handleLevelClick={this.handleLevelClick}
            />
          ) : null}
          {!this.props.isOnline && (
            <Row>
              <Col md={12} className="offline-message">
                You are offline
              </Col>
            </Row>
          )}
          {this.state.booksData ? (
            <>
              {this.state.categories.length > 0 ? (
                <div className="books-sliders-list" data-hubs={this.state.hideNav}>
                  {this.state.continueReadingBooks.length > 0 &&
                    !this.state.isContinueReadingBooks &&
                    !this.state.selectedCategory &&
                    !this.state.bookSearchValue &&
                    !this.state.readingLevelSearchValue &&
                    !this.state.bookBagSelected && (
                      <div className="bookslider" key="continue_reading">
                        <BookSlider
                          heading="Continue Reading"
                          books={this.state.continueReadingBooks}
                          isStudent={this.props.isStudent}
                          shouldHide={this.state.shouldHide}
                          openBook={this.openBook}
                          bookInfo={this.bookInfo}
                          addBook={this.handleAddBook}
                          removeBook={this.props.removeBook}
                          displayBookReadingLevel={this.displayBookReadingLevel}
                          count={this.state.continueReadingBooks.length}
                          categories={this.state.categories}
                          showReadingLevel={this.state.showReadingLevel}
                          setIsContinueReadingBooks={
                            this.setIsContinueReadingBooks
                          }
                        />
                      </div>
                    )}
                  {this.state.bookBagBooks.length > 0 &&
                    !this.state.selectedCategory &&
                    !this.state.bookSearchValue &&
                    !this.state.shouldHide &&
                    !this.state.readingLevelSearchValue &&
                    !this.state.bookBagSelected && (
                      <div className="bookslider" key="book_bag">
                        <BookSlider
                          heading="Book Bag"
                          books={this.state.bookBagBooks}
                          isStudent={this.props.isStudent}
                          shouldHide={this.state.shouldHide}
                          openBook={this.openBook}
                          bookInfo={this.bookInfo}
                          addBook={this.handleAddBook}
                          removeBook={this.props.removeBook}
                          displayBookReadingLevel={this.displayBookReadingLevel}
                          count={this.state.bookBagBooks.length}
                          categories={this.state.categories}
                          showReadingLevel={this.state.showReadingLevel}
                          handleSearchMode={this.handleSearchMode}
                          sectionImage={Bookbag}
                        />
                      </div>
                    )}
                  {!this.state.isContinueReadingBooks &&
                    !this.state.selectedCategory &&
                    !this.state.bookSearchValue &&
                    !this.state.readingLevelSearchValue &&
                    !this.state.bookBagSelected &&
                    Object.keys(this.state.booksData)
                      .filter(
                        (section) =>
                          this.state.booksData[section].books?.length > 0,
                      )
                      .map((section) => (
                        <div className="bookslider" key={section}>
                          <BookSlider
                            heading={section}
                            books={this.state.booksData[section].books.slice(
                              0,
                              24,
                            )}
                            isStudent={this.props.isStudent}
                            shouldHide={this.state.shouldHide}
                            count={this.state.booksData[section].count}
                            openBook={this.openBook}
                            bookInfo={this.bookInfo}
                            addBook={this.handleAddBook}
                            removeBook={this.props.removeBook}
                            handleCategoryClick={this.handleCategoryClick}
                            displayBookReadingLevel={this.displayBookReadingLevel}
                            user={this.props.user}
                            isSectionBooks={true}
                            sectionImage={
                              this.state.booksData[section].books[0].sectionImage
                            }
                            categories={this.state.categories}
                            showReadingLevel={this.state.showReadingLevel}
                          />
                        </div>
                      ))}
                </div>
              ) : (
                !this.state.bookSearchValue &&
                !this.state.readingLevelSearchValue && (
                  <div className="books-sliders-list">
                    <BooksGrid
                      listView={this.state.listView}
                      emptySearchMessage={""}
                      filteredBooks={this.state.booksData.books}
                      isStudent={this.props.isStudent}
                      shouldHide={this.state.shouldHide}
                      section={this.props.isStudent ? this.state.isBookbag ? "Book Bag" : "Book Room" : "All Books"}
                      count={this.state.bookBagCount}
                      addBook={this.handleAddBook}
                      removeBook={this.removeBook}
                      openBook={this.openBook}
                      bookInfo={this.bookInfo}
                      displayBookReadingLevel={this.displayBookReadingLevel}
                      changeView={this.changeView}
                      defaultPhoto={defaultPhoto}
                      constants={constants}
                      toastr={toastr}
                      fetchMoreData={this.fetchMoreData}
                      downloadBook={this.props.downloadBook}
                      isSearchingBooks={false}
                      showReadingLevel={this.state.showReadingLevel}
                      bookBagSelected={this.state.bookBagSelected}
                    />
                  </div>
                )
              )}
              {(this.state.isContinueReadingBooks ||
                this.state.selectedCategory) &&
                !this.state.bookSearchValue &&
                !this.state.readingLevelSearchValue && (
                  <>
                    <BooksGrid
                      listView={this.state.listView}
                      emptySearchMessage={""}
                      filteredBooks={
                        this.state.isContinueReadingBooks
                          ? this.state.continueReadingBooks
                          : this.state.booksData[this.state.selectedCategory.Name]?.books
                      }
                      count={this.state.isContinueReadingBoo ? this.state.continueReadingBooks.length : this.state.booksData[this.state.selectedCategory.Name]?.count}
                      isStudent={this.props.isStudent}
                      shouldHide={this.state.shouldHide}
                      section={
                        this.state.isContinueReadingBooks
                          ? "Continue Reading"
                          : this.state.selectedCategory.Name
                      }
                      addBook={this.handleAddBook}
                      removeBook={this.props.removeBook}
                      openBook={this.openBook}
                      bookInfo={this.bookInfo}
                      displayBookReadingLevel={this.displayBookReadingLevel}
                      changeView={this.changeView}
                      defaultPhoto={defaultPhoto}
                      constants={constants}
                      toastr={toastr}
                      fetchMoreData={this.fetchMoreData}
                      downloadBook={this.props.downloadBook}
                      isSearchingBooks={false}
                      sectionImage={
                        this.state.booksData[this.state.selectedCategory.Name]?.books[0]?.sectionImage
                      }
                      showReadingLevel={this.state.showReadingLevel}
                      hideNav={this.state.hideNav}
                    />
                    {!this.state.isContinueReadingBooks &&
                      this.state.booksData[this.state.selectedCategory.Name]?.length === 0 && (
                        <div className="empty-search-message">
                          No books available in this category.
                        </div>
                      )}
                  </>
                )}
              {(!this.state.bookSearchValue ||
                !this.state.readingLevelSearchValue) &&
                !this.props.isStudent &&
                this.state.bookBagSelected && (
                  <>
                    <BooksGrid
                      listView={this.state.listView}
                      emptySearchMessage={""}
                      filteredBooks={this.state.bookBagBooks}
                      count={this.state.bookBagCount}
                      isStudent={this.props.isStudent}
                      shouldHide={this.state.shouldHide}
                      section={"Book Bag"}
                      addBook={this.handleAddBook}
                      removeBook={this.props.removeBook}
                      openBook={this.openBook}
                      bookInfo={this.bookInfo}
                      displayBookReadingLevel={this.displayBookReadingLevel}
                      changeView={this.changeView}
                      defaultPhoto={defaultPhoto}
                      constants={constants}
                      toastr={toastr}
                      fetchMoreData={this.fetchMoreData}
                      downloadBook={this.props.downloadBook}
                      bookBagSelected={this.state.bookBagSelected}
                      sectionImage={Bookbag}
                      showReadingLevel={this.state.showReadingLevel}
                      hideNav={this.state.hideNav}
                    />
                  </>
                )}
              {(!!this.state.bookSearchValue ||
                !!this.state.readingLevelSearchValue) && (
                  <>
                    <BooksGrid
                      listView={this.state.listView}
                      emptySearchMessage={""}
                      filteredBooks={this.props.books}
                      count={this.props.booksCount}
                      isStudent={this.props.isStudent}
                      shouldHide={this.state.shouldHide}
                      section={"Search Results"}
                      addBook={this.handleAddBook}
                      removeBook={this.props.removeBook}
                      openBook={this.openBook}
                      bookInfo={this.bookInfo}
                      displayBookReadingLevel={this.displayBookReadingLevel}
                      changeView={this.changeView}
                      defaultPhoto={defaultPhoto}
                      constants={constants}
                      toastr={toastr}
                      fetchMoreData={this.fetchMoreData}
                      downloadBook={this.props.downloadBook}
                      isSearchingBooks={false}
                      showReadingLevel={this.state.showReadingLevel}
                      hideNav={this.state.hideNav}
                    />
                  </>
                )}
            </>
          ) : Object.values(this.state.hub).some(Boolean) ? (
            <div className="hub-grid-list">
              <h1 className="hub-title">
                {this.state.hubTitle || hubTitles[this.state.hubLevel]}
              </h1>
              <section className="hub-grid">
                {this.state.hubLevel === hubLevels.Hubs && this.state.hub.Hubs?.map((item) => (
                  <HubItem
                    key={item.ID}
                    hubItem={item}
                    hubLevel={this.state.hubLevel}
                    showReadingLevel={this.state.showReadingLevel}
                    bookInfo={this.bookInfo}
                    displayBookReadingLevel={this.displayBookReadingLevel}
                    handleClick={this.fetchHubsBooks}
                  />
                ))}
                {this.state.hubLevel === hubLevels.HubsAndChildren && this.state.hub.HubsAndChildren?.map((item) => (
                  <HubItem
                    key={item.ID}
                    hubItem={item}
                    hubLevel={this.state.hubLevel}
                    showReadingLevel={this.state.showReadingLevel}
                    bookInfo={this.bookInfo}
                    displayBookReadingLevel={this.displayBookReadingLevel}
                    handleClick={this.handleHubsBooks}
                  />

                ))}
                {this.state.hubLevel === hubLevels.Children && this.state.hub.Children?.map((item) => (
                  <HubItem
                    key={item.ID}
                    hubItem={item}
                    hubLevel={this.state.hubLevel}
                    showReadingLevel={this.state.showReadingLevel}
                    bookInfo={this.bookInfo}
                    displayBookReadingLevel={this.displayBookReadingLevel}
                    handleClick={this.openBook}
                  />
                ))}
                {this.state.hubLevel === hubLevels.Search && this.state.hub.Search?.map((item) => (
                  <HubItem
                    key={item.ID}
                    hubItem={item}
                    hubLevel={this.state.hubLevel}
                    showReadingLevel={this.state.showReadingLevel}
                    bookInfo={this.bookInfo}
                    displayBookReadingLevel={this.displayBookReadingLevel}
                    handleClick={this.handleHubsBooks}
                  />
                ))}
              </section >
            </div >
          ) : null}
        </Container>
        <BookInfo
          book={this.state.bookInfo}
          className="book-info-modal"
          isStudent={this.props.isStudent}
          modalVisable={this.state.showInfoModal}
          resources={this.state.resources}
          schoolID={this.state.hubSchoolID}
          shouldHide={this.state.shouldHide}
          tags={this.state.tags}
          userToken={this.props.user.AzureToken}
          addBook={this.handleAddBook}
          cancel={() => this.setState({ showInfoModal: false })}
          displayBookReadingLevel={this.displayBookReadingLevel}
          openBook={this.openBook}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const addCode =
    ownProps.location.query.addCode ||
    ownProps.location.query.addcode ||
    ownProps.location.query.AddCode;
  const isGeneric = UserAPI.isGeneric(state.user.RoleID);

  return {
    completedBLMs: state.completedBLMs,
    user: {
      ...state.user,
      School: {
        ...state.user.School,
        HideBookbag: isGeneric || state.user?.School?.HideBookbag,
      }
    },
    showReadingLevel: state.user?.School?.ShowReadingLevel,
    book: state.book,
    books: state.books,
    hubAndBooks: state.hubAndBooks,
    booksCount: state.booksCount,
    bookbagFilters: state.bookbagFilters,
    loading: state.ajaxCallsInProgress > 0,
    isOnline: state.offlineQueue.isOnline && navigator.onLine,
    downloadedBooks: state.downloadedBooks,
    pageResults: state.dashboardPageResults,
    query: { ...initialDashboardQuery, ...ownProps.location.query },
    addCode,
    isGeneric,
    isStudent: UserAPI.isStudent(state.user.RoleID),
    isDemo: UserAPI.isDemo(state.user.RoleID),
  };
}

export default connect(mapStateToProps, {
  addBook,
  removeBook,
  searchBookBagBooks,
  userLogout,
  manualAjaxStart,
  toggleUserProfileModal,
  downloadBook,
  deleteDownloadedBook,
  toggleClassCodeModal,
  beginAjaxCall,
  endAjaxCall,
})(BookBag);
