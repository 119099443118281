export const roleIDs = {
  Teacher: "C89F9DD9-ED08-43F5-8FC8-DBFCB1A3D008",
  SuperAdmin: "BA2ED223-7F90-4700-B04E-3DA0025D2F5A",
  SchoolAdmin: "B751ECE9-97E1-439D-BC27-9665E47D847E",
  DistrictAdmin: "76228252-F3A8-4165-BF9B-E22857593CF9",
  SalesRep: "D335673F-182A-4E72-B4B6-F20856698843",
  Student: "357001F1-7DC2-496F-82F3-8FC21813BF6C",
  Generic: "2E4030D1-BD1A-451E-B1C8-010CAA433BBC",
  Demo: "E3F41C5E-DC13-4859-9A51-3124B6724297",
  Admin3PL: "fabe0d9e-c267-46b6-b0b0-10b090a79910",
}

export const studentReadingEventTypes = {
  BookFirstOpen: 0,
  BookOpen: 1,
  BookClose: 2,
  PageNext: 3,
  PageBack: 4,
  PageFirst: 5,
  PageLast: 6,
  BookTimeOut: 7,
  BookFinish: 8,
  LaunchedExternalBook: 9,
}

export const hubLevels = {
  Search: 0,
  Hubs: 1,
  HubsAndChildren: 2,
  Children: 3,
}

export const hubTitles = {
  0: "Search Results",
  1: "Home",
  2: "Sections",
  3: "Books",
}

export const keyFoundByValue = (obj, value) => {
  const keys = [];

  for (const key in obj) {
    if (obj[key] === value) {
      keys.push(key);
    }
  }

  if (keys.length === 0) return false;

  return true;
}
