import * as React from "react";
import isHotkey from "is-hotkey";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { FormControl, InputGroup } from "react-bootstrap";

import EyeGlassIcon from "../../images/eyeglass-2.svg";

import "./Navbar.scss";

import {
  checkForUpdatedBookFiles,
  getBookItemsList,
  initialDashboardQuery,
  searchBookBagBooks,
} from "../../actions/bookActions";
import { clearAdvancedSearchFilter } from "../../actions/bookbagFiltersActions";

interface Iprops {
  query: typeof initialDashboardQuery;
  isOnline: boolean;
  bookbagFilters: {
    tags: any;
    spanishOnly: any;
    selectedReadingLevelTypeOption: any;
    readingMax: any;
    readingMin: any;
  };
  showSearchBar: boolean;
  theme: any;
  books: Array<any>;
  searchBookBagBooks: typeof searchBookBagBooks;
  isFiltersActive: boolean;
  user: any;
  downloadedBooks: Array<any>;
  checkForUpdatedBookFiles: typeof checkForUpdatedBookFiles;
  manualAjaxEnd: () => void;
  handleInvalidSession: () => void;
  clearAdvancedSearchFilter: typeof clearAdvancedSearchFilter;
  loading: boolean;
  isStudent: boolean;
  isGeneric: boolean;
  getBookItemsList: typeof getBookItemsList;
  isBig: boolean;
  handleBookSearchValue: any;
  handleReadingSearchValue: any;
  searchMode: any;
}

interface QueryValues {
  grl: string;
  value: string;
  searchMode: string;
}

const BookBagSearch = (props: Iprops) => {
  const [showSearchIcon, setShowSearchIcon] = React.useState<boolean>(true);
  const [showReadingIcon, setShowReadingIcon] = React.useState<boolean>(true);
  const [bookSearchValue, setBookSearchValue] = React.useState<string>(
    localStorage.getItem("bookSearchValue") || "",
  );
  const [readingSearchValue, setReadingSearchValue] = React.useState<string>(
    localStorage.getItem("readingSearchValue") || "",
  );
  const [queryValue, setQueryValue] = React.useState<QueryValues>({
    grl: "",
    value: "",
    searchMode: "",
  });

  React.useEffect(() => {
    if (props.isOnline && props.downloadedBooks.length > 0) {
      setTimeout(
        () => {
          props.getBookItemsList(props.downloadedBooks, props.user);
        },
        props.loading ? 5000 : 0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOnline, props.downloadedBooks.length]);

  const handleReadingInputChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = evt.target.value;
    const newQueryValue = {
      grl: inputValue,
      value: bookSearchValue,
      searchMode: props.searchMode,
    };

    !!inputValue ? setShowReadingIcon(false) : setShowReadingIcon(true);
    localStorage.setItem("readingSearchValue", inputValue);

    setReadingSearchValue(inputValue);
    setQueryValue(newQueryValue);
  };

  const handleBookInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = evt.target.value;
    const newQueryValue = {
      grl: readingSearchValue,
      value: inputValue,
      searchMode: props.searchMode,
    };

    !!inputValue ? setShowSearchIcon(false) : setShowSearchIcon(true);
    localStorage.setItem("bookSearchValue", inputValue);

    setBookSearchValue(inputValue);
    setQueryValue(newQueryValue);
  };

  React.useEffect(() => {
    const isEnterHotkey = isHotkey("Enter");

    const handleKeyDown = (event: KeyboardEvent) => {
      if (isEnterHotkey(event)) {
        props.handleBookSearchValue(queryValue);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [props, queryValue]);

  return (
    props.showSearchBar &&
    props.isOnline && (
      <form
        id="search-form"
        className={
          !!props.isBig
            ? "d-flex align-items-center search-bg"
            : "nav-item d-flex align-items-center"
        }
      >
        {!!props.isBig ? (
          <>
            <InputGroup className="m-0 me-2 search">
              {showSearchIcon && (
                <InputGroup.Text className="input-icon" id="basic-addon1">
                  <SearchIcon />
                </InputGroup.Text>
              )}
              <FormControl
                className={`search-input-field ${
                  !showSearchIcon ? "full-width" : ""
                } nav-text`}
                type="search"
                placeholder="Search Title, ISBN, Tags or Keywords"
                value={bookSearchValue}
                onChange={handleBookInputChange}
              />
            </InputGroup>

            <span className="me-2">
              <AddIcon className="add-icon" />
            </span>

            <InputGroup className="m-0 me-2 reading">
              {showReadingIcon && (
                <InputGroup.Text className="input-icon" id="basic-addon1">
                  <img src={EyeGlassIcon} alt="" />
                </InputGroup.Text>
              )}
              <FormControl
                className={`reading-level-field ${
                  !showReadingIcon ? "full-width" : ""
                } nav-text`}
                type="search"
                placeholder="Reading Level"
                value={readingSearchValue.toUpperCase()}
                onChange={handleReadingInputChange}
              />
            </InputGroup>
          </>
        ) : (
          <InputGroup className="m-0 me-2 search-small">
            {showSearchIcon && (
              <InputGroup.Text className="input-icon" id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
            )}
            <FormControl
              className={`search-input-small ${
                !showSearchIcon ? "full-width" : ""
              } nav-text`}
              type="search"
              placeholder="Search Title, ISBN, Tags or Keywords"
              value={bookSearchValue}
              onChange={handleBookInputChange}
            />
          </InputGroup>
        )}
        <button
          type="button"
          id="run-search"
          onClick={() => {
            props.handleBookSearchValue(queryValue);
          }}
        >
          Go
        </button>
      </form>
    )
  );
};

export default BookBagSearch;
