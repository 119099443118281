import * as React from 'react';
import { FormControl, InputGroup } from "react-bootstrap";
import { Modal } from 'react-bootstrap';

import config from "../../api/config";

import BookBagIcon from "../../images/Backback.svg";
import CloseIcon from '../../images/close-modal.svg';
import ModalSearchIcon from '../../images/modal-search.svg';
import OpenBookIcon from '../../images/open-book.svg';
import StudentLinkIcon from '../../images/student-link.svg';

const SearchField = ({ name, placeholder, value, onChange }) => (
    <InputGroup className="m-0 me-2 search">
        <InputGroup.Text className="input-icon" id="basic-addon1">
            <img src={ModalSearchIcon} alt="" aria-hidden="true" />
        </InputGroup.Text>
        <FormControl
            name={name}
            className="search-input-field"
            type="search"
            placeholder={placeholder}
            value={value}
            data-active={Boolean(value)}
            onChange={onChange}
        />
    </InputGroup>
);

const BookInfo = ({
    book,
    className,
    isStudent,
    modalVisable,
    resources,
    shouldHide,
    tags,
    userToken,
    addBook,
    cancel,
    displayBookReadingLevel,
    openBook,
    schoolID,
}) => {
    const [activeTab, setActiveTab] = React.useState(0);
    const [reasourceItems, setReasourceItems] = React.useState(resources);
    const [tagItems, setTagItems] = React.useState(tags);
    const [reasourceSearchValue, setReasourceSearchValue] = React.useState('');
    const [tagsSearchValue, setTagsSearchValue] = React.useState('');
    const [clipboardMessage, setClipboardMessage] = React.useState('Student Link');
    const history = window.location;

    const TABS = [
        {
            title: 'Book Info',
        },
        {
            title: 'Teacher Resources',
        },
        {
            title: 'Tags',
        },
    ];

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setClipboardMessage('Copied to Clipboard!');
        } catch (err) {
            console.error('Failed to copy text: ', err);
            setClipboardMessage('Clipboard Failed!');
        } finally {
            setTimeout(() => {
                setClipboardMessage('Student Link');
            }, 3000);
        }
    }

    const handleCancle = React.useCallback(() => {
        cancel();
        setActiveTab(0);
        setReasourceSearchValue('');
        setTagsSearchValue('');
        setReasourceItems([]);
        setTagItems([]);
    }, [cancel]);

    const handleStudentLink = React.useCallback(() => {
        const currSchoolID = schoolID || book.section.SchoolID;
        const link = `${history.origin}/#/viewer?bookID=${book.ID}&schoolID=${currSchoolID}`
        copyToClipboard(link);

    }, [book, history, schoolID]);

    React.useEffect(() => {
        if (!book) return;


        if (reasourceSearchValue) {
            const reasourcesFiltered = resources.filter(item =>
                item.Name.toLowerCase().includes(reasourceSearchValue.toLowerCase()) ||
                item.Description.toLowerCase().includes(reasourceSearchValue.toLowerCase())
            );
            setReasourceItems(reasourcesFiltered);
        } else {
            setReasourceItems(resources);
        }

        if (tagsSearchValue) {
            const tagsFiltered = tags.filter(item =>
                item.Name.toLowerCase().includes(tagsSearchValue.toLowerCase())
            );
            setTagItems(tagsFiltered);
        } else {
            setTagItems(tags);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [book, reasourceSearchValue, tagsSearchValue]);

    React.useEffect(() => {
        if (resources && resources.length > 0) {
            setReasourceItems(resources);
        } else {
            setReasourceItems([]);
        }
    }, [resources]);

    React.useEffect(() => {
        if (tags && tags.length > 0) {
            setTagItems(tags);
        } else {
            setTagItems([]);
        }
    }, [tags]);

    if (!book) return null;

    return (
        <div>
            <Modal
                show={modalVisable}
                onHide={handleCancle}
                className={className}
            >
                <button
                    type='button'
                    className='close-modal'
                    onClick={handleCancle}
                >
                    <img src={CloseIcon} alt='close' aria-hidden="true" />
                </button>
                <Modal.Body>
                    {book.OfficialImage ?
                        (<img className="book-img" src={book.OfficialImage} alt="" />) :
                        (<div className="book-placeholder"><p>{book.Title}</p></div>)
                    }
                    <section className="content-wrap">
                        <ul className="tabs-list">
                            {TABS.map((tab, index) => (
                                <li key={index}>
                                    <button
                                        type="button"
                                        data-active={activeTab === index}
                                        onClick={() => setActiveTab(index)}
                                    >
                                        {tab.title}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <div className="body">
                            <div
                                className="body-inner"
                                data-active={activeTab === 0}
                            >
                                <p className="title">{book.Title}</p>
                                <p className="ibsn">IBSN: {book.ISBN}</p>
                                <p className="description">{book.Description}</p>
                                <p className="meta">
                                    {book.IsFiction ? 'Fiction' : 'Non-Fiction'}
                                    {displayBookReadingLevel(book, true)}
                                </p>
                            </div>
                            <form
                                id="search-form"
                                className="d-flex align-items-center search-bg"
                            >
                                <div
                                    className="body-inner"
                                    data-active={activeTab === 1}
                                >
                                    <SearchField
                                        name="resource-search"
                                        placeholder="Search Resources"
                                        value={reasourceSearchValue}
                                        onChange={(evt) => {
                                            const formVal = evt.target.value;
                                            setReasourceSearchValue(formVal);
                                            if (!formVal) setReasourceItems([]);
                                        }}
                                    />
                                    <div className="resource-list-wrap">
                                        <ul className="resource-list">
                                            {reasourceItems && reasourceItems.map((resource, index) => (
                                                <li key={index}>
                                                    <a
                                                        href={`${config.API.Storage}/books/TeacherResources/${resource.Url}${userToken}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {resource.Name}
                                                    </a>
                                                    <p>{resource.Description}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className="body-inner"
                                    data-active={activeTab === 2}
                                >
                                    <SearchField
                                        name="tags-search"
                                        placeholder="Search Tags (3 character minimum)"
                                        value={tagsSearchValue}
                                        onChange={(evt) => {
                                            setTagsSearchValue(evt.target.value);
                                        }}
                                    />
                                    <ul className="tag-list">
                                        {tagItems && tagItems.map((tag, index) => (
                                            <li key={index}>
                                                <p>{tag.Name}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className='student-link'
                        onClick={() => handleStudentLink()}
                    >
                        <img src={StudentLinkIcon} alt='student link' aria-hidden="true" />
                        {clipboardMessage}
                    </button>
                    <button
                        type="button"
                        className='open-book'
                        onClick={() => openBook(book)}
                    >
                        <img src={OpenBookIcon} alt='open e book' aria-hidden="true" />
                        Open E-Book
                    </button>
                    {!shouldHide && isStudent && !book.IsStudentAdded && (
                        <button
                            type="button"
                            className="book-bag"
                            onClick={() => addBook(book)}
                        >
                            <img src={BookBagIcon} alt="add to book bag" aria-hidden="true" />
                        </button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default BookInfo;