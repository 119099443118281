import * as React from 'react';

import { hubLevels } from "../../constants/enums";

import HubsAndChildrenIcon from '../../images/hubs-children-icon.svg';
import ChildrenIcon from '../../images/children-icon.svg';
import NavGuideIcon from '../../images/hub-nav-guide.svg';
import HubsIcon from '../../images/hubs-icon.svg';


const SideNav = ({ hubLevel, subHubs, handleLevelClick }) => {
  const lastSub = hubLevel > hubLevels.HubsAndChildren ? subHubs[subHubs.length - 1] : {};
  const cleanSubs = hubLevel > hubLevels.HubsAndChildren ? subHubs.slice(0, subHubs.length - 1) : subHubs;

  return (
    <aside id="side-nav">
      <button
        type="button"
        className="side-nav-btn"
        data-type="hub-main"
        data-active="true"
        onClick={() => handleLevelClick(hubLevels.Hubs)}
      >
        <img src={HubsIcon} alt="Hubs" aria-hidden="true" />
        <span className="btn-name">Hubs</span>
      </button>
      {hubLevel > hubLevels.Hubs && (
        cleanSubs.map((sub, index) => (
          <>
            <div className="side-nav-guide" data-active={true}>
              <img src={NavGuideIcon} alt="" aria-hidden="true" />
            </div>
            <button
              type="button"
              className="side-nav-btn"
              data-type="hub-sub"
              data-icon={Boolean(sub.IconUrl)}
              data-active={true}
              onClick={() => handleLevelClick(hubLevels.HubsAndChildren, sub)}
            >
              <img src={sub.IconUrl || HubsAndChildrenIcon} alt="Children" aria-hidden="true" />
              <span className="btn-name">{sub.Name || 'Hubs and Children'}</span>
            </button>
          </>
        ))
      )}
      <div className="side-nav-guide" data-active={hubLevel > hubLevels.HubsAndChildren}>
        <img src={NavGuideIcon} alt="" aria-hidden="true" />
      </div>
      <button
        type="button"
        className="side-nav-btn"
        data-type="hub-children"
        data-icon={Boolean(lastSub.IconUrl)}
        data-active={hubLevel > hubLevels.HubsAndChildren}
        onClick={() => handleLevelClick(hubLevels.Children)}
      >
        <img src={lastSub.IconUrl || ChildrenIcon} alt="Collections" aria-hidden="true" />
        <span className="btn-name">{lastSub.Name || "Children"}</span>
      </button>
    </aside>
  )
};

export default SideNav;
