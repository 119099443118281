/* eslint-disable jsx-a11y/anchor-is-valid */
// NotesPopover is responsible for displaying the note icon and displaying the popover

import * as React from "react";
import { FormControl, OverlayTrigger } from "react-bootstrap";
import class_note_icon from "../../images/class_note_icon.png";
import constants from "../../constants/constants";
import group_note_icon from "../../images/group_note_icon.png";
import student_note_icon from "../../images/student_note_icon.svg";
import teacher_note_icon from "../../images/teacher_note_pin.svg";

class NotePopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.note.Content.value || "",
      show: false,
    };
    this.theme = constants.themeProvider.activeTheme;
  }

  componentDidMount() {
    if (
      this.props.activeNoteID === this.props.note.ID ||
      this.props.activeNoteID === this.props.note.TempID
    ) {
      this.setState({ show: true });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.activeNoteID !== prevProps.activeNoteID) {
      if (
        this.props.activeNoteID === this.props.note.ID ||
        this.props.activeNoteID === this.props.note.TempID
      ) {
        this.setState({ show: true });
      } else {
        this.setState({ show: false });
      }
    }
  }

  onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const note = this.props.note;
    const newNote = {
      ...note,
      Content: { ...note.Content, value: this.state.value },
    };
    this.props.saveNote(newNote);
    this.props.setActiveNote("");
  };

  cancelNote = () => {
    this.props.setActiveNote("");
    // delete the note if the user never saved any content
    if (this.props.note.Content.value === "") {
      this.props.deleteNote(this.props.note);
    }
  };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  getPopover = (note) => {
    let popoverClassName = "";
    let disableNote = false;
    let hideFromStudent = "";

    if (note.Type === 4 || note.Type === 5 || note.Type === 6) {
      popoverClassName = "teacher";
    } else if (note.Type === 3 && this.props.isStudent) {
      popoverClassName = "student";
    }

    if (
      this.props.isStudent &&
      (note.Type === 5 || note.Type === 4 || note.Type === 6)
    ) {
      disableNote = true;
      hideFromStudent = "hide";
    }

    return (
      <div className="note-popup">
        <FormControl
          as="textarea"
          placeholder="Type your note"
          class="note-text-area"
          disabled={disableNote}
          value={this.state.value}
          onChange={this.handleChange}
          rows={10}
        />

        <div className={`action-buttons ${popoverClassName}`}>
          <a
            css={this.theme.warningButtonStyle}
            className={`delete ${hideFromStudent}`}
            onClick={() => {
              this.props.deleteNote(this.props.note);
            }}
          >
            <span className="button-label">Delete</span>
          </a>
          <div className="right-buttons">
            <a
              className="cancel"
              onClick={() => {
                this.cancelNote(this.props.note);
              }}
            >
              <span className="button-label">Cancel</span>
            </a>

            <a
              css={this.theme.secondaryButtonStyle}
              className={`save ${hideFromStudent}`}
              onClick={() => {
                this.onSubmit();
              }}
              disabled={this.props.loading}
            >
              <span className="button-label">Save</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  handleToggle = () => {
    const noteID = this.props.note.ID || this.props.note.TempID;
    this.props.setActiveNote(noteID);
  };

  render() {
    const note = this.props.note;
    const offsetX = note.Content.offsetX;
    const offsetY = note.Content.offsetY;
    const ID = !!note.ID ? note.ID : note.TempID;
    let note_icon;
    if (note.Type === 3) {
      note_icon = student_note_icon;
    } else if (note.Type === 4) {
      note_icon = teacher_note_icon;
    } else if (note.Type === 5) {
      note_icon = class_note_icon;
    } else if (note.Type === 6) {
      note_icon = group_note_icon;
    }
    let placement = "top";
    if (note.Content.offsetY < 240) {
      // 190 is the height of the popover
      placement = "bottom";
    }
    return (
      <OverlayTrigger
        trigger="click"
        show={this.state.show}
        onToggle={this.handleToggle}
        placement={placement}
        overlay={this.getPopover(note)}
      >
        <div
          key={ID}
          className={"note note-" + ID}
          style={{
            position: "absolute",
            top: offsetY,
            left: offsetX,
            transform: `scale(${1 / this.props.pageScalePercent})`,
            zIndex: 3,
            transformOrigin: "top left",
          }}
        >
          {this.props.note.TeacherID !== null && (
            <img alt="" src={teacher_note_icon} />
          )}
          {this.props.note.StudentID !== null && (
            <img alt="" src={student_note_icon} />
          )}
        </div>
      </OverlayTrigger>
    );
  }
}

export default NotePopover;
