import config from "./config";
import { forEach } from "lodash";
import differenceInDays from "date-fns/differenceInDays";
import JSZip from "jszip";
import BookFS from "./bookFS";
import {
  addTappableClassOnly,
  addSpeechMarkTimesAndTappableClass,
} from "../utilities/TextToSpeechUtility";
import UserAPI from "./userAPI";
// import { createReadStream } from "fs";

export const cachedFetch = async (cacheName, remoteURL, request) => {
  let data;

  // function to fetch the request and put the response in the cache
  const cacheReq = req => fetch(req).then(async (resp) => {
    if (resp.status !== 200 || !resp.ok) {
      throw resp;
    }

    // and put the network response in the cache
    cache.put(remoteURL, resp.clone()); // clone the response before putting it in the cache

    // read the response body and convert it to JSON
    const jsonData = await resp.json();

    return jsonData;
  });

  // open the cache
  const cache = await caches.open(cacheName);
  // try to get the response from the cache
  const cachedResponse = await cache.match(remoteURL);

  if (cachedResponse) {
    // check if the cached response is older than 3 day
    let expired = true;
    const cachedDate = cachedResponse.headers.get('date');

    if (cachedDate) {
      const prevDate = new Date(cachedDate);
      const currDay = new Date();
      const difference = differenceInDays(prevDate, currDay);

      expired = difference > 3;
    }

    if (expired) {
      // if the cached response is older than 3 days, delete it from the cache
      await cache.delete(remoteURL);
      // and fetch the response from the network
      data = await cacheReq(request);
    } else {
      // if the response is in the cache, return it
      data = await cachedResponse.json();
    }
  } else {
    // if the response is not in the cache, fetch it from the network
    data = await cacheReq(request);
  }

  return data;
};

export default class BookAPI {
  /* get the json properties for a particular book from blob storage */
  static getBookProperties(book, token) {
    const remoteURL = `${config.API.Storage}${book.EBookPath}${book.ISBN}/properties.json${token}`;
    var request = new Request(remoteURL, { method: "GET" });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  static async getBooksV2(user, schoolID, pageNo) {
    const remoteURL = `${config.API.Main}/student/getbooksv2?page=${pageNo}&schoolID=${schoolID}`;

    var request = new Request(remoteURL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    const response = await fetch(request);

    if (response.status !== 200) {
      throw response;
    }

    const res = await response.json();

    return {
      count: res.Count,
      pages: res.Pages,
      books: res.Result,
      currentPage: 1,
    };
  }

  static async getBookByCategory(pageNo, schoolID, sectionID, user) {
    const response = await this.getBooksBySection(
      pageNo,
      schoolID,
      sectionID,
      user,
    );
    const books = response.Result.map((book) => {
      const { ID, Name, SectionImage } = book.Section;
      const { ...bookData } = book.Book;
      bookData.sectionId = ID;
      bookData.sectionName = Name;
      bookData.section = book.Section;
      bookData.sectionImage = SectionImage;
      return bookData;
    });
    return books;
  }

  static async getBooksBySection(pageNo, schoolID, sectionID, user, max) {
    const cacheName = "getBooksBySection";
    const remoteURL = `${config.API.Main}/book/sections?page=${pageNo}&schoolID=${schoolID}&sectionID=${sectionID}&max=${max || 24}&firstPage=${pageNo === 1}`;
    const request = new Request(remoteURL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    const data = await cachedFetch(cacheName, remoteURL, request);

    // WARNING: because the only books shown in the first page are determined by the ordinal value, the first page is not technically the first page, so we need to add 1 to the page number
    return {
      ...data,
      Pages: data.Pages + 1,
    };
  }

  // page: number;
  // schoolID: string;
  // search: string | null;
  // grl: string | null;
  // max: number;
  static async getBooksBySectionSearch(user, pageNo, schoolID, search, grl) {
    const remoteURL = `${config.API.Main}/book/sectionsearch?page=${pageNo}&schoolID=${schoolID}&search=${search}&grl=${grl}`;

    var request = new Request(remoteURL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    const response = await fetch(request);

    if (response.status !== 200) {
      throw response;
    }

    const data = await response.json();

    return data;
  }

  static async getBooksByIds(ids, user) {
    const url = `${config.API.Main}/book/getbooks`;
    const body = JSON.stringify(ids);
    const request = new Request(url, {
      method: "POST",
      headers: UserAPI.getHeaders(user),
      body,
    });

    const response = await fetch(request);
    if (!response.ok) {
      throw new Error(`Failed to fetch books. Error code: ${response.status}`);
    }
    const res = await response.json();

    return res;
  }

  static async getSectionList(pageNo, schoolID, user) {
    const remoteURL = `${config.API.Main}/book/sectionlist?page=${pageNo}&schoolID=${schoolID}`;

    var request = new Request(remoteURL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    const response = await fetch(request);

    if (response.status !== 200) {
      throw response;
    }

    return await response.json();
  }

  static async getBooksBySections(user, schoolID, pageNo) {
    const sections = await this.getSectionList(pageNo, schoolID, user);

    const booksBySections = await Promise.all(
      sections.filter(section => section.IsVisible).map(async (section) => {
        const response = await this.getBooksBySection(
          pageNo,
          schoolID,
          section.ID,
          user,
        );
        const books = response.Result;

        const filteredBooks = books.map((book) => {
          const { ID, Name, SectionImage } = book.Section;
          const { ...bookData } = book.Book;
          bookData.sectionId = ID;
          bookData.sectionName = Name;
          bookData.section = section;
          bookData.sectionImage = SectionImage;
          return bookData;
        });

        return {
          [section.Name]: {
            count: response.Count,
            pages: response.Pages,
            books: filteredBooks,
            currentPage: 1,
          },
        };
      }),
    );

    return Object.assign({}, ...booksBySections);
  }

  static getSpeechMarksRemote(book, index, token, voice = "default") {
    if (!book.EBookPath || !book.ISBN || !token) {
      throw new Error(
        `missing book info path: ${book.EBookPath} ISBN: ${book.ISBN} token: ${token}`,
      );
    }
    const URL = `${config.API.Storage}${book.EBookPath}${book.ISBN}/audio/${voice}/${index}.marks${token}`;
    var request = new Request(URL, { method: "GET" });
    return fetch(request).then((response) => {
      if (response.status !== 200) {
        return Promise.resolve([]);
      }
      return response.text();
    });
  }

  /* get and process data for a particular book page from file storage (type blob) */
  static getBookPageLocal(book, index, pageKey) {
    console.log("getting book page local");
    const htmURL = `${index}.html`;
    const svgURL = `${index}/${index}.svg`;

    // fetch the html for the page
    const promise1 = BookFS.getBookFileEntry(book.ISBN, htmURL);

    // fetch the svg data for the page
    const promise2 = BookFS.getBookFileEntry(book.ISBN, svgURL);

    return Promise.all([promise1, promise2]).then((values) => {
      // return values;
      let pageData = values[0]; // html page data
      let svgData = values[1]; // temporarily set this to an array with the SVG file and the URL to the file
      // let fontPath = "";
      if (svgData === 404) {
        //not found
        svgData = "";
      } else {
        // const childImageUrl = svgData.search()
        // return BookFS.getBookFileUrl(book.ISBN, )
        const svgPromises = [];
        const fontPromises = [];
        // let rootPath = svgData[1]; // URL to the file
        // rootPath = rootPath.substring(0, rootPath.lastIndexOf('/')); // we are only replacing the rootPath, not the file name or the extension
        // fontPath = rootPath.substring(0, rootPath.lastIndexOf('/')); // the root directory of this book
        // svgData = svgData[0];
        // replace all relative image urls with full url
        svgData.replace(/((\w*)\/[0-9]*.(png|jpg))+/g, (originalPath) => {
          svgPromises.push(
            BookFS.getBookFileUrl(book.ISBN, `${index}/${originalPath}`).then(
              (newPath) => {
                svgData = svgData.replace(originalPath, newPath);
              },
            ),
          );
        });

        pageData.replace(/(fonts\/[a-zA-Z0-9\-_]*.\w*)/g, (originalPath) => {
          fontPromises.push(
            BookFS.getBookFileUrl(book.ISBN, `${originalPath}`).then(
              (newPath) => {
                pageData = pageData.replace(originalPath, newPath);
              },
            ),
          );
        });

        return Promise.all([...svgPromises, ...fontPromises]).then(
          (childValues) => {
            svgData = svgData.replace(
              '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
              "",
            );
            // now replace the entire svg <object> in the html with the svg element
            pageData = pageData.replace(/(<object[^<]*<\/object>)+/, svgData);

            // if marks are enabled and available, the speechMarks array for this page will have length
            if (
              book.cachedPages[pageKey] &&
              book.cachedPages[pageKey].speechMarks.length > 0
            ) {
              pageData = addSpeechMarkTimesAndTappableClass(
                book,
                pageData,
                index,
              );
            } else {
              pageData = addTappableClassOnly(pageData);
            }
            return pageData;
          },
        );
      }
    });
  }

  /* get and process data for a particular book page from blob storage */
  static getBookPageRemote(book, index, token, pageKey) {
    if (!book.EBookPath || !book.ISBN || !token) {
      throw new Error(
        `missing book info path: ${book.EBookPath} ISBN: ${book.ISBN} token: ${token}`,
      );
    }

    const root = `${config.API.Storage}${book.EBookPath}${book.ISBN}/`;
    const htmURL = `${root}${index}.html${token}`;
    const svgURL = `${root}${index}/${index}.svg${token}`;

    // fetch the html for the page
    const p1 = fetch(htmURL).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.text();
    });
    // fetch the svg data for the page
    const p2 = fetch(svgURL)
      .then((response) => {
        if (response.status !== 200) {
          throw response;
        }
        return response.text();
      })
      // catch any errors with svg fetch so we can
      // inspect and not autofail the whole request.
      .catch((err) => {
        if (err.status === 404) {
          // then this is a benign error - the svg is simply not there as expected
          return err.status;
        } else {
          throw err;
        }
      });
    return Promise.all([p1, p2]).then((values) => {
      // return values;
      let pageData = values[0];
      let svgData = values[1];
      if (svgData === 404) {
        //not found
        svgData = "";
      } else {
        // replace all relative image urls with full url
        svgData = svgData.replace(
          /((\w*)\/[0-9]*.(png|jpg))+/g,
          `${root}${index}/$1${token}`,
        );
        // remove extra crap we do not need for an embedded svg
        svgData = svgData.replace(
          '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
          "",
        );
      }
      // now replace the entire svg <object> in the html with the svg element
      pageData = pageData.replace(/(<object[^<]*<\/object>)+/, svgData);
      // replace fonts path
      pageData = pageData.replace(
        /(fonts\/[a-zA-Z0-9\-_]*.\w*)/g,
        `${root}$1${token}`,
      );

      // if marks are enabled and available, the speechMarks array for this page will have length
      if (
        book.cachedPages[pageKey] &&
        book.cachedPages[pageKey].speechMarks &&
        book.cachedPages[pageKey].speechMarks.length > 0
      ) {
        pageData = addSpeechMarkTimesAndTappableClass(book, pageData, index);
      } else {
        pageData = addTappableClassOnly(pageData);
      }
      return pageData;
    });
  }

  /* get the zipped up book from blob storage */
  static downloadBook(book, token) {
    const URL = `${config.API.Storage}${book.EBookPath}${book.ISBN}/${book.ISBN}.zip${token}`;
    const request = new Request(URL, { method: "GET" });

    return (
      fetch(request)
        .then((response) => {
          console.log("downloaded book zip");
          if (response.status !== 200) {
            throw response;
          }
          // decode the base64 encoded response
          return response.blob();
        })
        // unzip the zip
        .then(JSZip.loadAsync)
        .then(function (zip) {
          console.log("unzipped book zip");
          // save the unziped files to the file system
          return BookFS.saveBook(book.ISBN, zip);
        })
    );
  }

  /*
   * Get the zipped up speech files
   */
  static downloadSpeech(book, token) {
    const URL = `${config.API.Storage}${book.EBookPath}${book.ISBN}/audio/default/${book.ISBN}_audio.zip${token}`;
    const request = new Request(URL, { method: "GET" });

    return (
      fetch(request)
        .then((response) => {
          if (response.status !== 200) {
            throw response;
          }
          // decode the base64 encoded response
          return response.blob();
        })
        // unzip the zip
        .then(JSZip.loadAsync)
        .then(function (zip) {
          console.log("unzipped speech zip");
          // save the unziped files to the file system
          return BookFS.saveSpeech(book.ISBN, zip);
        })
    );
  }

  static getBookItemsList(downloadedBookIDs, user) {
    let URL = `${config.API.Main}/viewer/getbookitemlist`;

    forEach(downloadedBookIDs, (bookID, index) => {
      if (index === 0) {
        URL += `?bookIDs=${bookID}`;
      } else {
        URL += `&bookIDs=${bookID}`;
      }
    });

    var request = new Request(URL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  /* Get a list of books the current user has access to*/
  static getBooks(
    activePage,
    search,
    type,
    tag,
    grl,
    user,
    advancedSearchFilters,
  ) {
    if (type === "All") {
      type = "";
    }
    const {
      selectedReadingLevelTypeOption = null,
      readingMin = "",
      readingMax = "",
      spanishOnly = "",
    } = advancedSearchFilters;
    const rltype = selectedReadingLevelTypeOption
      ? selectedReadingLevelTypeOption.value
      : "";
    let URL = `${config.API.Main}/student/getbooks?page=${activePage}&search=${search}&grl=${grl}&type=${type}&rltype=${rltype || ""}&rlmin=${readingMin || ""}&rlmax=${readingMax || ""}&spanishonly=${spanishOnly}`;

    if (tag !== undefined && tag !== "" && tag !== "allTags") {
      URL += `&tags=${config.typeGUID[tag]}`;
    } else {
      URL += `&tags=`;
    }

    if (advancedSearchFilters.tags && advancedSearchFilters.tags.length > 0) {
      forEach(advancedSearchFilters.tags, (value, key) => {
        URL += `&tags=${value.TagID}`;
      });
    }

    var request = new Request(URL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  static addBook(bookID, user) {
    const URL = `${config.API.Main}/student/addtobookbag?bookid=${bookID}`;
    var request = new Request(URL, {
      method: "POST",
      headers: UserAPI.getHeaders(user),
    });
    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  static removeBook(bookID, user) {
    const URL = `${config.API.Main}/student/removefrombookbag?bookid=${bookID}`;
    var request = new Request(URL, {
      method: "POST",
      headers: UserAPI.getHeaders(user),
    });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  static getBookByID(bookID, user) {
    const URL = `${config.API.Main}/book/getbookbyid?bookid=${bookID}`;
    var request = new Request(URL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    console.log("gettingbook", user, request);
    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  static searchBooks(
    page,
    search,
    type,
    tag,
    grl,
    user,
    advancedSearchFilters,
  ) {
    if (type === "All") {
      type = "";
    }
    const {
      selectedReadingLevelTypeOption = null,
      readingMin = "",
      readingMax = "",
      spanishOnly = "",
    } = advancedSearchFilters;
    const rltype = selectedReadingLevelTypeOption
      ? selectedReadingLevelTypeOption.value
      : "";
    let URL = `${config.API.Main
      }/book/ebooksearch?page=${page}&search=${search}&type=${type}&grl=${grl}
    &rltype=${rltype || ""}&rlmin=${readingMin || ""}&rlmax=${readingMax || ""
      }&spanishonly=${spanishOnly}`;
    // grabbing the tag name and assigning it as the key pair to grab the value in config
    if (tag !== undefined && tag !== "" && tag !== "allTags") {
      URL += `&tags=${config.typeGUID[tag]}`;
    } else {
      URL += `&tags=`;
    }

    if (advancedSearchFilters.tags && advancedSearchFilters.tags.length > 0) {
      forEach(advancedSearchFilters.tags, (value, key) => {
        URL += `&tags=${value.TagID}`;
      });
    }

    var request = new Request(URL, {
      method: "GET",
      headers: UserAPI.getHeadersUrlEncode(user),
    });
    console.log("before fetch");
    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  /*
    save bookitem
  */
  static saveBookItem(bookItem, user) {
    const URL = `${config.API.Main}/viewer/savebookitem`;
    const body = JSON.stringify({
      ...bookItem,
      Content: JSON.stringify(bookItem.Content),
    });
    var request = new Request(URL, {
      method: "POST",
      headers: UserAPI.getHeaders(user),
      body,
    });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }
  /*
    save bookitem offline
  */

  /*
    delete bookitem
  */
  static deleteBookItem(bookItem, user) {
    const URL = `${config.API.Main}/viewer/deletebookitem`;
    let body = `ID=${bookItem.ID}`;
    var request = new Request(URL, {
      method: "POST",
      headers: UserAPI.getHeaders(user),
      body: body,
    });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  /*
    get book items
  */
  static getBookItems(bookId, user) {
    const URL = `${config.API.Main}/viewer/getbookitems?bookID=${bookId}`;
    var request = new Request(URL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  static getTeacherBookItems(data, user) {
    let passCorrectID;
    if (data.groupID) {
      passCorrectID = `&groupID=${data.groupID}`;
    } else if (data.classID) {
      passCorrectID = `&classID=${data.classID}`;
    } else {
      passCorrectID = `&studentID=${data.studentID}`;
    }

    const URL = `${config.API.Main}/viewer/getteacherbookitems?bookID=${data.book}${passCorrectID}`;
    var request = new Request(URL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  /*
    get book items by student id (used by teacher token login)
  */
  static getBookItemsByStudentID(BookId, StudentId, user) {
    const URL = `${config.API.Main}/viewer/getstudentbookitems?bookID=${BookId}&studentID=${StudentId}`;
    var request = new Request(URL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }

  static async getResources(user, bookID) {
    const remoteURL = `${config.API.Main}/book/getteacherresources?bookid=${bookID}&search=`;
    const request = new Request(remoteURL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });
    const response = await fetch(request);

    if (response.status !== 200) {
      throw response;
    }

    const data = await response.json();

    return data;
  }

  static async getTags(user, bookID) {
    const remoteURL = `${config.API.Main}/book/gettags?bookid=${bookID}`;
    const request = new Request(remoteURL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });
    const response = await fetch(request);

    if (response.status !== 200) {
      throw response;
    }

    const data = await response.json();

    return data;
  }

  static async getHubs(user) {
    const remoteURL = `${config.API.Main}/hub/gethubs`;
    const request = new Request(remoteURL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });
    const response = await fetch(request);

    if (response.status !== 200) {
      throw response;
    }

    const data = await response.json();

    return data;
  }

  static async getHubsBooks(user, hubID) {
    const remoteURL = `${config.API.Main}/hub/gethubsandbooks${hubID ? `?hubID=${hubID}` : ""}`;
    const request = new Request(remoteURL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });
    const response = await fetch(request);

    if (response.status !== 200) {
      throw response;
    }

    const data = await response.json();

    return data;
  }

  static async getHubSearch(user, query) {
    const remoteURL = `${config.API.Main}/hub/search?search=${query}`;
    const request = new Request(remoteURL, {
      method: "GET",
      headers: UserAPI.getHeaders(user),
    });
    const response = await fetch(request);

    if (response.status !== 200) {
      throw response;
    }

    const data = await response.json();

    return data;
  }

  static logEvent(BookID, EventType, user) {
    const URL = `${config.API.Main}/viewer/log`;
    const body = JSON.stringify({
      AccountID: user.ID, BookID, EventType
    });
    const request = new Request(URL, {
      method: "POST",
      headers: UserAPI.getHeaders(user),
      body,
    });

    return fetch(request).then((response) => {
      if (response.status !== 200) {
        throw response;
      }
      return response.json();
    });
  }
}
